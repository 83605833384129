import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

export type FirebaseEventUnsubscribe = (
  a: firebase.database.DataSnapshot
) => any;

const QUEUED_TRACKS_REF = 'queued_tracks';
// const QUEUED_TRACKS_SUMMARY_REF = 'queued_tracks_summary';
const CONNECTED_USERS_REF = 'connected_users';
const CURRENT_TRACK_REF = 'current_track';
const PLAYED_TRACKS_REF = 'played_tracks';
// const CHANNELS_REF = 'channels';
const PUBLIC_CHANNELS_REF = 'public_channels';
const VOTES_REF = 'votes';
const VOTING_REF = 'voting';
const PROFILES_REF = 'profiles';
const MESSAGES_REF = 'messages';

export type ChannelId = string;
export type Channel = {
  id: ChannelId;
  created_by: string;
  name: string;
  created_at: number;
  updated_at: number;
};

export type GetChannelsResponse = Record<ChannelId, Channel>;

export async function getChannels(): Promise<GetChannelsResponse> {
  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(PUBLIC_CHANNELS_REF)
      .on(
        'value',
        (snap) => {
          resolve(snap.val());
        },
        (error: Error) => {
          console.error('Could not get channels', error);
          reject(error);
        }
      );
  });
}

export const selectChannel = async (
  channelId: string,
  uid: string
): Promise<void> => {
  const ref = firebase
    .database()
    .ref(CONNECTED_USERS_REF)
    .child(channelId)
    .child(uid);
  await ref.set(firebase.database.ServerValue.TIMESTAMP);
  ref.onDisconnect().remove();

  firebase
    .database()
    .ref('.info/connected')
    .on('value', (snap) => {
      if (snap.val() === true) {
        ref.set(firebase.database.ServerValue.TIMESTAMP);
        ref.onDisconnect().remove();
      }
    });
};

export const deselectChannel = async (
  channelId: string,
  uid: string
): Promise<void> => {
  return firebase
    .database()
    .ref(CONNECTED_USERS_REF)
    .child(channelId)
    .child(uid)
    .remove();
};

export type ConnectedUsers = Record<string, number>;

export const observeConnectedUsers = (
  channelId: string,
  onConnectedUsersChange: (connectedUsers: ConnectedUsers) => void
) => {
  return firebase
    .database()
    .ref(CONNECTED_USERS_REF)
    .child(channelId)
    .on('value', (snap) => {
      if (snap.key) {
        onConnectedUsersChange(snap.val());
      }
    });
};

export const stopObserveConnectedUsers = (
  channelId: string,
  unsubscribe?: FirebaseEventUnsubscribe
) => {
  return firebase
    .database()
    .ref(CONNECTED_USERS_REF)
    .child(channelId)
    .off('value', unsubscribe);
};

export const queueTrack = async ({
  channelId,
  uid,
  trackUri,
  duration,
}: {
  channelId: string;
  duration: number;
  trackUri: string;
  uid: string;
}) => {
  const params = {
    created_at: firebase.database.ServerValue.TIMESTAMP,
    created_by: uid,
    duration: duration,
    uri: trackUri,
  };

  try {
    await firebase
      .database()
      .ref(QUEUED_TRACKS_REF)
      .child(channelId)
      .push(params);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export type Profile = {};

export const getProfile = (uid: string): Promise<Profile> => {
  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(PROFILES_REF)
      .child(uid)
      .once('value', (snap) => resolve(snap.val()))
      .catch((error) => {
        console.error('Failed to get profile', uid, error);
        reject(error);
      });
  });
};

export type VoteProps = {
  pump: boolean;
  uid: string;
};

export const observeVoters = (
  channelId: string,
  onVoterAdded: (vote: VoteProps) => void
): FirebaseEventUnsubscribe => {
  return firebase
    .database()
    .ref(CURRENT_TRACK_REF)
    .child(channelId)
    .child(VOTES_REF)
    .on('child_added', (snap) => {
      if (snap.key) {
        onVoterAdded({
          pump: snap.val(),
          uid: snap.key,
        });
      }
    });
};

export const stopObservingVoters = (
  channelId: string,
  unsubscribe?: FirebaseEventUnsubscribe
) => {
  firebase
    .database()
    .ref(CURRENT_TRACK_REF)
    .child(channelId)
    .child(VOTES_REF)
    .off('child_added', unsubscribe);
};

export const observeVoting = (
  channelId: string,
  stateChanged: (active: boolean) => void
): FirebaseEventUnsubscribe => {
  return firebase
    .database()
    .ref(VOTING_REF)
    .child(channelId)
    .on('value', (snap) => stateChanged(snap.val()));
};

export const stopObservingVoting = (
  channelId: string,
  unsubscribe?: FirebaseEventUnsubscribe
) => {
  firebase
    .database()
    .ref(VOTING_REF)
    .child(channelId)
    .off('value', unsubscribe);
};

export const vote = ({
  channelId,
  uid,
  pump,
}: {
  channelId: string;
  uid: string;
  pump: boolean;
}) => {
  return firebase
    .database()
    .ref(CURRENT_TRACK_REF)
    .child(channelId)
    .child(VOTES_REF)
    .child(uid)
    .set(pump);
};

export type CurrentTrack = {
  created_at: number;
  created_by: string;
  duration: number;
  started_at: number;
  uri: string;
};

export const getCurrentTrack = (
  channelId: string
): Promise<CurrentTrack | null> => {
  return new Promise((resolve, reject) => {
    return firebase
      .database()
      .ref(CURRENT_TRACK_REF)
      .child(channelId)
      .once(
        'value',
        (snap) => {
          const result = snap.val();
          resolve(
            result
              ? {
                  created_at: result.created_at,
                  created_by: result.created_by,
                  duration: result.duration,
                  started_at: result.started_at,
                  uri: result.uri,
                }
              : null
          );
        },
        reject
      );
  });
};

export const observeCurrentTrack = (
  channelId: string,
  onTrackChanged: (track: CurrentTrack | null) => void
): FirebaseEventUnsubscribe => {
  return firebase
    .database()
    .ref(CURRENT_TRACK_REF)
    .child(channelId)
    .on(
      'value',
      (snap) => {
        const result = snap.val();
        onTrackChanged(
          result
            ? {
                created_at: result.created_at,
                created_by: result.created_by,
                duration: result.duration,
                started_at: result.started_at,
                uri: result.uri,
              }
            : null
        );
      },
      (error: Error) => {
        console.error('observeCurrentTrack', error);
      }
    );
};

export const stopObserveCurrentTrack = (
  channelId: string,
  unsubscribe?: FirebaseEventUnsubscribe
) => {
  firebase
    .database()
    .ref(CURRENT_TRACK_REF)
    .child(channelId)
    .off('value', unsubscribe);
};

export type ChatCallbackResponse = {
  body: string;
  created_at: number;
  created_by: string;
};

export const observeChat = (
  channelId: string,
  callback: (messages: ChatCallbackResponse) => void
) => {
  firebase
    .database()
    .ref(MESSAGES_REF)
    .child(channelId)
    .limitToLast(20)
    .on('value', (snap) => callback(snap.val()));
};

export const stopObserveChat = (
  channelId: string,
  unsubscribe: FirebaseEventUnsubscribe
) => {
  firebase
    .database()
    .ref(MESSAGES_REF)
    .child(channelId)
    .off('value', unsubscribe);
};

export type AddChatMessage = {
  channelId: string;
  body: string;
};

export const addChatMessage = async ({
  channelId,
  body,
}: AddChatMessage): Promise<void> => {
  const currentUser = firebase.auth().currentUser;
  if (!currentUser) {
    throw new Error('addChatMessage: Not authenticated');
  }

  const message = {
    body,
    created_by: currentUser.uid,
    created_at: firebase.database.ServerValue.TIMESTAMP,
  };

  await firebase.database().ref(MESSAGES_REF).child(channelId).push(message);
  await firebase
    .database()
    .ref(PUBLIC_CHANNELS_REF)
    .child(channelId)
    .child('updated_at')
    .set(firebase.database.ServerValue.TIMESTAMP);
};

export type ObservePlayedTracksResponse = {
  created_at: number;
  created_by: string;
  duration: number;
  ended_at: number;
  started_at: number;
  uri: string;
  votes?: {
    [uid: string]: boolean;
  };
};

export const observePlayedTracks = (
  channelId: string,
  callback: (playedTracks: Record<string, ObservePlayedTracksResponse>) => void
) => {
  return firebase
    .database()
    .ref(PLAYED_TRACKS_REF)
    .child(channelId)
    .limitToLast(20)
    .on('value', (snap) => callback(snap.val()));
};

export const stopObservingPlayedTracks = (
  channelId: string,
  unsubscribe: FirebaseEventUnsubscribe
) => {
  firebase
    .database()
    .ref(PLAYED_TRACKS_REF)
    .child(channelId)
    .off('value', unsubscribe);
};
