import firebase from 'firebase/app';
import { atom, selector } from 'recoil';
import { StatePrefix } from '../Core/CoreState';
import { AppEventKeys } from '../utilities/events';
import { broadcastEffect, localStorageEffect } from '../utilities/state';
import {
  AccessTokenExpiry,
  AuthInitiatedState,
  AuthInProgressState,
  IsAuthenticatedSelectorState,
  LoginCodeState,
  RefreshTokenInProgressState,
  RefreshTokenState,
  UserState,
} from './AuthTypes';

export const userState = atom<UserState | null>({
  key: `${StatePrefix}userState`,
  default: null,
  effects_UNSTABLE: [
    ({ setSelf, resetSelf }) => {
      const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
        console.log('received user', user);
        if (user) {
          setSelf({
            email: user.email,
            uid: user.uid,
            displayName: user.displayName,
          });
        } else {
          resetSelf();
        }
      });

      return () => {
        unsubscribe();
      };
    },
  ],
});

export const authInitiatedState = atom<AuthInitiatedState>({
  key: `${StatePrefix}authInitiatedState`,
  default: false,
});
type StateKeyState = string | null;

export const stateKeyState = atom<StateKeyState>({
  key: `${StatePrefix}authStateKey`,
  default: null,
  effects_UNSTABLE: [
    localStorageEffect<StateKeyState>(`${StatePrefix}authStateKey`),
  ],
});

export const loginCodeState = atom<LoginCodeState>({
  key: `${StatePrefix}loginCodeState`,
  default: null,
  effects_UNSTABLE: [
    localStorageEffect<StateKeyState>(`${StatePrefix}loginCodeState`),
  ],
});

export const authIsInProgressState = atom<AuthInProgressState>({
  key: `${StatePrefix}authIsInProgressState`,
  default: false,
  effects_UNSTABLE: [
    localStorageEffect<AuthInProgressState>(
      `${StatePrefix}authIsInProgressState`
    ),
  ],
});

type AccessTokenState = string | null;

export const accessTokenState = atom<AccessTokenState>({
  key: `${StatePrefix}accessTokenState`,
  default: null,
  effects_UNSTABLE: [
    localStorageEffect<AccessTokenState>(`${StatePrefix}accessTokenState`),
  ],
});

export const accessTokenExpiryState = atom<AccessTokenExpiry>({
  key: `${StatePrefix}accessTokenExpiryState`,
  default: null,
  effects_UNSTABLE: [
    localStorageEffect<AccessTokenExpiry>(
      `${StatePrefix}accessTokenExpiryState`
    ),
    broadcastEffect(AppEventKeys.AuthAccessTokenExpiryReceived),
  ],
});

export const refreshTokenState = atom<RefreshTokenState>({
  key: `${StatePrefix}refreshTokenState`,
  default: null,
  effects_UNSTABLE: [
    localStorageEffect<RefreshTokenState>(`${StatePrefix}refreshTokenState`),
  ],
});

export const refreshTokenInProgressState = atom<RefreshTokenInProgressState>({
  key: `${StatePrefix}refreshTokenInProgressState`,
  default: false,
});

// ...

export const isAuthenticatedSelector = selector<IsAuthenticatedSelectorState>({
  key: `${StatePrefix}isAuthenticatedSelector`,
  get: ({ get }) => {
    const accessToken = get(accessTokenState);
    const refreshToken = get(refreshTokenState);
    const accessTokenExpiry = get(accessTokenExpiryState);

    return (
      accessToken !== null &&
      refreshToken !== null &&
      accessTokenExpiry !== null
    );
  },
});
