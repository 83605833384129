import {
  Divider,
  Flex,
  Box,
  Skeleton,
  SkeletonText,
  HStack,
  StackItem,
} from '@chakra-ui/react';
import { FunctionComponent } from 'react';

type Props = { compactMode?: boolean };

export const TrackListLoadingItem: FunctionComponent<Props> = ({
  compactMode = false,
}) => {
  return (
    <>
      <Divider orientation="horizontal" color="silver" opacity={1} />
      <Flex direction="row" grow={1} py="12px" align="center">
        <Box height={12} width={12} mr={3} flexShrink={0}>
          <Skeleton height={12} width={12} />
        </Box>
        <Flex direction="column" grow={1} shrink={1}>
          <SkeletonText noOfLines={2} width={20} />
        </Flex>

        <HStack spacing={0}>
          <StackItem
            w={!compactMode ? { base: '72px', sm: '168px' } : undefined}
            mr={compactMode ? 20 : undefined}
          >
            <Flex direction="row" alignItems="center" width="100%">
              <SkeletonText noOfLines={1} width={10} />
            </Flex>
          </StackItem>
        </HStack>
      </Flex>
    </>
  );
};

export default TrackListLoadingItem;
