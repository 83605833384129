import { PropsWithChildren, FC, useEffect, useCallback } from 'react';
import { useRecoilSnapshot } from 'recoil';
import Debug from '../utilities/debug';

const debugV = Debug.verbose('state');

const DevDebugger: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const snapshot = useRecoilSnapshot();

  const onRecoilStateChange = useCallback((stateSnapshot) => {
    if (process.env.NODE_ENV === 'development') {
      const nodes = stateSnapshot.getNodes_UNSTABLE({ isModified: true });
      for (const node of nodes) {
        debugV(node.key, stateSnapshot.getLoadable(node).contents);
      }
    }
  }, []);

  useEffect(() => {
    onRecoilStateChange(snapshot);
  }, [onRecoilStateChange, snapshot]);

  return <>{children}</>;
};

export default DevDebugger;
