import { Box, Flex, Text } from '@chakra-ui/layout';
import { FunctionComponent } from 'react';

const TrackListHeader: FunctionComponent<unknown> = () => {
  return (
    <Flex direction="row" width="100%" pb={3}>
      <Box w={{ base: '72px', sm: '168px' }}>
        <Text textStyle="Label">SONG</Text>
      </Box>
      <Box w={{ base: '72px', sm: '168px' }} ml="auto">
        <Text textStyle="Label">TIME</Text>
      </Box>
    </Flex>
  );
};

export default TrackListHeader;
