import {
  Box,
  Divider,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { animated, useSpring } from 'react-spring';
import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import { ReactComponent as ArrowDownIcon } from '../assets/icons/arrow-down.svg';
import { useVotes } from '@worldeggplant/partypooper-react';
import VoteList from './VoteList';
import { useResponsiveTextStyle } from '../utilities/ResponsiveUtilities';

type Props = {
  title: ReactNode;
  artist: ReactNode;
  trackName: ReactNode;
  createdBy?: ReactNode;
  pumps?: number;
  poops?: number;
  hideVotingData?: boolean;
  enableVoterList?: boolean;
};

const NowPlayingCard: FunctionComponent<Props> = ({
  artist,
  title,
  trackName,
  createdBy,
  pumps,
  poops,
  hideVotingData = false,
  enableVoterList = false,
}) => {
  const { votes } = useVotes();
  const [showVotes, setShowVotes] = useState<boolean>(false);
  const [showVoterList, setShowVoterList] = useState<boolean>(false);
  const [animationProps, setVotingDataOpacity] = useSpring(() => ({
    opacity: 0,
  }));
  const label = useResponsiveTextStyle('Mini label', 'Label');
  const VoteText = useResponsiveTextStyle('Small', 'Medium');
  const h1 = useResponsiveTextStyle('Title+', 'Big Title+');
  const h2 = useResponsiveTextStyle('Large', 'Title');

  const onToggleShowVotes = useCallback(() => {
    setShowVotes((currentValue) => !currentValue);
  }, []);

  useEffect(() => {
    setVotingDataOpacity({ opacity: hideVotingData ? 0 : 1 });
  }, [hideVotingData, setVotingDataOpacity]);

  useLayoutEffect(() => {
    setShowVoterList(enableVoterList);
  }, [enableVoterList]);

  const AnimatedBox = animated(Box);

  return (
    <Flex direction="column" grow={1} shrink={0}>
      <Text textStyle={label}>{title}</Text>
      <Text textStyle={h1} mt={6} isTruncated noOfLines={2} maxW="100%">
        {trackName}
      </Text>
      <Text textStyle={h2} mt={0.5} isTruncated noOfLines={1} maxW="100%">
        {artist}
      </Text>

      <AnimatedBox style={animationProps} mt="auto">
        <Divider mt={{ base: 4, sm: 6 }} mr={4} color="silver" />
        <Flex justify="space-between" mt={3}>
          <Box>
            <Text textStyle={VoteText}>Queued by {createdBy}</Text>
          </Box>
          <Box flexShrink={0}>
            <Text as="div" letterSpacing="tight" textStyle="body">
              <Wrap>
                <WrapItem>
                  <Text as="span" textStyle={VoteText}>
                    {pumps} {pumps === 1 ? 'pump' : 'pumps'}
                  </Text>
                </WrapItem>
                <WrapItem>
                  <Text textStyle={VoteText}>&bull;</Text>
                </WrapItem>
                <WrapItem>
                  <Text as="span" textStyle={VoteText}>
                    {poops} {poops === 1 ? 'poop' : 'poops'}
                  </Text>
                </WrapItem>
                {showVoterList && (
                  <WrapItem>
                    <IconButton
                      size="6"
                      mt={{ base: -1, md: 0 }}
                      aria-label="Show votes"
                      colorScheme="transparent"
                      onClick={onToggleShowVotes}
                      icon={
                        <Icon
                          transform={showVotes ? 'rotate(-180deg)' : undefined}
                          boxSize={6}
                          viewBox="0 0 24 24"
                          color="body"
                        >
                          <ArrowDownIcon />
                        </Icon>
                      }
                    />
                  </WrapItem>
                )}
              </Wrap>
            </Text>
          </Box>
        </Flex>
        <Collapse in={showVotes} animateOpacity>
          <Box mt={3} pb={6}>
            <VoteList votes={votes} />
          </Box>
        </Collapse>
      </AnimatedBox>
    </Flex>
  );
};

export default NowPlayingCard;
