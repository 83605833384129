import { Divider, Flex, List, ListItem, Text } from '@chakra-ui/react';
import { VoterState } from '@worldeggplant/partypooper-react';
import { FunctionComponent } from 'react';
import UserAvatar from './UserAvatar';

type Props = {
  votes: VoterState;
};

const VoteList: FunctionComponent<Props> = ({ votes }) => {
  return (
    <List>
      {!votes?.length && (
        <ListItem mt={5}>
          <Text textStyle="Medium+" color="fadedBlack" textAlign="center">
            No votes!
          </Text>
        </ListItem>
      )}
      {votes?.map((vote) => (
        <ListItem key={vote.uid}>
          <Flex alignItems="center" py={3}>
            <Flex
              justifyContent="center"
              alignContent="center"
              borderColor={vote.pump ? 'black' : 'grey'}
              borderStyle="solid"
              borderWidth={1}
              borderRadius="full"
              opacity={vote.pump ? 1 : 0.4}
              padding="2px"
            >
              <UserAvatar size="sm" uid={vote.uid} />
            </Flex>
            <Text textStyle="Medium+" ml={3}>
              {vote.uid}
            </Text>
            <Text textStyle="Medium+" ml="auto">
              {vote.pump ? 'Pumped!' : 'Pooped!'}
            </Text>
          </Flex>
          <Divider orientation="horizontal" color="silver" />
        </ListItem>
      ))}
    </List>
  );
};

export default VoteList;
