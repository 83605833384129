import firebase from 'firebase';
import 'firebase/analytics';
import { getConfig } from './config';
import Debug from './debug';

const debugV = Debug.verbose('app:analytics');

const config = getConfig();

if (firebase.apps.length === 0) {
  console.log('initializing firebase', config.firebase);
  firebase.initializeApp({
    appId: config.firebase.appId,
    apiKey: config.firebase.apiKey,
    authDomain: config.firebase.authDomain,
    databaseURL: config.firebase.databaseUrl,
    messagingSenderId: config.firebase.messagingSenderId,
    projectId: config.firebase.projectId,
    storageBucket: config.firebase.storageBucket,
  });
}

export const analytics = firebase.analytics();

analytics.setAnalyticsCollectionEnabled(true);

export const logEvent = (
  ...[event, params, options]: Parameters<
    firebase.analytics.Analytics['logEvent']
  >
) => {
  debugV('logging event', event, params);
  analytics.logEvent(event, params, options);
};
