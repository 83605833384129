import {
  getArtistFromTrack,
  useTrackMeta,
} from '@worldeggplant/partypooper-react';
import { FunctionComponent } from 'react';
import ArtistLink from './ArtistLink';
import FeedItem from './FeedItem';

type Props = {
  title: string;
  timestamp: number;
  trackUri: string;
  votes?: { pumps: number; poops: number };
  hideVotes?: boolean;
};

const FeedCurrentTrack: FunctionComponent<Props> = ({
  title,
  timestamp,
  trackUri,
  votes,
  hideVotes = false,
}) => {
  const trackMeta = useTrackMeta(trackUri);
  const image = trackMeta?.album.images[0]?.url;

  if (!trackMeta) {
    return null;
  }

  return (
    <FeedItem
      image={image}
      title={title}
      message={
        <>
          {trackMeta.name} –{' '}
          <ArtistLink artist={getArtistFromTrack(trackMeta)} />
        </>
      }
      timestamp={timestamp}
      votes={votes}
      hideVotes={hideVotes}
    />
  );
};

export default FeedCurrentTrack;
