import {
  FeedMessageItem,
  FeedMessageType,
  FeedQueuedTrackItem,
  FeedCurrentTrackItem,
} from './FeedTypes';

export function createFeedMessageItem(message: {
  created_by: string;
  created_at: number;
  body: string;
  id: string;
}): FeedMessageItem {
  const item: FeedMessageItem = {
    type: FeedMessageType.message,
    created_by: message.created_by,
    created_at: message.created_at,
    body: message.body,
    id: `message:${message.id}`,
  };

  return item;
}

export function createFeedQueuedTrackItem(track: {
  votes?: { pumps: number; poops: number };
  created_by: string;
  created_at: number;
  uri: string;
  id: string;
}): FeedQueuedTrackItem {
  const { pumps = 0, poops = 0 } = track.votes || {};
  const pumped = pumps >= poops;

  const item: FeedQueuedTrackItem = {
    type: FeedMessageType.queuedTrack,
    created_by: track.created_by,
    created_at: track.created_at,
    track_uri: track.uri,
    currentPlayingTrack: false,
    id: `queued_track:${track.id}`,
    pumped,
    votes: { pumps, poops },
  };

  return item;
}

export function createFeedCurrentTrackItem(track: {
  created_at: number;
  uri: string;
}): FeedCurrentTrackItem {
  const item: FeedCurrentTrackItem = {
    type: FeedMessageType.currentTrack,
    created_at: track.created_at,
    track_uri: track.uri,
    currentPlayingTrack: true,
    id: `current_track:${track.created_at}`,
  };

  return item;
}
