import { ReactNode, useCallback } from 'react';
import {
  AutoSizer,
  Index,
  InfiniteLoader,
  InfiniteLoaderProps,
  List,
  ListRowRenderer,
} from 'react-virtualized';

type Props<T> = {
  items: T[];
  loadNextPage: () => void;
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  renderItem: ({ index }: Index) => ReactNode;
  renderItemLoading: ReactNode;
  rowHeight: number;
};

const InfiniteLoadingList = <T extends unknown>({
  hasNextPage,
  items,
  loadNextPage,
  isNextPageLoading,
  renderItem,
  renderItemLoading,
  rowHeight,
}: Props<T>) => {
  const rowCount = hasNextPage ? items.length + 1 : items.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreRows: InfiniteLoaderProps['loadMoreRows'] = useCallback(async () => {
    if (!isNextPageLoading) {
      loadNextPage();
    }
  }, [isNextPageLoading, loadNextPage]);

  // Every row is loaded except for our loading indicator row.
  const isRowLoaded = useCallback(
    ({ index }: Index): boolean => {
      return !hasNextPage || index < items.length;
    },
    [hasNextPage, items.length]
  );

  const renderRow: ListRowRenderer = useCallback(
    ({ index, key, style }) => {
      let content: ReactNode;

      if (!isRowLoaded({ index })) {
        content = renderItemLoading;
      } else {
        content = renderItem({ index });
      }

      return (
        <div key={key} style={style}>
          {content}
        </div>
      );
    },
    [isRowLoaded, renderItem, renderItemLoading]
  );

  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={loadMoreRows}
      rowCount={rowCount}
    >
      {({ onRowsRendered, registerChild }) => (
        <AutoSizer>
          {({ height, width }) => (
            <List
              ref={registerChild}
              onRowsRendered={onRowsRendered}
              rowRenderer={renderRow}
              height={height}
              rowHeight={rowHeight}
              rowCount={rowCount}
              width={width}
            />
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  );
};

export default InfiniteLoadingList;
