import { Icon, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

import { ReactComponent as HeartIcon } from '../assets/icons/heart-small.svg';
import { ReactComponent as PoopIcon } from '../assets/icons/poop-small.svg';
import { useResponsiveTextStyle } from '../utilities/ResponsiveUtilities';

type Props = {
  pumps?: number;
  poops?: number;
};

const FeedVotes: FunctionComponent<Props> = ({ pumps = 0, poops = 0 }) => {
  const text = useResponsiveTextStyle('Mini+', 'Small+');
  return (
    <Wrap direction="row" align="center" spacing={3} mt="auto">
      <WrapItem>
        <Icon as={HeartIcon} height="16px" width="16px" mr={1} mt="2px" />

        <Text fontSize={text}>{pumps}</Text>
      </WrapItem>
      <WrapItem>
        <Icon as={PoopIcon} height="16px" width="16px" mr={1} mt="3px" ml={3} />
        <Text fontSize={text}>{poops}</Text>
      </WrapItem>
    </Wrap>
  );
};

export default FeedVotes;
