import { selectorFamily } from 'recoil';
import {
  currentTrackState,
  playedTracksSelector,
  voterState,
  votingActiveState,
} from '../Channel/ChannelState';
import { chatMessagesSelector } from '../Chat/ChatState';
import { StatePrefix } from '../Core/CoreState';
import { FeedItem } from './FeedTypes';
import {
  createFeedCurrentTrackItem,
  createFeedMessageItem,
  createFeedQueuedTrackItem,
} from './FeedUtilities';

type FeedItemsSelectorResults = FeedItem[] | null;
type FeedItemsParams = {
  channelId: string | null;
};

export const feedItemsSelector = selectorFamily<
  FeedItemsSelectorResults,
  FeedItemsParams
>({
  key: `${StatePrefix}feedItemsSelector`,
  get: ({ channelId }) => ({ get }) => {
    if (!channelId) {
      return null;
    }

    const currentVotes = get(voterState);
    const isVotingActive = get(votingActiveState);
    const currentTrack = get(currentTrackState);
    const chatMessages = get(chatMessagesSelector({ channelId }));
    const playedTracks = get(playedTracksSelector({ channelId }));

    const feed: FeedItemsSelectorResults = [];
    const feedItemIds: string[] = [];

    if (chatMessages !== null) {
      Object.keys(chatMessages).forEach((chatMessageId) => {
        const message = chatMessages[chatMessageId];

        if (!feedItemIds.includes(chatMessageId)) {
          const item = createFeedMessageItem({
            created_by: message.created_by,
            created_at: message.created_at,
            body: message.body,
            id: chatMessageId,
          });
          feed.push(item);
          feedItemIds.push(chatMessageId);
        }
      });
    }

    if (playedTracks !== null) {
      Object.keys(playedTracks).forEach((playedTracksId) => {
        const queuedTrack = playedTracks[playedTracksId];
        const item = createFeedQueuedTrackItem({
          created_by: queuedTrack.created_by,
          // We add the details of the person who queued the track 30 seconds after.
          created_at: queuedTrack.started_at + 30000,
          uri: queuedTrack.uri,
          votes: {
            pumps: Object.values(queuedTrack.votes || {}).filter((p) => p)
              .length,
            poops: Object.values(queuedTrack.votes || {}).filter((p) => !p)
              .length,
          },
          id: `${queuedTrack.started_at}:${playedTracksId}`,
        });

        if (!feedItemIds.includes(item.id)) {
          feed.push(item);
          feedItemIds.push(item.id);
        }

        // Keep the original "New song queued" event
        const queuedItem = createFeedCurrentTrackItem({
          created_at: queuedTrack.started_at,
          uri: queuedTrack.uri,
        });

        if (!feedItemIds.includes(queuedItem.id)) {
          feed.push(queuedItem);
          feedItemIds.push(queuedItem.id);
        }
      });
    }

    if (currentTrack) {
      const item = createFeedCurrentTrackItem({
        created_at: currentTrack.started_at,
        uri: currentTrack.uri,
      });
      if (!feedItemIds.includes(item.id)) {
        feed.push(item);
        feedItemIds.push(item.id);
      }

      if (!isVotingActive) {
        const queuedItem = createFeedQueuedTrackItem({
          created_by: currentTrack.created_by,
          created_at: currentTrack.started_at + 30000,
          uri: currentTrack.uri,
          votes: {
            pumps: currentVotes.filter((p) => p).length,
            poops: currentVotes.filter((p) => !p).length,
          },
          id: `${currentTrack.started_at}:${currentTrack.uri}`,
        });

        if (!feedItemIds.includes(queuedItem.id)) {
          feed.push(queuedItem);
          feedItemIds.push(queuedItem.id);
        }
      }
    }

    feed.sort((a, b) => b.created_at - a.created_at);

    return [...feed];
  },
});
