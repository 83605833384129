import firebase from 'firebase/app';
import 'firebase/auth';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  generateStateToken,
  getAccessToken,
  getAccessTokenExpiryTime,
  getFirebaseToken,
  getRedirectUrl,
} from '../utilities/auth';
import useEventEmitter, {
  AppEventKeys,
  AppEventMap,
  getEventEmitter,
} from '../utilities/events';
import { nullValueGuard } from '../utilities/hook';
import { useConfigSafe } from '../Core/CoreHooks';
import {
  isAuthenticatedSelector,
  authIsInProgressState,
  stateKeyState,
  authInitiatedState,
  userState,
  accessTokenState,
  refreshTokenState,
  accessTokenExpiryState,
} from '../Auth/AuthState';
import { CoreConfig } from '../Core/CoreTypes';

// ...

export function useIsAuthenticated() {
  const isAuthenticated = useRecoilValue(isAuthenticatedSelector);

  return isAuthenticated;
}

function useIsAuthenticationInProgress() {
  const isAuthenticationInProgress = useRecoilValue(authIsInProgressState);
  return isAuthenticationInProgress;
}

// ...

const useLogin = (config: CoreConfig) => {
  const login = useCallback(
    async (code: string) => {
      console.log('logging in', code);

      try {
        const {
          access_token: accessToken,
          refresh_token: refreshToken,
          expires_in: expiresIn,
        } = await getAccessToken({
          code,
          serverBaseUri: config.serverBaseUri,
          redirectUri: config.redirectUri,
        });

        getEventEmitter().emit(AppEventKeys.AuthStateUpdate, {
          accessToken,
          accessTokenExpiry: expiresIn,
          refreshToken,
        });

        const firebaseTokenResponse = await getFirebaseToken({
          token: accessToken,
          serverBaseUri: config.serverBaseUri,
        });

        const { token: firebaseToken } = firebaseTokenResponse;
        await firebase.auth().signInWithCustomToken(firebaseToken);
      } catch (error) {
        console.error('AuthError', error);
        if (error?.code === 'auth/invalid-custom-token') {
          console.warn('invalid custom token');
          // @TOD log out?
        }
        throw error;
      }
    },
    [config.redirectUri, config.serverBaseUri]
  );

  return login;
};

// ...

type InitiateAuthCallback = (redirectUri: string) => void;
type InitiateAuthResponse = (
  initiateAuthCallback: InitiateAuthCallback
) => void;

function useInitiateAuth(config: CoreConfig): InitiateAuthResponse {
  const setStateKey = useSetRecoilState(stateKeyState);
  const setAuthInProgress = useSetRecoilState(authIsInProgressState);
  const tempStateKey = useRef<string>();

  const initiateAuth: InitiateAuthResponse = useCallback(
    (initiateAuthCallback) => {
      setAuthInProgress(true);
      const stateKey = generateStateToken();
      tempStateKey.current = stateKey;
      setStateKey(stateKey);
      initiateAuthCallback(
        getRedirectUrl({
          spotifyAccountsBaseUri: config.spotify.accountsBaseUri,
          spotifyClientID: config.spotify.clientId,
          redirectURI: config.redirectUri,
          scopes: config.scopes,
          state: tempStateKey.current,
        })
      );
    },
    [
      config.redirectUri,
      config.scopes,
      config.spotify.accountsBaseUri,
      config.spotify.clientId,
      setAuthInProgress,
      setStateKey,
    ]
  );

  return initiateAuth;
}

// ...

type ValidateAuthResponse = (state: string, code: string) => Promise<void>;

export function useValidateAuth(config: CoreConfig) {
  const setAuthInProgress = useSetRecoilState(authIsInProgressState);
  const login = useLogin(config);

  const validateAuth: ValidateAuthResponse = useCallback(
    async (state, code) => {
      if (!code || !state) {
        throw new Error('Code or state variable missing');
      }

      try {
        await login(code);
      } catch (error) {
        console.error('Error when logging in', error);
      } finally {
        setAuthInProgress(false);
      }
    },
    [login, setAuthInProgress]
  );

  return validateAuth;
}

// ...

type UseAuthResult = {
  initiateAuth: InitiateAuthResponse;
  validateAuth: ValidateAuthResponse;
  isAuthenticationInProgress: boolean;
  isAuthenticated: boolean;
};

export const useAuth = (): UseAuthResult => {
  useAuthStateSubscriber();
  const config = useConfigSafe();

  const setAuthInitiated = useSetRecoilState(authInitiatedState);
  const isAuthenticationInProgress = useIsAuthenticationInProgress();
  const isAuthenticated = useIsAuthenticated();
  const initiateAuth = useInitiateAuth(config);
  const validateAuth = useValidateAuth(config);

  const result = useMemo(
    () => ({
      initiateAuth,
      validateAuth,
      isAuthenticationInProgress,
      isAuthenticated,
    }),
    [initiateAuth, isAuthenticated, isAuthenticationInProgress, validateAuth]
  );

  useEffect(() => {
    setAuthInitiated(true);
  }, [setAuthInitiated]);

  return result;
};

export const useUser = () => {
  const user = useRecoilValue(userState);

  return user;
};

export const useUserSafe = nullValueGuard(useUser);

export const useLogout = () => {
  const setAccessToken = useSetRecoilState(accessTokenState);
  const setRefreshToken = useSetRecoilState(refreshTokenState);
  const setAccessTokenExpiry = useSetRecoilState(accessTokenExpiryState);

  const logout = useCallback(() => {
    setAccessToken(null);
    setRefreshToken(null);
    setAccessTokenExpiry(null);
  }, [setAccessToken, setAccessTokenExpiry, setRefreshToken]);

  return logout;
};

// ...

const useSaveAccessToken = () => {
  const setAccessToken = useSetRecoilState(accessTokenState);
  const setAccessTokenExpiry = useSetRecoilState(accessTokenExpiryState);

  const saveAccessToken = useCallback(
    (accessToken: string, accessTokenExpiry: number) => {
      setAccessToken(accessToken);
      setAccessTokenExpiry(getAccessTokenExpiryTime(accessTokenExpiry));
    },
    [setAccessToken, setAccessTokenExpiry]
  );

  return saveAccessToken;
};

const useAuthStateSubscriber = () => {
  const setRefreshToken = useSetRecoilState(refreshTokenState);
  const saveAccessToken = useSaveAccessToken();
  const events = useEventEmitter();

  const onAuthStateUpdate = useCallback(
    (userState: AppEventMap['AuthStateUpdate']) => {
      console.log('Received new auth state');

      if (userState !== null) {
        const { accessToken, accessTokenExpiry, refreshToken } = userState;

        setRefreshToken(refreshToken);
        saveAccessToken(accessToken, accessTokenExpiry);
      } else {
        console.log('Not saving auth state...');
      }
    },
    [saveAccessToken, setRefreshToken]
  );

  useEffect(() => {
    events.on(AppEventKeys.AuthStateUpdate, onAuthStateUpdate);

    return () => {
      events.off(AppEventKeys.AuthStateUpdate, onAuthStateUpdate);
    };
  }, [events, onAuthStateUpdate]);
};
