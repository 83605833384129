import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/accordion';
import { HStack, List, ListItem, Text } from '@chakra-ui/layout';
import { format } from 'date-fns';
import { FunctionComponent, ReactNode } from 'react';
import { useResponsiveTextStyle } from '../utilities/ResponsiveUtilities';

type Props = {
  title: string;
  items: {
    date?: Date | null;
    text: ReactNode;
  }[];
};

const Changelog: FunctionComponent<Props> = ({ title, items }) => {
  const labelText = useResponsiveTextStyle('Mini label', 'Label');
  return (
    <Accordion allowToggle>
      <AccordionItem border={0}>
        <AccordionButton
          px={0}
          w="auto"
          textAlign="left"
          _hover={{ bg: 'transparent' }}
          _focus={{ bg: 'transparent' }}
        >
          <Text textStyle={labelText} flexGrow={1}>
            {title}
          </Text>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel px={0}>
          <List spacing={2} fontFamily="monospace" fontSize="xs">
            {items.map((item, key) => (
              <ListItem key={key}>
                <HStack align="top" spacing={3}>
                  {item.date && (
                    <Text flexShrink={0}>
                      {format(item.date, 'yyyy-MM-dd')}
                    </Text>
                  )}
                  <Text>{item.text}</Text>
                </HStack>
              </ListItem>
            ))}
          </List>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default Changelog;
