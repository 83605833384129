import { FunctionComponent, Suspense } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import PlayScreenView from './Play/PlayScreenView';
import PlayScreenMobileView from './Play/PlayScreenMobileView';
import PlayScreenSkeleton from './Play/PlayScreenSkeleton';
import { useIsMobile } from '../utilities/ResponsiveUtilities';

export type UserPlaylist = {
  id: string;
  name: string;
  total: number;
  image: string | null;
  link: string;
};

export type RouteParams = {
  playlistId: 'liked' | string;
};

type Props = {} & RouteComponentProps<RouteParams>;

const PlayScreen: FunctionComponent<Props> = () => {
  const { playlistId } = useParams<RouteParams>();
  const isMobile = useIsMobile();

  return (
    <>
      <Suspense fallback={<PlayScreenSkeleton />}>
        {isMobile ? (
          <PlayScreenMobileView playlistId={playlistId} />
        ) : (
          <PlayScreenView playlistId={playlistId} />
        )}
      </Suspense>
    </>
  );
};

export default PlayScreen;
