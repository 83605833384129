import { FunctionComponent } from 'react';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { trackBackgroundSelector } from '../../state/app/appSelectors';
import { getArtistFromTrack } from '../../utilities/SpotifyUtilities';
import UpNext from '../../components/UpNext';
import { useCurrentTrackMeta } from '@worldeggplant/partypooper-react';
import VoteStandings from '../../components/VoteStandings';
import {
  useHeaderHeight,
  useIsMobile,
} from '../../utilities/ResponsiveUtilities';
import NowPlayingCard from '../../components/NowPlayingCard';
import NowPlayingCardLoading from '../../components/NowPlayingCardLoading';
import { Link } from 'react-router-dom';

type Props = {};

const HomeNowVotingView: FunctionComponent<Props> = () => {
  const trackMeta = useCurrentTrackMeta();
  const backgroundColor = useRecoilValue(
    trackBackgroundSelector(trackMeta?.album.images[0]?.url ?? null)
  );
  const isMobile = useIsMobile();
  const headerHeight = useHeaderHeight();

  return (
    <Flex grow={1} direction="row">
      <Flex basis={{ base: '100%', md: '50%' }} justify="center" grow={1}>
        <Flex direction="column" grow={1} shrink={1}>
          <Flex basis="50%" grow={1} shrink={1} px={{ base: 6, md: 28 }}>
            <Box mt={headerHeight} pt={{ base: 8, md: 16 }}>
              {trackMeta ? (
                <NowPlayingCard
                  hideVotingData
                  title="Vote now"
                  artist={
                    <Tooltip
                      label={`Discover more tracks from ${getArtistFromTrack(
                        trackMeta
                      )}`}
                    >
                      <Link
                        to={`/search/artist:${encodeURI(
                          getArtistFromTrack(trackMeta)
                        )}`}
                      >
                        {getArtistFromTrack(trackMeta)}
                      </Link>
                    </Tooltip>
                  }
                  trackName={trackMeta.name}
                />
              ) : (
                <NowPlayingCardLoading hideVotingData />
              )}
            </Box>
          </Flex>
          <Flex grow={1} shrink={0} basis="50%">
            <VoteStandings />
          </Flex>
        </Flex>
      </Flex>
      {!isMobile && (
        <Flex
          grow={1}
          shrink={0}
          direction="column"
          basis="50%"
          overflowY="hidden"
        >
          <UpNext
            backgroundColor={backgroundColor}
            image={trackMeta?.album.images[0].url || undefined}
            sx={{ paddingTop: headerHeight }}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default HomeNowVotingView;
