import { Box } from '@chakra-ui/react';
import {
  useCurrentTrack,
  useIsVotingActive,
} from '@worldeggplant/partypooper-react';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useIsMobile } from '../utilities/ResponsiveUtilities';

type Props = {};

const VOTING_PERIOD_MS = 30000;

const NowPlayingProgressBar: FunctionComponent<Props> = () => {
  const track = useCurrentTrack();
  const isVotingActive = useIsVotingActive();
  const isMobile = useIsMobile();

  const calculateProgress = useCallback(() => {
    if (track && isVotingActive) {
      const now = Date.now();
      const trackProgress = track
        ? ((now - track.started_at) / VOTING_PERIOD_MS) * 100
        : 0;
      return trackProgress;
    } else {
      return 0;
    }
  }, [track, isVotingActive]);
  const [progress, setProgress] = useState(calculateProgress());

  useEffect(() => {
    const timer = setTimeout(() => {
      setProgress(calculateProgress());
    }, 16.666);

    return () => clearTimeout(timer);
  });

  const height = isMobile ? '3px' : '4px';

  return (
    <Box
      width="100%"
      height={height}
      bg={isVotingActive ? 'black10' : 'transparent'}
    >
      <Box
        backgroundColor="black"
        height={height}
        style={{
          opacity: isVotingActive ? 1 : 0,
          width: `${progress}%`,
        }}
      />
    </Box>
  );
};

export default NowPlayingProgressBar;
