import { Global } from '@emotion/react';
import { FunctionComponent } from 'react';

const Fonts: FunctionComponent = () => (
  <Global
    styles={`  
  @font-face {
      font-family: 'Neue Haas Grotesk Display Pro';
      src: url('/assets/fonts/neue-haas-grotesk-display-pro/NeueHaasDisplay-Medium.eot');
      src: local('Neue Haas Grotesk Display Pro 65 Medium'), local('NeueHaasDisplay-Medium'),
          url('/assets/fonts/neue-haas-grotesk-display-pro/NeueHaasDisplay-Medium.eot?#iefix') format('embedded-opentype'),
          url('/assets/fonts/neue-haas-grotesk-display-pro/NeueHaasDisplay-Medium.woff') format('woff'),
          url('/assets/fonts/neue-haas-grotesk-display-pro/NeueHaasDisplay-Medium.ttf') format('truetype');
      font-weight: 500;
      font-style: normal;
  }
  
  @font-face {
      font-family: 'Neue Haas Grotesk Display Pro';
      src: url('/assets/fonts/neue-haas-grotesk-display-pro/NeueHaasDisplay-Light.eot');
      src: local('Neue Haas Grotesk Display Pro 45 Light'), local('NeueHaasDisplay-Light'),
          url('/assets/fonts/neue-haas-grotesk-display-pro/NeueHaasDisplay-Light.eot?#iefix') format('embedded-opentype'),
          url('/assets/fonts/neue-haas-grotesk-display-pro/NeueHaasDisplay-Light.woff') format('woff'),
          url('/assets/fonts/neue-haas-grotesk-display-pro/NeueHaasDisplay-Light.ttf') format('truetype');
      font-weight: 300;
      font-style: normal;
  }
  
  @font-face {
      font-family: 'Neue Haas Grotesk Display Pro';
      src: url('/assets/fonts/neue-haas-grotesk-display-pro/NeueHaasDisplay-Roman.eot');
      src: local('Neue Haas Grotesk Display Pro 55 Roman'), local('NeueHaasDisplay-Roman'),
          url('/assets/fonts/neue-haas-grotesk-display-pro/NeueHaasDisplay-Roman.eot?#iefix') format('embedded-opentype'),
          url('/assets/fonts/neue-haas-grotesk-display-pro/NeueHaasDisplay-Roman.woff') format('woff'),
          url('/assets/fonts/neue-haas-grotesk-display-pro/NeueHaasDisplay-Roman.ttf') format('truetype');
      font-weight: 400;
      font-style: normal;
  }
  
  @font-face {
      font-family: 'Neue Haas Grotesk Display Pro';
      src: url('/assets/fonts/neue-haas-grotesk-display-pro/NeueHaasDisplay-Bold.eot');
      src: local('Neue Haas Grotesk Display Pro 75 Bold'), local('NeueHaasDisplay-Bold'),
          url('/assets/fonts/neue-haas-grotesk-display-pro/NeueHaasDisplay-Bold.eot?#iefix') format('embedded-opentype'),
          url('/assets/fonts/neue-haas-grotesk-display-pro/NeueHaasDisplay-Bold.woff') format('woff'),
          url('/assets/fonts/neue-haas-grotesk-display-pro/NeueHaasDisplay-Bold.ttf') format('truetype');
      font-weight: 600;
      font-style: normal;
  }
  `}
  />
);

export default Fonts;
