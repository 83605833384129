import { Input } from '@chakra-ui/react';
import { FunctionComponent, useCallback, useState } from 'react';
import { useDebouncedEffect } from '../utilities/useDebouncedEffect';

type Props = {
  onSubmit: (value: string) => void;
  initialValue?: string;
};

const SearchForm: FunctionComponent<Props> = ({ initialValue, onSubmit }) => {
  const [value, setValue] = useState<string>(initialValue || '');
  const [inputChanged, setInputChanged] = useState(false);

  const onSubmitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSubmit(value);
    },
    [onSubmit, value]
  );

  const onChangeValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputChanged(true);
      setValue(event.target.value);
    },
    []
  );

  useDebouncedEffect(
    () => {
      if (inputChanged) {
        onSubmit(value);
      }
    },
    500,
    [value, inputChanged]
  );

  return (
    <form onSubmit={onSubmitForm}>
      <Input
        sx={{
          _placeholder: {
            fontWeight: 600,
          },
        }}
        fontSize={{ base: 'xl', md: '5xl' }}
        variant="unstyled"
        autoFocus
        placeholder="Search for your tune"
        value={value}
        onChange={onChangeValue}
      />
    </form>
  );
};

export default SearchForm;
