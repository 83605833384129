import { Box, Button, Text } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import AlbumCoversImage from '../../assets/images/AlbumCovers.png';
import { useHeaderHeight } from '../../utilities/ResponsiveUtilities';

type Props = {};

const FeedNothingPlayingView: FunctionComponent<Props> = () => {
  const headerHeight = useHeaderHeight();

  return (
    <Box
      background={`url(${AlbumCoversImage}) no-repeat right bottom / 50vh`}
      backgroundColor="wildSand"
      pt={headerHeight}
      flexGrow={1}
      px={28}
    >
      <Text textStyle="Big Title+" mt={30}>
        There’s Nothing Playing
        <br />
        Turn Up
      </Text>

      <Button variant="inverted" mt={12} as={Link} to="/play" width={40}>
        + Queue
      </Button>
    </Box>
  );
};

export default FeedNothingPlayingView;
