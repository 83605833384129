import {
  FunctionComponent,
  Suspense,
  useCallback,
  useMemo,
  useLayoutEffect,
} from 'react';
import { NavLink, Switch, useHistory } from 'react-router-dom';
import Loading, { Size } from '../../components/Loading';
import { Route } from 'react-router-dom';
import PlayPlaylistView from '../Play/PlayPlaylistView';
import {
  AspectRatio,
  Box,
  Flex,
  Icon,
  IconButton,
  Image,
  Link,
  Text,
  useToken,
} from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import InfiniteLoadingList from '../../components/InfiniteLoadingList';
import InfiniteLoadingGrid from '../../components/InfiniteLoadingGrid';
import { Index } from 'react-virtualized';
import { ReactComponent as ListIcon } from '../../assets/icons/list.svg';
import { ReactComponent as GridIcon } from '../../assets/icons/grid.svg';
import {
  playScreenViewMode,
  PlayScreenViewMode,
} from '../../state/app/appState';
import PlayLikedTracksView from '../Play/PlayLikedTracksView';
import {
  usePlaylists,
  useLikedTracks,
  useCurrentProfile,
} from '@worldeggplant/partypooper-react';
import TrackListLoading from '../../components/TrackListLoading';
import {
  useHeaderHeight,
  useIsMobile,
} from '../../utilities/ResponsiveUtilities';

export type UserPlaylist = {
  id: string;
  name: string;
  total: number;
  image: string | null;
  link: string;
};

type Props = {
  playlistId: 'liked' | string;
};

const PlayScreenView: FunctionComponent<Props> = ({ playlistId }) => {
  const headerHeight = useHeaderHeight();
  const [likedTracks] = useLikedTracks();
  const profile = useCurrentProfile();
  const [
    playlistItems,
    loadNextPage,
    nextPage,
    isLoadingNextPage,
  ] = usePlaylists();
  const isMobile = useIsMobile();
  const history = useHistory();

  const listItems = useMemo(() => {
    const items: UserPlaylist[] = [];

    if (likedTracks) {
      items.push({
        id: 'liked',
        name: 'Liked tracks',
        total: likedTracks.total,
        image: profile?.images?.length ? profile.images[0].url : null,
        link: '/play/liked',
      });
    }

    if (playlistItems?.items.length) {
      items.push(
        ...playlistItems.items.map((playlist) => ({
          id: playlist.id,
          name: playlist.name,
          total: playlist.tracks.total,
          image: playlist.images.length ? playlist.images[0].url : null,
          link: `/play/${playlist.id}`,
        }))
      );
    }

    return items;
  }, [likedTracks, playlistItems?.items, profile?.images]);

  const activeColor = useToken('colors', 'black');
  const [viewMode, setViewMode] = useRecoilState(playScreenViewMode);

  const onToggleViewMode = useCallback(() => {
    setViewMode(
      viewMode === PlayScreenViewMode.list
        ? PlayScreenViewMode.grid
        : PlayScreenViewMode.list
    );
  }, [setViewMode, viewMode]);

  const renderItem = useCallback(
    ({ index }: Index) => {
      const item = listItems && listItems[index];

      if (!item) {
        return null;
      }

      switch (viewMode) {
        case PlayScreenViewMode.grid:
          return (
            <Link
              as={NavLink}
              display="block"
              _hover={{ fontStyle: 'normal' }}
              to={item.link}
              title={item.name}
            >
              {item.image !== null && (
                <AspectRatio ratio={1}>
                  <Image
                    sx={
                      playlistId === item.id
                        ? {
                            border: '2px solid black',
                            padding: '2px',
                          }
                        : undefined
                    }
                    src={item.image}
                    fallback={<Box height="100%" width="100%" bg="wildSand" />}
                    width="100%"
                    height="100%"
                    fit="cover"
                  />
                </AspectRatio>
              )}

              <Text
                mt={2}
                as="span"
                display="inline-block"
                textStyle="Regular+"
                width="100%"
                isTruncated
                float="left"
                maxWidth="100%"
              >
                {item.name}
              </Text>
              <Text
                as="span"
                display="inline-block"
                textStyle="Small"
                width="100%"
                float="left"
              >
                {item.total} tracks
              </Text>
            </Link>
          );

        case PlayScreenViewMode.list:
        default:
          return (
            <Link
              as={NavLink}
              exact
              opacity={0.2}
              activeStyle={{
                color: activeColor,
                opacity: 1,
              }}
              _hover={{ fontStyle: 'normal' }}
              to={item.link}
              title={item.name}
            >
              <Text textStyle="Regular+" isTruncated maxWidth="100%">
                {item.name}
              </Text>
            </Link>
          );
      }
    },
    [activeColor, playlistId, listItems, viewMode]
  );

  useLayoutEffect(() => {
    if (!playlistId && !isMobile) {
      history.replace('/play/liked');
    }
  }, [history, isMobile, playlistId]);

  return (
    <>
      {listItems === null ? (
        <Loading />
      ) : (
        <Flex
          grow={1}
          direction="row"
          mt={headerHeight}
          borderTop="1px"
          borderTopColor="silver"
        >
          <Flex
            basis="lg"
            grow={0}
            direction="column"
            px={6}
            align="stretch"
            borderRight="1px"
            borderRightColor="silver"
          >
            <Flex direction="row" pr={3} justify="space-between" align="center">
              <Text textStyle="Large+" py={4}>
                Library
              </Text>
              <IconButton
                variant="unstyled"
                aria-label="Switch mode"
                onClick={onToggleViewMode}
                icon={
                  <Icon width="24px" height="24px">
                    {viewMode === PlayScreenViewMode.list ? (
                      <GridIcon />
                    ) : (
                      <ListIcon />
                    )}
                  </Icon>
                }
              />
            </Flex>

            <Flex grow={1} overflowY="auto">
              {viewMode === PlayScreenViewMode.list ? (
                <InfiniteLoadingList
                  items={listItems}
                  hasNextPage={nextPage !== null}
                  isNextPageLoading={isLoadingNextPage}
                  rowHeight={54}
                  loadNextPage={loadNextPage}
                  renderItem={renderItem}
                  renderItemLoading={<Loading size={Size.sm} noAnimation />}
                />
              ) : (
                <InfiniteLoadingGrid
                  columnCount={2}
                  items={listItems.filter((x) => x.id)}
                  hasNextPage={nextPage !== null}
                  isNextPageLoading={isLoadingNextPage}
                  columnSpacing={20}
                  columnHeight={298}
                  loadNextPage={loadNextPage}
                  renderItem={renderItem}
                  renderItemLoading={<Loading size={Size.sm} noAnimation />}
                />
              )}
            </Flex>
          </Flex>

          <Flex grow={1} direction="column" px={16}>
            <Text textStyle="Large+" py={4}>
              {playlistId === 'liked' || playlistId === ''
                ? 'Liked tracks'
                : listItems.find((x) => x.id === playlistId)?.name ?? ''}
            </Text>

            <Suspense fallback={<TrackListLoading />}>
              <Switch>
                <Route path="/play/liked" component={PlayLikedTracksView} />
                <Route path="/play/:playlistId" component={PlayPlaylistView} />
              </Switch>
            </Suspense>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default PlayScreenView;
