import {
  FunctionComponent,
  PropsWithChildren,
  Suspense,
  useEffect,
} from 'react';
import Loading from './Loading';
import TopNavigation from './TopNavigation';
import { Box, Flex, Collapse } from '@chakra-ui/react';
import NowPlayingBar from './NowPlayingBar';
import { useRecoilValue } from 'recoil';
import { appHasSeenWelcomeState } from '../state/app/appState';
import { useHistory } from 'react-router-dom';
import { WELCOME_VERSION } from '../screens/Splash/WelcomeView';
import { useIsMobile } from '../utilities/ResponsiveUtilities';
import VoteButtons from './VoteButtons';
import { useIsVotingActive } from '@worldeggplant/partypooper-react';

type Props = PropsWithChildren<{
  loading?: boolean;
  hideScreen?: boolean;
}>;

const Screen: FunctionComponent<Props> = ({
  children,
  hideScreen = false,
  loading = false,
}) => {
  const history = useHistory();
  const hasSeenWelcome = useRecoilValue(appHasSeenWelcomeState);
  const isVotingActive = useIsVotingActive();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (hasSeenWelcome === null || hasSeenWelcome < WELCOME_VERSION) {
      history.replace('/');
    }
  }, [hasSeenWelcome, history]);

  if (hideScreen) {
    return <Suspense fallback={<Loading />}>{children}</Suspense>;
  }

  return (
    <Flex direction="column" grow={1} overflowY="auto">
      <TopNavigation />

      <Flex grow={1} overflowY="auto">
        <Suspense fallback={<Loading />}>
          {loading ? <Loading /> : children}
        </Suspense>
      </Flex>

      <Flex shrink={0}>
        <Suspense fallback={null}>
          <NowPlayingBar />
        </Suspense>
      </Flex>

      {isMobile && (
        <Box w="100%" position="relative" shrink={0}>
          <Collapse in={isVotingActive}>
            <Box h={14} w="100%">
              <VoteButtons />
            </Box>
          </Collapse>
        </Box>
      )}
    </Flex>
  );
};

export default Screen;
