import { useEffect } from 'react';
import {
  RouteProps,
  Route,
  Redirect,
  RouteComponentProps,
} from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { redirectState } from '../state/auth/authState';
import {
  ChannelRoot,
  useAuth,
  useCurrentChannel,
  useUser,
} from '@worldeggplant/partypooper-react';
import Debug from '../utilities/debug';
import Loading from './Loading';
import Screen from './Screen';

const debugV = Debug.verbose('navigation');

// overload `any` generic from @types/react-router-dom
type ComponentType<T> =
  | React.ComponentType<RouteComponentProps<T>>
  | React.ComponentType<any>;

type PrivateRouteProps<T> = RouteProps & {
  component: ComponentType<T>;
  hideScreen?: boolean;
};

const PrivateRoute = <T extends unknown>({
  component: PrivateComponent,
  hideScreen = false,
  ...rest
}: PrivateRouteProps<T>) => {
  const { isAuthenticated, isAuthenticationInProgress } = useAuth();
  const user = useUser();
  const setRedirect = useSetRecoilState(redirectState);
  const currentChannel = useCurrentChannel();
  const { location } = rest;

  useEffect(() => {
    if (!isAuthenticated && !isAuthenticationInProgress) {
      debugV('Unauthorized access, redirecting to auth');
      if (location?.pathname) {
        setRedirect(location.pathname);
      }
    }
  }, [
    isAuthenticated,
    location?.pathname,
    isAuthenticationInProgress,
    setRedirect,
  ]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticationInProgress) {
          return <Loading />;
        }

        if (!isAuthenticated) {
          debugV('Private route redirect to /');
          return <Redirect to="/" />;
        }

        if (!user) {
          return <Loading />;
        }

        if (!currentChannel && !location?.pathname.startsWith('/channels')) {
          debugV('No current channel saved, redirecting to channel selector');

          return (
            <Redirect
              to={{ pathname: '/channels', state: { from: location } }}
            />
          );
        }

        return (
          <Screen hideScreen={hideScreen}>
            {currentChannel && <ChannelRoot />}

            <PrivateComponent {...props} />
          </Screen>
        );
      }}
    />
  );
};

export default PrivateRoute;
