type GroupByKey = string | number;
type GroupByReturn<T> = Record<GroupByKey, T[]>;

export function groupBy<T = unknown>(
  collection: T[],
  iteratee: (item: T, index: number) => GroupByKey
): GroupByReturn<T> {
  return collection.reduce<GroupByReturn<T>>((result, value, index) => {
    const groupKey = iteratee(value, index);
    const copy = { ...result };

    if (!result.hasOwnProperty(groupKey)) {
      copy[groupKey] = [];
    }

    copy[groupKey].push(value);

    return copy;
  }, {});
}
