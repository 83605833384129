import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  RecoilState,
  SerializableParam,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { useUser } from '../Auth/AuthHooks';
import { isAuthenticatedSelector } from '../Auth/AuthState';
import { nullValueGuard } from '../utilities/hook';
import { ExtractPropertyNames, Unwrap } from '../utilities/type';
import {
  spotifyApiCredentialSelector,
  devicesState,
  devicesLoadingState,
  currentDeviceState,
  profileSelector,
  genericLoadState,
  trackMetaState,
  spotifyGenericLoadState,
  spotifyGenericState,
} from './SpotifyState';
import {
  DevicesState,
  PagingFunctions,
  SpotifyFunctions,
} from './SpotifyTypes';
import { spotifyApiClient } from './SpotifyUtilities';

function useSpotifyApi() {
  const spotifyApiPromise = useRecoilCallback(
    ({ snapshot }) => async () => {
      const credentials = await snapshot.getPromise(
        spotifyApiCredentialSelector
      );
      return spotifyApiClient(credentials);
    },
    []
  );

  return spotifyApiPromise;
}

// ...

export const useProfile = (uid?: string) => {
  const profile = useRecoilValue(profileSelector({ uid }));

  return profile;
};

export const useCurrentProfile = () => {
  const user = useUser();
  const profile = useProfile(user?.uid);

  return profile;
};

export const useCurrentProfileSafe = nullValueGuard(useProfile);

// ...

export const useDevices = (): [DevicesState, boolean, () => Promise<void>] => {
  const spotifyApi = useSpotifyApi();
  const [devices, setDevices] = useRecoilState(devicesState);
  const [isLoading, setIsLoading] = useRecoilState(devicesLoadingState);
  const isAuthenticated = useRecoilValue(isAuthenticatedSelector);

  const loadDevices = useCallback(async () => {
    setIsLoading(true);

    try {
      const result = await spotifyApi().then((api) => api.getMyDevices());
      setDevices(result.devices);
    } catch (e) {
      console.error('Error when fetching devices', e);
      setDevices([]);
    }

    setIsLoading(false);
  }, [setDevices, setIsLoading, spotifyApi]);

  useEffect(() => {
    if (isAuthenticated && devices === null && !isLoading) {
      loadDevices();
    }
  }, [devices, isAuthenticated, isLoading, loadDevices]);

  return [devices, isLoading, loadDevices];
};

export const useTrackMeta = (trackUri: string) => {
  const trackMeta = useRecoilValue(trackMetaState({ trackUri }));

  return trackMeta;
};

export const useGetUserSpotifyDevices = () => {
  const spotifyApi = useSpotifyApi();

  const getUserSpotifyDevices = useCallback(async () => {
    try {
      const result = await spotifyApi().then((spotify) =>
        spotify.getMyDevices()
      );
      return result.devices;
    } catch (e) {
      return [];
    }
  }, [spotifyApi]);
  return getUserSpotifyDevices;
};

export const useCurrentDevice = () => {
  const currentPlayerDevice = useRecoilValue(currentDeviceState);

  return currentPlayerDevice;
};

export const useSetCurrentDevice = () => {
  const setCurrentPlayerDevice = useSetRecoilState(currentDeviceState);

  return setCurrentPlayerDevice;
};

export const usePlaySpotifyTrack = () => {
  const spotifyApi = useSpotifyApi();
  const currentPlayerDevice = useRecoilValue(currentDeviceState);

  const playSpotifyTrack = useCallback(
    async ({
      spotifyUri,
      position,
    }: {
      spotifyUri: string;
      position: number;
    }) => {
      try {
        if (!currentPlayerDevice?.id) {
          console.warn('No player device selected');
          return;
        }

        console.log(`Playing ${spotifyUri} to ${currentPlayerDevice.id}`);

        const api = await spotifyApi();
        api.play({
          device_id: currentPlayerDevice.id,
          uris: [spotifyUri],
          position_ms: position,
        });
      } catch (e) {
        console.error('playSpotifyTrack error', e);
      }
    },
    [currentPlayerDevice, spotifyApi]
  );

  return playSpotifyTrack;
};

export const usePauseSpotifyTrack = () => {
  const spotifyApi = useSpotifyApi();
  const currentPlayerDevice = useRecoilValue(currentDeviceState);

  const pauseSpotifyTrack = useCallback(async () => {
    try {
      if (!currentPlayerDevice?.id) {
        console.warn('No player device selected');
        return;
      }

      console.log(`Pausing playback ${currentPlayerDevice}`);

      const api = await spotifyApi();
      api.pause({
        device_id: currentPlayerDevice.id,
      });
    } catch (e) {
      console.error('pauseSpotifyTrack error', e);
    }
  }, [spotifyApi, currentPlayerDevice]);

  return pauseSpotifyTrack;
};

export const useGetSpotifyPlaybackStatus = () => {
  const spotifyApi = useSpotifyApi();
  const currentPlayerDevice = useRecoilValue(currentDeviceState);

  const getPlaybackStatus = useCallback(async () => {
    try {
      if (!currentPlayerDevice?.id) {
        console.warn('No player device selected');
        return;
      }

      console.log(`Checking playback ${currentPlayerDevice}`);

      const api = await spotifyApi();
      const status = await api.getMyCurrentPlaybackState();

      return status;
    } catch (e) {
      console.error('getPlaybackStatus error', e);
    }

    return null;
  }, [spotifyApi, currentPlayerDevice]);

  return getPlaybackStatus;
};

export const useLoadPlaylist = () => {
  const spotifyApi = useSpotifyApi();

  const loadPlaylist = useCallback(
    async (playlistId: string) => {
      try {
        const result = await spotifyApi().then((spotify) =>
          spotify.getPlaylist(playlistId)
        );
        return result;
      } catch (error) {
        console.error('loadPlaylist error', error);
        return null;
      }
    },
    [spotifyApi]
  );

  return loadPlaylist;
};

type LoadNextFunction = () => void;
type NextUri = string | null;
type IsNextPageLoading = boolean;
type UseSpotifyItemsLoaderHookProps<K, O> = {
  method: K;
  options?: O;
};

export function useSpotifyItemsLoaderHook<
  T extends Unwrap<ReturnType<PagingFunctions[K]>>,
  K extends keyof PagingFunctions,
  O extends Parameters<PagingFunctions[K]>
>({
  method,
  options,
}: UseSpotifyItemsLoaderHookProps<K, O>): [
  T | null,
  LoadNextFunction,
  NextUri,
  IsNextPageLoading
] {
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
  const params = useMemo(() => {
    return { method, options } as SerializableParam;
  }, [method, options]);
  const initialData = useRecoilValue(spotifyGenericLoadState(params)) as T;
  const [allData, setAllData] = useRecoilState(
    spotifyGenericState(params) as RecoilState<T>
  );
  const root = allData || initialData;
  const nextPage = useMemo(() => {
    if (root !== null && 'next' in root) {
      return root.next;
    }

    return null;
  }, [root]);

  const loadNextPage = useRecoilCallback(
    ({ snapshot, set }) => async (): Promise<void> => {
      if (nextPage === null) {
        return;
      }

      setIsLoadingNextPage(true);

      try {
        const results = (await snapshot.getPromise(
          genericLoadState({
            path: nextPage,
          })
        )) as NonNullable<T>;

        set(spotifyGenericState(params), (prevResult: T) => {
          return {
            ...results,
            items: [...prevResult.items, ...results.items],
          };
        });
      } catch (error) {
        console.error('useSpotifyItemsLoaderHook loadNextPage error', error);
      }

      setIsLoadingNextPage(false);
    },
    [nextPage, params]
  );

  useEffect(() => {
    if (initialData && !allData) {
      setAllData(initialData);
    }
  }, [allData, initialData, setAllData]);

  return [root, loadNextPage, nextPage, isLoadingNextPage];
}

type UseSpotifyLoaderHookDeluxeProps<SpotifyFunctionKeys, Options, ItemKey> = {
  method: SpotifyFunctionKeys;
  options: Options;
  itemKey: ItemKey;
  pagedResultsReturnsRoot?: boolean;
};

type ItemsType = SpotifyApi.PagingObject<unknown>;

export function useSpotifyLoaderHook<
  SpotifyFunctionKeys extends keyof SpotifyFunctions,
  SpotifyFunction extends SpotifyFunctions[SpotifyFunctionKeys],
  RootType extends Unwrap<ReturnType<SpotifyFunction>>,
  Options extends Parameters<SpotifyFunction>,
  ItemKey extends ExtractPropertyNames<RootType, ItemsType>
>({
  method,
  options,
  itemKey,
  pagedResultsReturnsRoot,
}: UseSpotifyLoaderHookDeluxeProps<SpotifyFunctionKeys, Options, ItemKey>): [
  RootType,
  LoadNextFunction,
  NextUri,
  IsNextPageLoading
] {
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
  const params = useMemo(() => {
    return { method, options } as SerializableParam;
  }, [method, options]);
  const initialData = useRecoilValue(
    spotifyGenericLoadState(params)
  ) as RootType;
  const [allData, setAllData] = useRecoilState(
    spotifyGenericState(params) as RecoilState<RootType>
  );
  const root = allData || initialData;

  const items = useMemo(() => {
    if (root !== null && root[itemKey]) {
      return root[itemKey] as ItemsType;
    }

    return null;
  }, [itemKey, root]);
  const nextPage = useMemo(() => {
    if (items !== null && items.next) {
      return items.next;
    }

    return null;
  }, [items]);

  const loadNextPage = useRecoilCallback(
    ({ snapshot, set }) => async (): Promise<void> => {
      if (nextPage === null) {
        return;
      }

      setIsLoadingNextPage(true);

      try {
        const results = await snapshot.getPromise(
          genericLoadState({
            path: nextPage,
          })
        );

        set(spotifyGenericState(params), (prevRoot: RootType) => {
          const prevItems = prevRoot[itemKey] as ItemsType;
          const newItems = pagedResultsReturnsRoot
            ? ((results as RootType)[itemKey] as ItemsType)
            : (results as ItemsType);

          return {
            ...prevRoot,
            [itemKey]: {
              ...newItems,
              items: [...prevItems.items, ...newItems.items],
            },
          };
        });
      } catch (error) {
        console.error('useSpotifyLoaderHook loadNextPage error', error);
      }

      setIsLoadingNextPage(false);
    },
    [itemKey, nextPage, pagedResultsReturnsRoot, params]
  );

  useEffect(() => {
    if (initialData && !allData) {
      setAllData(initialData);
    }
  }, [allData, initialData, setAllData]);

  return [root, loadNextPage, nextPage, isLoadingNextPage];
}

export const useSearch = (searchTerm: string) => {
  const generic = useSpotifyLoaderHook({
    method: 'searchTracks',
    itemKey: 'tracks',
    options: [searchTerm],
    pagedResultsReturnsRoot: true,
  });

  return generic;
};

// ...

export const usePlaylists = () => {
  const generic = useSpotifyItemsLoaderHook({
    method: 'getUserPlaylists',
  });

  return generic;
};

// ...

export const usePlaylist = (playlistId: string) => {
  const generic = useSpotifyLoaderHook({
    method: 'getPlaylist',
    itemKey: 'tracks',
    options: [playlistId],
  });

  return generic;
};

// ...

export const useLikedTracks = () => {
  const generic = useSpotifyItemsLoaderHook({
    method: 'getMySavedTracks',
  });

  return generic;
};
