import { useBreakpointValue } from '@chakra-ui/media-query';
import { useTheme } from '@chakra-ui/system';
import {
  RefObject,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRecoilState } from 'recoil';
import { appIsMobileState } from '../state/app/appState';
import {
  TOP_NAVIGATION_HEIGHT_MOBILE,
  TOP_NAVIGATION_HEIGHT_DESKTOP,
  textStyles,
} from '../Theme';

export const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useRecoilState(appIsMobileState);
  const { breakpoints } = useTheme();
  const mobileBreakpoint = breakpoints[1];
  const mobileMaxPxWidth = useMemo(
    () =>
      parseInt(
        (mobileBreakpoint as string).endsWith('em')
          ? parseInt(mobileBreakpoint, 10) * 16
          : mobileBreakpoint,
        10
      ),
    [mobileBreakpoint]
  );
  const isMobileQuery = window.innerWidth < mobileMaxPxWidth;

  useLayoutEffect(() => {
    setIsMobile(isMobileQuery);
  }, [isMobileQuery, setIsMobile]);

  return isMobile;
};

export const useHeaderHeight = (): string => {
  const isMobile = useIsMobile();
  const height = isMobile
    ? TOP_NAVIGATION_HEIGHT_MOBILE
    : TOP_NAVIGATION_HEIGHT_DESKTOP;

  return `${height}px`;
};

type TextStyleName = keyof typeof textStyles;

export const useResponsiveTextStyle = (
  mobile: TextStyleName,
  desktop: TextStyleName
): TextStyleName => {
  const textStyle = useBreakpointValue({ base: mobile, md: desktop }) || mobile;

  return textStyle;
};

type ElementDimensions = {
  height: number;
  width: number;
} | null;

export const useElementDimensions = (): [
  ElementDimensions,
  RefObject<HTMLDivElement> | null
] => {
  const [dimensions, setDimensions] = useState<ElementDimensions>(null);
  const ref = useRef<HTMLDivElement>(null);

  const onResize: ResizeObserverCallback = useCallback(
    (entries: ResizeObserverEntry[]) => {
      const el = entries[0].target as HTMLElement;

      setDimensions({
        height: el.clientHeight || 0,
        width: el.clientWidth || 0,
      });
    },
    []
  );

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(onResize);

    ref.current && resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [onResize]);

  return [dimensions, ref];
};
