import {
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  StackItem,
  Text,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { FunctionComponent, useCallback } from 'react';
import { getArtistFromTrack } from '../utilities/SpotifyUtilities';
import { millisecondsToHumanReadable } from '../utilities/DateUtilities';
import { useRecoilState } from 'recoil';
import { queuedTracksState } from '../state/app/appState';
import { Index } from 'react-virtualized';

import { ReactComponent as AddIcon } from '../assets/icons/add.svg';
import { ReactComponent as CheckIcon } from '../assets/icons/check.svg';
import InfiniteLoadingList from './InfiniteLoadingList';
import { useQueueTrack } from '@worldeggplant/partypooper-react';
import TrackListHeader from './TrackListHeader';
import TrackListLoadingItem from './TrackListLoadingItem';
import {
  useIsMobile,
  useResponsiveTextStyle,
} from '../utilities/ResponsiveUtilities';
import ArtistLink from './ArtistLink';

type Props = {
  tracks: SpotifyApi.TrackObjectFull[];
  loadNextPage: () => void;
  onActive?: (trackId: string) => void;
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  compactMode?: boolean;
};

const TrackList: FunctionComponent<Props> = ({
  tracks,
  loadNextPage,
  onActive,
  hasNextPage,
  isNextPageLoading,
  compactMode = false,
}) => {
  const toast = useToast();
  const isMobile = useIsMobile();
  const trackNameText = useResponsiveTextStyle('Medium+', 'Medium');
  const artistAlbumText = useResponsiveTextStyle('Mini', 'Small');
  const artistAlbumOpacity = useBreakpointValue({ base: 1, md: 0.7 });
  const queueTrack = useQueueTrack();
  const [queuedTracks, setQueuedTracks] = useRecoilState(queuedTracksState);
  const onClickQueueTrack = useCallback(
    (track: SpotifyApi.TrackObjectFull) => async () => {
      try {
        await queueTrack(track);
        setQueuedTracks((currQueuedTracks) => [...currQueuedTracks, track.uri]);
      } catch (error) {
        toast.closeAll();
        toast({
          title: 'Could not queue track',
          description: error.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    },
    [queueTrack, setQueuedTracks, toast]
  );

  const onMouseEnterItem = useCallback(
    (trackId: string) => () => {
      onActive && onActive(trackId);
    },
    [onActive]
  );

  const renderItem = useCallback(
    ({ index }: Index) => {
      const track = tracks[index];
      if (!track) {
        return null;
      }

      return (
        <>
          <Divider orientation="horizontal" color="silver" opacity={1} />
          <Flex
            direction="row"
            grow={1}
            py="12px"
            align="center"
            onMouseEnter={onMouseEnterItem(track.id)}
          >
            <Box height={12} width={12} mr={3} flexShrink={0}>
              {track.album.images.length > 0 && (
                <Image
                  src={track.album.images[0].url}
                  height={12}
                  width={12}
                  fit="cover"
                  fallback={<Box height={12} width={12} bg="wildSand" />}
                />
              )}
            </Box>
            <Flex direction="column" grow={1} shrink={1} isTruncated>
              <Box>
                <Text textStyle={trackNameText} as="span" isTruncated>
                  {track.name}
                </Text>
              </Box>
              <Box>
                <Text
                  textStyle={artistAlbumText}
                  opacity={artistAlbumOpacity}
                  as="span"
                  isTruncated
                >
                  <ArtistLink artist={getArtistFromTrack(track)} />
                  <Text as="span" px={1}>
                    &bull;
                  </Text>
                  {track.album.name}
                </Text>
              </Box>
            </Flex>
            <Flex direction="column" shrink={0} ml="auto" pl={3}>
              <HStack spacing={0}>
                <StackItem
                  w={!compactMode ? { base: '72px', sm: '168px' } : undefined}
                >
                  <Flex direction="row" alignItems="center" width="100%">
                    <Text textStyle="Medium">
                      {millisecondsToHumanReadable(track.duration_ms)}
                    </Text>
                    <IconButton
                      ml="auto"
                      aria-label="Queue track"
                      colorScheme="transparent"
                      onClick={onClickQueueTrack(track)}
                      icon={
                        queuedTracks.includes(track.uri) ? (
                          <Icon
                            height="24px"
                            width="24px"
                            color="black"
                            opacity={0.3}
                          >
                            <CheckIcon />
                          </Icon>
                        ) : (
                          <Icon height="24px" width="24px" color="black">
                            <AddIcon />
                          </Icon>
                        )
                      }
                    />
                  </Flex>
                </StackItem>
              </HStack>
            </Flex>
          </Flex>
        </>
      );
    },
    [
      artistAlbumOpacity,
      artistAlbumText,
      compactMode,
      onClickQueueTrack,
      onMouseEnterItem,
      queuedTracks,
      trackNameText,
      tracks,
    ]
  );

  return (
    <>
      <Flex direction="column" grow={1} width="100%">
        {!compactMode && !isMobile && <TrackListHeader />}

        <Flex grow={1} direction="column" position="relative">
          <InfiniteLoadingList
            items={tracks}
            hasNextPage={hasNextPage}
            isNextPageLoading={isNextPageLoading}
            rowHeight={73}
            loadNextPage={loadNextPage}
            renderItem={renderItem}
            renderItemLoading={
              <TrackListLoadingItem compactMode={compactMode} />
            }
          />
        </Flex>
      </Flex>
    </>
  );
};

export default TrackList;
