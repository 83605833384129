import { FunctionComponent, memo, Suspense } from 'react';
import { NavLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { navigationState } from '../state/app/appState';
import { Divider, Flex, IconButton } from '@chakra-ui/react';
import NavigationLink from './NavigationLink';
import { ReactComponent as Logo } from '../assets/icons/partypooper-logo.svg';
import ProfileMenu from './ProfileMenu';
import FeedUnreadCount from './FeedUnreadCount';
import { useIsMobile } from '../utilities/ResponsiveUtilities';

type Props = {};

const TopNavigation: FunctionComponent<Props> = () => {
  const isMobile = useIsMobile();
  const { currentPlaylistId, currentSearchTerm } = useRecoilValue(
    navigationState
  );

  return (
    <Flex
      as="nav"
      px={{ base: 5, md: 8 }}
      py={{ base: 4, md: 4 }}
      justify="space-between"
      sx={{
        position: 'absolute',
        left: 0,
        right: 0,
        zIndex: 'docked',
      }}
    >
      <Flex direction="row" align="stretch" justify="center">
        <IconButton
          width={{ base: 6, md: 8 }}
          height={{ base: 6, md: 8 }}
          as={NavLink}
          sx={{ opacity: 0.5, _hover: { opacity: 1 } }}
          activeStyle={{
            opacity: 1,
          }}
          colorScheme="transparent"
          aria-label="Home"
          mr={3}
          to="/home"
          icon={<Logo />}
        />

        {!isMobile && (
          <Flex direction="row" alignItems="center">
            <Divider
              colorScheme="silver"
              height={6}
              orientation="vertical"
              mx={2}
            />

            <NavigationLink to={`/play/${currentPlaylistId || ''}`}>
              Play
            </NavigationLink>
            <NavigationLink to="/feed">
              Feed
              <FeedUnreadCount />
            </NavigationLink>
            <NavigationLink to={`/search/${currentSearchTerm || ''}`}>
              Search
            </NavigationLink>
          </Flex>
        )}
      </Flex>

      <Suspense fallback={null}>
        <ProfileMenu />
      </Suspense>
    </Flex>
  );
};

export default memo(TopNavigation);
