import { useSearch } from '@worldeggplant/partypooper-react';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import Loading from '../../components/Loading';
import TrackList from '../../components/TrackList';
import { searchActiveAlbumImageState } from '../../state/search/searchState';

export type RouteParams = {
  searchTerm: string;
};

type Props = RouteComponentProps<RouteParams>;

const SearchResults: FunctionComponent<Props> = () => {
  const { searchTerm } = useParams<RouteParams>();
  const [search, loadNextPage, nextPage, isNextPageLoading] = useSearch(
    searchTerm
  );
  const [searchActiveAlbumImage, setSearchActiveAlbumImage] = useRecoilState(
    searchActiveAlbumImageState({ searchTerm })
  );

  const onSetActive = useCallback(
    (trackId: string) => {
      const track = search?.tracks?.items.find((t) => t.id === trackId);
      if (track && track.album.images.length > 0) {
        setSearchActiveAlbumImage(track.album.images[0].url);
      }
    },
    [search?.tracks?.items, setSearchActiveAlbumImage]
  );

  useEffect(() => {
    if (searchActiveAlbumImage === null) {
      const firstTrack = search?.tracks?.items.find(
        (t) => t.album.images.length > 0
      );

      if (firstTrack) {
        setSearchActiveAlbumImage(firstTrack.album.images[0].url);
      }
    }
  }, [
    searchActiveAlbumImage,
    search?.tracks?.items,
    setSearchActiveAlbumImage,
  ]);

  return (
    <>
      {search === null && searchTerm !== '' && <Loading />}
      {search && search?.tracks.items.length > 0 && (
        <TrackList
          tracks={search?.tracks.items}
          onActive={onSetActive}
          loadNextPage={loadNextPage}
          isNextPageLoading={isNextPageLoading}
          hasNextPage={!!nextPage}
          compactMode
        />
      )}
    </>
  );
};

export default SearchResults;
