import {
  extendTheme,
  theme as chakraDefaults,
  ThemeOverride,
} from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import { getColorWithOpacity } from './utilities/ColorUtilities';

export const TOP_NAVIGATION_HEIGHT_DESKTOP = 64;
export const TOP_NAVIGATION_HEIGHT_MOBILE = 56;

const breakpoints = createBreakpoints({
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
});

const fonts = {
  body: 'Neue Haas Grotesk Display Pro, sans-serif',
  heading: 'Neue Haas Grotesk Display Pro, sans-serif',
  monospace: 'Menlo, monospace',
};

const sizes = {
  ...chakraDefaults.sizes,
};

const fontSizes = {
  '3xs': 8,
  '2xs': 10,
  xs: 12,
  sm: 14,
  md: 16,
  lg: 18,
  xl: 20,
  '2xl': 24,
  '3xl': 28,
  '4xl': 36,
  '5xl': 48,
  '6xl': 64,
};

const letterSpacings = {
  ...chakraDefaults.letterSpacings,
  tight: '-0.004em',
  normal: '-0.004em',
};

const lineHeights = {
  ...chakraDefaults.lineHeights,
  shortest: 1.1,
};

export const textStyles = {
  body: {
    color: 'body',
    fontSize: { base: 'sm', sm: 'md' },
  },
  sup: {
    fontSize: 'sm',
    fontWeight: 500,
    position: 'relative',
    right: -2,
    top: -1,
  },
  'Big Title+': {
    color: 'body',
    fontSize: '5xl',
    fontWeight: 500,
    lineHeight: 'shorter',
  },
  'Title+': {
    color: 'body',
    fontSize: '4xl',
    fontWeight: 500,
    lineHeight: 'shorter',
  },
  Title: {
    color: 'body',
    fontSize: '4xl',
    fontWeight: 300,
    lineHeight: 'short',
  },
  'Large+': {
    color: 'body',
    fontSize: '2xl',
    fontWeight: 500,
    lineHeight: 'shorter',
  },
  Large: {
    color: 'body',
    fontSize: '2xl',
    fontWeight: 300,
    lineHeight: 'shorter',
  },
  'Medium+': {
    color: 'body',
    fontSize: 'md',
    fontWeight: 500,
    lineHeight: 'none',
  },
  Medium: {
    color: 'body',
    fontSize: 'md',
    fontWeight: 400,
    lineHeight: 'short',
  },
  'Regular+': {
    color: 'body',
    fontSize: 'lg',
    fontWeight: 500,
    lineHeight: 'short',
  },
  Regular: {
    color: 'body',
    fontSize: 'lg',
    fontWeight: 400,
    lineHeight: 'shorter',
  },
  'Small+': {
    color: 'body',
    fontSize: 'sm',
    fontWeight: 600,
    lineHeight: 'short',
  },
  Small: {
    color: 'body',
    fontSize: 'sm',
    fontWeight: 400,
    lineHeight: 'none',
    letterSpacings: 'tighter',
  },
  'Mini+': {
    color: 'body',
    fontSize: 'xs',
    fontWeight: 600,
    lineHeight: 'short',
  },
  Mini: {
    color: 'body',
    fontSize: 'xs',
    fontWeight: 400,
    lineHeight: 'short',
  },
  Label: {
    color: 'grey',
    fontSize: 'xs',
    fontWeight: 600,
    lineHeight: 'short',
    textTransform: 'uppercase',
    letterSpacing: 'wider',
  },
  'Mini label': {
    color: 'grey',
    fontSize: '2xs',
    fontWeight: 600,
    lineHeight: 'short',
    textTransform: 'uppercase',
    letterSpacing: 'wider',
  },
};
//                0  1  2  3   4   5   6
// const space = [0, 4, 8, 16, 32, 48, 64];
const spacing = {
  px: '1px',
  0.5: '0.125rem',
  1: '0.25rem',
  1.5: '0.375rem',
  2: '0.5rem',
  2.5: '0.625rem',
  3: '0.75rem',
  3.5: '0.875rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  8: '2rem',
  9: '2.25rem',
  10: '2.5rem',
  12: '3rem',
  14: '3.5rem',
  16: '4rem',
  20: '5rem',
  24: '6rem',
  28: '7rem',
  32: '8rem',
  36: '9rem',
  40: '10rem',
  44: '11rem',
  48: '12rem',
  52: '13rem',
  56: '14rem',
  60: '15rem',
  64: '16rem',
  72: '18rem',
  80: '20rem',
  96: '24rem',
};

const colors = {
  fuscia: '#F905BE',
  body: '#333333',
  bodyLight: '#FFFFFF',
  silver: '#EBEBEB',
  grey: '#666666',
  black: '#000000',
  black80: getColorWithOpacity('#000000', 80),
  black30: getColorWithOpacity('#000000', 10),
  black10: getColorWithOpacity('#000000', 10),
  pressedBlack: '#aaaaaa',
  white: '#FFFFFF',
  offWhite: '#FAFAFA',
  pressedWhite: '#F2F2F2',
  fadedWhite: '#FAFAFA',
  wildSand: '#F4F4F4',

  cornflowerBlue: '#6978FF',
  luckyPointBlue: '#1D2677',
  mauvePink: '#F7A5FF',
  brightGreen: '#C2F741',
  turboYellow: '#F7E300',

  brand: {
    900: '#F905BE',
  },
};

// ...

const NavigationLink = {
  baseStyle: {
    color: 'black',
    fontWeight: 600,
    fontSize: { base: 'xl', sm: 'sm' },
    mx: 3,
    opacity: 0.7,
    _hover: {
      textDecoration: 'none',
      opacity: 1,
    },
  },
};

const Button = {
  variants: {
    outline: {
      borderRadius: 'none',
      borderColor: 'black',

      _hover: {
        backgroundColor: 'transparent',
        borderColor: 'pressedBlack',
      },
    },
    inverted: {
      borderRadius: 'none',
      borderColor: 'body',
      ...textStyles['Medium+'],
      bg: 'body',
      color: 'white',

      _hover: {
        opacity: 0.8,
      },
    },
    vote: {
      backgroundColor: 'black80',
      borderRadius: 'none',
      color: 'white',
      fontSize: 'lg',
      px: 4,
      height: '100%',
      minWidth: 0,

      _hover: {
        backgroundColor: 'black',
      },

      _disabled: {
        backgroundColor: 'black80',
      },
    },
    unstyled: {
      p: 0,
      height: 'auto',
      minW: 'auto',
      minH: 'auto',
    },
    unstyledFlex: {
      p: 0,
      height: 'auto',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      py: 1,
    },
  },
};

const Menu = {
  parts: ['menu', 'item'],
  variants: {
    profile: {
      button: {
        fontSize: 'sm',
        fontWeight: 600,
      },
      groupTitle: {
        mx: 0,
        type: 'radio',
      },
      list: {
        px: 3,
      },
      item: {
        py: 1,
        _hover: {
          bg: 'transparent',
        },
        _focus: {
          bg: 'transparent',
        },
      },
    },
  },
};
// ...

const styles = {
  global: {
    'html, body, #root': {
      width: '100%',
      height: '100%',
    },
    '#root': {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
    },
  },
};

// ...

const theme = extendTheme({
  breakpoints,
  colors,
  fonts,
  fontSizes,
  letterSpacings,
  lineHeights,
  textStyles,
  space: spacing,
  sizes,

  styles,

  components: {
    Button,
    NavigationLink,
    Menu,
  },
} as ThemeOverride);

export default theme;
