import { atom } from 'recoil';

export type ErrorState = null | {
  message: string;
  error?: Error;
};

export const errorState = atom<ErrorState>({
  key: 'errorState',
  default: null,
});
