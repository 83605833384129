import { FunctionComponent } from 'react';
import FeedItem from './FeedItem';

type Props = {
  title: string;
  message: string;
  timestamp: number;
  uid?: string;
};

const FeedMessage: FunctionComponent<Props> = ({
  uid,
  title,
  message,
  timestamp,
}) => {
  return (
    <FeedItem
      uid={uid}
      title={title}
      message={message}
      timestamp={timestamp}
      hideVotes
    />
  );
};

export default FeedMessage;
