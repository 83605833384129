export type Config = {
  spotify: {
    accountsBaseUri: string;
    apiBaseUri: string;
    clientId: string;
  };
  serverBaseUri: string;
  redirectUri: string;
  scopes: string;
  firebase: {
    appId: string;
    apiKey: string;
    authDomain: string;
    databaseUrl: string;
    messagingSenderId: string;
    projectId: string;
    storageBucket: string;
  };
};

export function getConfig(): Config {
  return {
    spotify: {
      accountsBaseUri: process.env
        .REACT_APP_SPOTIFY_ACCOUNTS_BASE_URI as string,
      apiBaseUri: process.env.REACT_APP_SPOTIFY_API_BASE_URI as string,
      clientId: process.env.REACT_APP_SPOTIFY_CLIENT_ID as string,
    },
    serverBaseUri: process.env.REACT_APP_SERVER_BASE_URI as string,
    redirectUri: process.env.REACT_APP_REDIRECT_URI as string,
    scopes: process.env.REACT_APP_SCOPES as string,
    firebase: {
      appId: process.env.REACT_APP_FIREBASE_APP_ID as string,
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY as string,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN as string,
      databaseUrl: process.env.REACT_APP_FIREBASE_DATABASE_URL as string,
      messagingSenderId: process.env
        .REACT_APP_FIREBASE_MESSAGING_SENDER_ID as string,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID as string,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET as string,
    },
  };
}
