import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useCurrentChannelId } from '../Channel/ChannelHooks';
import { addChatMessage } from '../utilities/firebase';
import { chatMessagesSelector } from './ChatState';

export function useMessages() {
  const currentChannelId = useCurrentChannelId();
  const messages = useRecoilValue(
    chatMessagesSelector({ channelId: currentChannelId })
  );

  return messages;
}

export function useSendMessage() {
  const currentChannelId = useCurrentChannelId();

  const sendMessage = useCallback(
    async (body: string): Promise<void> => {
      if (currentChannelId !== null) {
        await addChatMessage({
          channelId: currentChannelId,
          body,
        });
      }
    },
    [currentChannelId]
  );

  return sendMessage;
}
