import {
  AspectRatio,
  Box,
  ChakraProps,
  Flex,
  SkeletonCircle,
} from '@chakra-ui/react';
import { FunctionComponent } from 'react';

type Props = {
  backgroundColor: string;
  image?: string;
  sx?: ChakraProps['sx'];
};

const UpNext: FunctionComponent<Props> = ({ backgroundColor, image, sx }) => {
  return (
    <Flex
      grow={1}
      direction="column"
      justify="center"
      align="center"
      overflowY="hidden"
      sx={{
        ...sx,
        backgroundColor,
      }}
    >
      <Box mt="auto" mb="-50%" width="100%" alignSelf="flex-end">
        <AspectRatio width="100%" ratio={1}>
          {image ? (
            <Box
              borderRadius="full"
              sx={{
                background: `url(${image}) no-repeat 50% 50% / cover`,
              }}
            />
          ) : (
            <SkeletonCircle height="100%" width="100%" />
          )}
        </AspectRatio>
      </Box>
    </Flex>
  );
};

export default UpNext;
