import { selectorFamily } from 'recoil';
import {
  getColorFromImage,
  getColorWithOpacity,
} from '../../utilities/ColorUtilities';

type TrackBackgroundSelectorResult = string;
type TrackBackgroundSelectorParams = SpotifyApi.ImageObject['url'] | null;

export const trackBackgroundSelector = selectorFamily<
  TrackBackgroundSelectorResult,
  TrackBackgroundSelectorParams
>({
  key: 'trackBackgroundSelector',
  get: (imageUrl) => async () => {
    if (imageUrl) {
      const color = await getColorFromImage(imageUrl);

      if (color) {
        return getColorWithOpacity(color as string, 30);
      }
    }

    return '#FFFFFF';
  },
});
