import {
  FunctionComponent,
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Feed from '../components/Feed';
import ChatInput from '../components/ChatInput';
import { Box, Divider, Flex, Skeleton, Text } from '@chakra-ui/react';
import {
  useCurrentTrack,
  useIsVotingActive,
  useConnectedUsers,
  useCurrentChannelSafe,
} from '@worldeggplant/partypooper-react';
import FeedNowVotingView from './Feed/FeedNowVotingView';
import FeedNothingPlayingView from './Feed/FeedNothingPlayingView';
import Loading from '../components/Loading';
import FeedNowPlayingView from './Feed/FeedNowPlayingView';
import { getRelativeDay } from '../utilities/DateUtilities';
import { subDays } from 'date-fns';
import { Section } from '../components/SectionList';
import {
  useHeaderHeight,
  useIsMobile,
  useResponsiveTextStyle,
} from '../utilities/ResponsiveUtilities';

type RouteProps = {};
type Props = {} & RouteComponentProps<RouteProps>;

const FeedScreen: FunctionComponent<Props> = () => {
  const isVotingActive = useIsVotingActive();
  const currentTrack = useCurrentTrack();
  const currentChannel = useCurrentChannelSafe();
  const connectedUsers = useConnectedUsers();
  const [title, setTitle] = useState<string | null>(null);
  const numConnectedUsers = useMemo(() => {
    return connectedUsers !== null ? Object.keys(connectedUsers).length : 0;
  }, [connectedUsers]);
  const headerHeight = useHeaderHeight();
  const isMobile = useIsMobile();
  const headerText = useResponsiveTextStyle('Medium+', 'Large+');
  const subheaderText = useResponsiveTextStyle('Mini', 'Small');
  const labelText = useResponsiveTextStyle('Mini label', 'Label');

  const onActiveSectionChange = useCallback((section: Section<any>) => {
    const sectionDate = subDays(Date.now(), Math.abs(section.id as number));
    setTitle(getRelativeDay(sectionDate));
  }, []);

  return (
    <>
      <Flex grow={1} overflowY="auto">
        <Flex
          basis={isMobile ? '100%' : '50%'}
          grow={1}
          mt={headerHeight}
          direction="column"
          borderTop={{ base: 'none', md: '1px' }}
          borderTopColor={{ base: 'none', md: 'silver' }}
        >
          <Flex
            px={{ base: 4, md: 8 }}
            pt={3}
            grow={1}
            direction="column"
            overflow="auto"
          >
            <Box pt={{ base: 0, md: 6 }} pb={{ base: 2, md: 4 }}>
              <Text textStyle={headerText}>{currentChannel.name}</Text>

              <Text textStyle={subheaderText}>
                {numConnectedUsers} active{' '}
                {numConnectedUsers === 1 ? 'user' : 'users'}
              </Text>
            </Box>

            <Box mt="12px">
              <Divider color="silver" orientation="horizontal" />
              <ChatInput />
              <Divider color="silver" orientation="horizontal" />
            </Box>
            <Text textStyle={labelText} mt={4} py={3}>
              {title !== null ? title : <Skeleton width={24} height={4} />}
            </Text>
            <Box overflow="auto">
              <Feed onActiveSectionChange={onActiveSectionChange} />
            </Box>
          </Flex>
        </Flex>

        {!isMobile && (
          <Flex width="50%" grow={1} direction="column">
            <Suspense fallback={<Loading />}>
              {!currentTrack && <FeedNothingPlayingView />}
              {currentTrack && isVotingActive && <FeedNowVotingView />}
              {currentTrack && !isVotingActive && <FeedNowPlayingView />}
            </Suspense>
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default FeedScreen;
