import { Skeleton } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

import NowPlayingCard from './NowPlayingCard';

type Props = {
  pumps?: number;
  poops?: number;
  hideVotingData?: boolean;
  enableVoterList?: boolean;
};

const NowPlayingCardLoading: FunctionComponent<Props> = ({
  pumps,
  poops,
  hideVotingData = false,
  enableVoterList = false,
}) => {
  return (
    <NowPlayingCard
      title={
        <Skeleton width={{ base: 24, md: 32 }} height={{ base: 4, md: 6 }} />
      }
      trackName={
        <Skeleton
          width={{ base: 180, md: 240 }}
          height={{ base: 10, md: 14 }}
        />
      }
      artist={
        <Skeleton width={{ base: 200, md: 300 }} height={{ base: 8, md: 12 }} />
      }
      pumps={pumps}
      poops={poops}
      hideVotingData={hideVotingData}
      enableVoterList={enableVoterList}
    />
  );
};

export default NowPlayingCardLoading;
