export const getTrackIdFromUri = (trackUri: string) => {
  return trackUri.split(':')[2];
};

export const getArtistFromTrack = (
  track:
    | SpotifyApi.TrackObjectSimplified
    | SpotifyApi.TrackObjectFull
    | SpotifyApi.SingleTrackResponse
): string => {
  return `${track.artists.map((artist) => artist.name).join(', ')}`;
};
