export const request = async ({
  endpoint = '',
  body,
  headers = {},
  mode = 'cors',
  method = 'POST',
}: {
  endpoint: string;
  body?: RequestInit['body'];
  headers?: {};
  mode?: 'cors' | 'no-cors';
  method?: 'POST' | 'GET' | 'PUT' | 'HEAD';
}) => {
  const req = await fetch(endpoint, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...headers,
    },
    method,
    mode,
    body,
  });

  const res = await req.json();

  if (req.ok) {
    return res;
  } else {
    if (res.error) {
      throw new Error(res.error);
    }

    throw new Error(`ERROR: ${req.status}`);
  }
};
