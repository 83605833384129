import { useLogout } from '@worldeggplant/partypooper-react';
import { FunctionComponent, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

type Props = {};

const LogoutScreen: FunctionComponent<Props> = () => {
  const logout = useLogout();
  const [hasLoggedOut, setHasLoggedOut] = useState<boolean>(false);

  useEffect(() => {
    logout();
    setHasLoggedOut(true);
  }, [logout]);

  if (hasLoggedOut) {
    return <Redirect to="/" />;
  }

  return null;
};

export default LogoutScreen;
