import React, { useEffect, useState } from 'react';
import { RecoilRoot, useRecoilState } from 'recoil';
import firebase from 'firebase/app';
import { configState } from './CoreState';
import { LocalStorage, setStorage, StateDebugger } from '../utilities/state';
import { CoreConfig } from './CoreTypes';

type PartyPooperContextValue = CoreConfig | null;

const PartyPooperContext = React.createContext<PartyPooperContextValue>(null);

type PartyPooperProviderProps = {
  config: CoreConfig;
};

const PartyPooperProvider: React.FunctionComponent<PartyPooperProviderProps> = ({
  config: coreConfig,
  ...props
}) => {
  const [config, setConfig] = useRecoilState(configState);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setConfig(coreConfig);
  }, [coreConfig, setConfig]);

  useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: coreConfig.firebase.apiKey,
        authDomain: coreConfig.firebase.authDomain,
        databaseURL: coreConfig.firebase.databaseUrl,
        messagingSenderId: coreConfig.firebase.messagingSenderId,
        projectId: coreConfig.firebase.projectId,
        storageBucket: coreConfig.firebase.storageBucket,
      });
    }
  }, [coreConfig]);

  useEffect(() => {
    if (config && !isReady) {
      setIsReady(true);
    }
  }, [config, isReady]);

  if (!isReady) {
    return null;
  }

  return <PartyPooperContext.Provider value={config || null} {...props} />;
};

// ...

type PartyPooperRootProps = {
  config: CoreConfig;
  storage: LocalStorage;
};

export const PartyPooperRoot: React.FunctionComponent<PartyPooperRootProps> = ({
  config,
  storage,
  ...props
}) => {
  setStorage(storage);

  return (
    <RecoilRoot>
      <StateDebugger>
        <PartyPooperProvider config={config} {...props} />
      </StateDebugger>
    </RecoilRoot>
  );
};
