import {
  Box,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Image,
  Text,
} from '@chakra-ui/react';
import {
  getArtistFromTrack,
  useCurrentTrack,
  useCurrentTrackMeta,
  useIsVotingActive,
  usePlaybackEnabled,
  useSetPlaybackEnabled,
} from '@worldeggplant/partypooper-react';
import { FunctionComponent, memo, useCallback, useEffect, useRef } from 'react';
import NowPlayingProgressBar from './NowPlayingProgressBar';
import VoteButtons from './VoteButtons';

import { ReactComponent as NowPlayingIcon } from '../assets/icons/now-playing.svg';
import { ReactComponent as VolumeOffIcon } from '../assets/icons/volume-off.svg';
import { ReactComponent as VolumeOnIcon } from '../assets/icons/volume-on.svg';
import {
  useIsMobile,
  useResponsiveTextStyle,
} from '../utilities/ResponsiveUtilities';
import ArtistLink from './ArtistLink';

type Props = {};

const NowPlayingBar: FunctionComponent<Props> = () => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const track = useCurrentTrack();
  const trackMeta = useCurrentTrackMeta();
  const playbackEnabled = usePlaybackEnabled();
  const setPlaybackEnabled = useSetPlaybackEnabled();
  const isVotingActive = useIsVotingActive();
  const image = trackMeta?.album.images[0]?.url ?? null;
  const isMobile = useIsMobile();
  const trackText = useResponsiveTextStyle('Small', 'Regular');

  const togglePlayback = useCallback(() => {
    setPlaybackEnabled((currVal) => !currVal);
  }, [setPlaybackEnabled]);

  useEffect(() => {
    // Keep the tab active when playback is enabled
    if (playbackEnabled) {
      audioRef.current?.play();
    } else {
      audioRef.current?.pause();
    }
  }, [playbackEnabled]);

  return (
    <Box width="100%" mt="auto">
      <Collapse in={track !== null && trackMeta !== null}>
        <audio
          ref={audioRef}
          src="/assets/audio/priority.wav"
          loop
          autoPlay={false}
        />
        <Flex
          direction="column"
          boxShadow="0px -1px 0px #EBEBEB"
          grow={0}
          shrink={0}
          width="100%"
          backgroundColor="wildSand"
          zIndex="docked"
        >
          {track !== null && trackMeta !== null && (
            <>
              <NowPlayingProgressBar />
              <Flex direction="row" alignItems="stretch" grow={1}>
                <Flex
                  grow={1}
                  pt={{ base: 1.5, md: 3 }}
                  pb={{ base: 2, md: 4 }}
                  pl={{ base: 2, md: 4 }}
                  direction="row"
                  alignItems="center"
                >
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    width="48px"
                    height="48px"
                    flexShrink={0}
                  >
                    <Image
                      src={image || undefined}
                      fallback={<NowPlayingIcon />}
                    />
                  </Flex>
                  <Box ml={3}>
                    <Text textStyle={trackText} noOfLines={1} isTruncated>
                      {trackMeta.name} –{' '}
                      <ArtistLink artist={getArtistFromTrack(trackMeta)} />
                    </Text>
                  </Box>
                  <Box ml="auto" mr={{ base: 5, md: 8 }}>
                    <IconButton
                      aria-label={playbackEnabled ? 'Mute' : 'Enable sound'}
                      variant="unstyled"
                      icon={
                        playbackEnabled ? (
                          <Icon
                            as={VolumeOffIcon}
                            height={{ base: 6, md: 8 }}
                            width={{ base: 6, md: 8 }}
                          />
                        ) : (
                          <Icon
                            as={VolumeOnIcon}
                            height={{ base: 6, md: 8 }}
                            width={{ base: 6, md: 8 }}
                          />
                        )
                      }
                      colorScheme="transparent"
                      onClick={togglePlayback}
                    />
                  </Box>
                </Flex>
                {!isMobile && isVotingActive && (
                  <Box width="300px">
                    <VoteButtons />
                  </Box>
                )}
              </Flex>
            </>
          )}
        </Flex>
      </Collapse>
    </Box>
  );
};

export default memo(NowPlayingBar);
