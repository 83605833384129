import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { NavigationState, navigationState } from '../state/app/appState';

export const useSetNavigationState = (
  key: keyof NavigationState,
  value: string | null
) => {
  const setNavigationState = useSetRecoilState(navigationState);

  useEffect(() => {
    setNavigationState((navigationState) => ({
      ...navigationState,
      [key]: value,
    }));
  }, [key, setNavigationState, value]);
};
