import SpotifyWebApi from 'spotify-web-api-js';
import {
  isRefreshAccessTokenNeeded,
  refreshAccessToken,
} from '../utilities/auth';

const spotifyApi = new SpotifyWebApi();

export const spotifyApiClient = async ({
  accessToken,
  refreshToken,
  accessTokenExpiry,
  serverBaseUri,
}: {
  accessToken: string;
  refreshToken: string;
  accessTokenExpiry: number | null;
  serverBaseUri: string;
}): Promise<SpotifyWebApi.SpotifyWebApiJs> => {
  if (isRefreshAccessTokenNeeded(accessTokenExpiry)) {
    console.log('Refreshing token');
    try {
      const { accessToken: newAccessToken } = await refreshAccessToken(
        refreshToken,
        serverBaseUri
      );

      if (newAccessToken) {
        spotifyApi.setAccessToken(newAccessToken);
      }
    } catch (error) {
      console.error('Cannot refresh token', error);
    }
  } else {
    spotifyApi.setAccessToken(accessToken);
  }

  return spotifyApi;
};

export const getTrackIdFromUri = (trackUri: string) => {
  return trackUri.split(':')[2];
};

export const getArtistFromTrack = (
  track:
    | SpotifyApi.TrackObjectSimplified
    | SpotifyApi.TrackObjectFull
    | SpotifyApi.SingleTrackResponse
): string => {
  return `${track.artists.map((artist) => artist.name).join(', ')}`;
};

export const addMarket = (endpoint: string) => {
  return (
    endpoint + `${endpoint.indexOf('?') === -1 ? '?' : '&'}market=from_token`
  );
};
