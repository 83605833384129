import { useState, useEffect, useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useCurrentTrack } from '../Channel/ChannelHooks';
import {
  useCurrentDevice,
  useDevices,
  useGetSpotifyPlaybackStatus,
  useGetUserSpotifyDevices,
  usePauseSpotifyTrack,
  usePlaySpotifyTrack,
  useSetCurrentDevice,
} from '../Spotify/SpotifyHooks';
import { devicesState } from '../Spotify/SpotifyState';
import { playbackEnabledState } from './PlayerState';

export const usePlayer = () => {
  const [devices] = useDevices();
  const currentDevice = useCurrentDevice();
  const setCurrentDevice = useSetCurrentDevice();
  const playbackEnabled = usePlaybackEnabled();
  const currentTrack = useCurrentTrack();
  const playTrack = usePlaySpotifyTrack();
  const pauseTrack = usePauseSpotifyTrack();
  const getPlaybackStatus = useGetSpotifyPlaybackStatus();

  const [wasPlaying, setWasPlaying] = useState<boolean>(false);

  useEffect(() => {
    if (currentDevice === null && devices !== null && devices?.length > 0) {
      const autoSelectedDevice =
        devices?.find((device) => device.is_active) || devices[0];
      setCurrentDevice(autoSelectedDevice);
    }
  }, [currentDevice, devices, setCurrentDevice]);

  useEffect(() => {
    if (currentDevice) {
      getPlaybackStatus().then((playbackStatus) => {
        if (currentTrack && playbackEnabled) {
          const position = new Date().getTime() - currentTrack.started_at;
          const positionThresholdMs = 2000; // two second threshold
          if (
            currentTrack.uri !== playbackStatus?.item?.uri ||
            !playbackStatus.is_playing ||
            Math.abs(position - (playbackStatus?.progress_ms || 0)) >
              positionThresholdMs
          ) {
            playTrack({
              spotifyUri: currentTrack.uri,
              position,
            });
          }

          setWasPlaying(true);
        } else if (
          (wasPlaying && playbackEnabled && !currentTrack) ||
          (wasPlaying && !playbackEnabled)
        ) {
          setWasPlaying(false);
          if (playbackStatus?.is_playing) {
            pauseTrack();
          }
        }
      });
    }
  }, [
    currentDevice,
    currentTrack,
    getPlaybackStatus,
    pauseTrack,
    playTrack,
    playbackEnabled,
    wasPlaying,
  ]);
};

export const usePlaybackEnabled = () => {
  const playbackEnabled = useRecoilValue(playbackEnabledState);

  return playbackEnabled;
};

export const useSetPlaybackEnabled = () => {
  const setPlaybackEnabled = useSetRecoilState(playbackEnabledState);

  return setPlaybackEnabled;
};

export const useGetPlayerDevices = () => {
  const getUserSpotifyDevices = useGetUserSpotifyDevices();
  const setPlayerDevices = useSetRecoilState(devicesState);

  const getPlayerDevices = useCallback(async () => {
    const devices = await getUserSpotifyDevices();
    const availableDevices = devices.filter(
      (device) => device.is_active || !device.is_restricted
    );

    setPlayerDevices(availableDevices);
  }, [getUserSpotifyDevices, setPlayerDevices]);

  return getPlayerDevices;
};
