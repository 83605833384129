import { FunctionComponent } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { trackBackgroundSelector } from '../../state/app/appSelectors';
import NowPlayingCard from '../../components/NowPlayingCard';
import {
  useCurrentTrack,
  useCurrentTrackMeta,
  useVotes,
  getArtistFromTrack,
} from '@worldeggplant/partypooper-react';
import AlbumCoverView from '../../components/AlbumCoverView';
import { useHeaderHeight } from '../../utilities/ResponsiveUtilities';
import NowPlayingCardLoading from '../../components/NowPlayingCardLoading';
import ArtistLink from '../../components/ArtistLink';

type Props = {};

const HomeNowPlayingView: FunctionComponent<Props> = () => {
  const currentTrack = useCurrentTrack();
  const trackMeta = useCurrentTrackMeta();
  const { pumps, poops } = useVotes();
  const backgroundColor = useRecoilValue(
    trackBackgroundSelector(trackMeta?.album.images[0]?.url ?? null)
  );
  const headerHeight = useHeaderHeight();

  return (
    <Flex
      shrink={0}
      grow={1}
      direction={{ base: 'column-reverse', lg: 'row' }}
      width="100%"
      overflowY="auto"
    >
      <Flex
        basis="50%"
        shrink={0}
        grow={1}
        mt={{ base: 0, md: headerHeight }}
        overflowY="auto"
      >
        <Box
          width="100%"
          height="100%"
          pt={{ base: 8, md: 16 }}
          px={{ base: 6, md: 28 }}
        >
          {currentTrack && trackMeta ? (
            <NowPlayingCard
              title="Playing"
              artist={<ArtistLink artist={getArtistFromTrack(trackMeta)} />}
              trackName={trackMeta.name}
              createdBy={currentTrack.created_by}
              pumps={pumps}
              poops={poops}
              enableVoterList
            />
          ) : (
            <NowPlayingCardLoading enableVoterList />
          )}
        </Box>
      </Flex>
      <Flex
        shrink={0}
        grow={1}
        direction="column"
        justify="center"
        align="center"
        basis="50%"
      >
        <AlbumCoverView
          image={trackMeta?.album.images[0].url || undefined}
          isLoading={trackMeta === null}
          backgroundColor={backgroundColor}
        />
      </Flex>
    </Flex>
  );
};

export default HomeNowPlayingView;
