import { Flex } from '@chakra-ui/react';
import { useLikedTracks } from '@worldeggplant/partypooper-react';
import { FunctionComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import TrackList from '../../components/TrackList';
import { useSetNavigationState } from '../../hooks/AppHooks';

type RouteProps = {
  playlistId: 'liked';
};

type Props = {} & RouteComponentProps<RouteProps>;

const PlayPlaylistView: FunctionComponent<Props> = () => {
  useSetNavigationState('currentPlaylistId', 'liked');

  const [
    likedTracks,
    loadNextPage,
    nextPage,
    isLoadingNextPage,
  ] = useLikedTracks();

  return (
    <Flex grow={1} overflowY="auto" sx={{ minHeight: 'min-content' }}>
      {likedTracks?.items && (
        <TrackList
          tracks={likedTracks.items
            .filter((track) => !!track)
            .map(({ track }) => track as SpotifyApi.TrackObjectFull)}
          loadNextPage={loadNextPage}
          hasNextPage={!!nextPage}
          isNextPageLoading={isLoadingNextPage}
        />
      )}
    </Flex>
  );
};

export default PlayPlaylistView;
