import { RecoilRoot } from 'recoil';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './Theme';
import PrivateRoute from './components/PrivateRoute';
import DevDebugger from './components/DevDebug';
import ErrorBoundary from './components/ErrorBoundary';
import HomeScreen from './screens/HomeScreen';
import SplashScreen from './screens/SplashScreen';
import AuthScreen from './screens/AuthScreen';
import ChannelSelectScreen from './screens/ChannelSelectScreen';
import PlayScreen from './screens/PlayScreen';
import SearchScreen from './screens/SearchScreen';
import FeedScreen from './screens/FeedScreen';
import LogoutScreen from './screens/LogoutScreen';
import { PartyPooperRoot } from '@worldeggplant/partypooper-react';
import { getConfig } from './utilities/config';
import Fonts from './assets/style/Fonts';
import { FunctionComponent, useEffect } from 'react';
import { logEvent } from './utilities/analytics';

const config = getConfig();

const CRASH_MESSAGE =
  "Something went horribly wrong (but at least we didn't crash totally, right?)...";

const LocationAnalytics: FunctionComponent = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      console.log('logging path', location.pathname);
      logEvent('page_view', {
        page_path: location.pathname,
      });
    }
  }, [location?.pathname]);

  return null;
};

function App() {
  return (
    <>
      <Fonts />
      <Router>
        <LocationAnalytics />
        <Switch>
          <Route path="/" exact component={SplashScreen} />
          <Route path="/auth" component={AuthScreen} />
          <PrivateRoute path="/home" exact component={HomeScreen} />
          <PrivateRoute path="/play/:playlistId?" component={PlayScreen} />
          <PrivateRoute
            path="/channels"
            exact
            component={ChannelSelectScreen}
            hideScreen
          />
          <PrivateRoute path="/feed" exact component={FeedScreen} />

          <PrivateRoute path="/search/:searchTerm?" component={SearchScreen} />
          <Route path="/logout" component={LogoutScreen} />
          <Redirect to="/" />
        </Switch>
      </Router>
    </>
  );
}

function AppWrapper() {
  return (
    <ErrorBoundary fallback={<h2>{CRASH_MESSAGE}</h2>}>
      <ChakraProvider theme={theme}>
        <RecoilRoot>
          <DevDebugger>
            <PartyPooperRoot storage={localStorage} config={config}>
              <App />
            </PartyPooperRoot>
          </DevDebugger>
        </RecoilRoot>
      </ChakraProvider>
    </ErrorBoundary>
  );
}

export default AppWrapper;
