import React, { Fragment, useCallback, useEffect } from 'react';
import { AtomEffect, DefaultValue, useRecoilSnapshot } from 'recoil';
import { AppEventKeys, AppEventMap, getEventEmitter } from './events';

export let PARTYPOOPER_STORAGE: LocalStorage;

export const getStorage = (): LocalStorage => {
  return PARTYPOOPER_STORAGE;
};
export const setStorage = (storage: LocalStorage): void => {
  PARTYPOOPER_STORAGE = storage;
};

export interface LocalStorage {
  getItem: (key: string) => any;
  setItem: (key: string, value: any) => void;
  removeItem: (key: string) => void;
}

export const localStorageEffect = <T extends unknown>(
  key: string
): AtomEffect<T> => ({ setSelf, onSet }) => {
  const storage = getStorage();
  const value = storage.getItem(key);
  if (typeof value?.then !== 'undefined') {
    setSelf(
      value.then((savedValue: any) =>
        savedValue != null ? JSON.parse(savedValue) : new DefaultValue()
      )
    );
  } else {
    setSelf(JSON.parse(value));
  }

  onSet((newValue) => {
    if (newValue instanceof DefaultValue) {
      storage.removeItem(key);
    } else {
      storage.setItem(key, JSON.stringify(newValue));
    }
  });
};

export const broadcastEffect = <T extends unknown>(
  key: AppEventKeys
): AtomEffect<T> => ({ onSet }) => {
  const eventEmitter = getEventEmitter();
  onSet((newValue) => {
    if (newValue instanceof DefaultValue || newValue === null) {
      eventEmitter.emit(key, null);
    } else {
      eventEmitter.emit(key, newValue as AppEventMap[typeof key]);
    }
  });
};

export const StateDebugger: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const snapshot = useRecoilSnapshot();

  const onRecoilStateChange = useCallback((stateSnapshot) => {
    if (process.env.NODE_ENV === 'development') {
      const nodes = stateSnapshot.getNodes_UNSTABLE({ isModified: true });
      for (const node of nodes) {
        console.log(
          'STATE CHANGE',
          node.key,
          stateSnapshot.getLoadable(node).contents
        );
      }
    }
  }, []);

  useEffect(() => {
    onRecoilStateChange(snapshot);
  }, [onRecoilStateChange, snapshot]);

  return <Fragment>{children}</Fragment>;
};
