import { CheckIcon, InfoOutlineIcon, LockIcon } from '@chakra-ui/icons';
import {
  Text,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  List,
  ListItem,
  Divider,
  ListIcon,
  Box,
  Stack,
  Flex,
} from '@chakra-ui/react';
import {
  useDevices,
  useLogout,
  useCurrentDevice,
  useSetCurrentDevice,
  usePlaybackEnabled,
  useSetPlaybackEnabled,
  useSignOutOfChannel,
  useCurrentChannel,
} from '@worldeggplant/partypooper-react';
import { FunctionComponent, useCallback } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  appHasSeenWelcomeState,
  appMobileMenuOpenState,
  navigationState,
} from '../state/app/appState';
import Debug from '../utilities/debug';
import FeedUnreadCount from './FeedUnreadCount';
import NavigationLink from './NavigationLink';

const debugV = Debug.verbose('MobileMenu');

type Props = {};

const MobileMenu: FunctionComponent<Props> = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useRecoilState(
    appMobileMenuOpenState
  );
  const logout = useLogout();
  const [devices, isLoadingDevices, reloadDevices] = useDevices();
  const channel = useCurrentChannel();
  const currentDevice = useCurrentDevice();
  const setCurrentDevice = useSetCurrentDevice();
  const playbackEnabled = usePlaybackEnabled();
  const setPlaybackEnabled = useSetPlaybackEnabled();
  const signOutOfCurrentChannel = useSignOutOfChannel();
  const setHasSeenWelcome = useSetRecoilState(appHasSeenWelcomeState);
  const { currentSearchTerm } = useRecoilValue(navigationState);

  const onClickChangeChannel = useCallback(() => {
    signOutOfCurrentChannel();
  }, [signOutOfCurrentChannel]);

  const onClickViewWelcomeMessage = useCallback(() => {
    setHasSeenWelcome(null);
  }, [setHasSeenWelcome]);

  const onSelectDevice = useCallback(
    (name: string | string[]) => {
      let deviceName: string | string[] = name;
      debugV('onSelectDevice', deviceName);

      if (typeof deviceName !== 'string') {
        deviceName = deviceName.shift() as string;
      }

      const device = devices?.find((d) => d.name === name);

      if (device) {
        setCurrentDevice(device);
      }
    },
    [devices, setCurrentDevice]
  );

  const onTogglePlaybackState = useCallback(() => {
    setPlaybackEnabled((currentPlaybackEnabled) => !currentPlaybackEnabled);
  }, [setPlaybackEnabled]);

  const onCloseMobileDrawer = useCallback(() => {
    setMobileMenuOpen(false);
  }, [setMobileMenuOpen]);

  return (
    <>
      <Drawer
        isOpen={mobileMenuOpen}
        placement="bottom"
        onClose={onCloseMobileDrawer}
        size="xl"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          {/* <DrawerHeader /> */}
          <DrawerBody mt={6} mb={10} px={3}>
            <List>
              <ListItem>
                <NavigationLink onClick={onCloseMobileDrawer} to="/home">
                  Home
                </NavigationLink>
              </ListItem>
              <ListItem>
                <NavigationLink onClick={onCloseMobileDrawer} to="/play">
                  Play
                </NavigationLink>
              </ListItem>
              <ListItem>
                <NavigationLink onClick={onCloseMobileDrawer} to="/feed">
                  Feed
                  <FeedUnreadCount />
                </NavigationLink>
              </ListItem>
              <ListItem>
                <NavigationLink
                  onClick={onCloseMobileDrawer}
                  to={`/search/${currentSearchTerm || ''}`}
                >
                  Search
                </NavigationLink>
              </ListItem>
            </List>
          </DrawerBody>

          <DrawerFooter px={4}>
            <List spacing={2} w="100%">
              <ListItem
                onClick={() => {
                  onCloseMobileDrawer();
                  onClickChangeChannel();
                }}
              >
                <Flex
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Text
                      as="span"
                      textAlign="center"
                      display="inline-block"
                      color="black"
                      opacity={0.5}
                      w={4}
                      h={4}
                      mr={2}
                    >
                      #
                    </Text>{' '}
                    {channel?.name}
                  </Box>
                  <Text opacity={0.6}>Change channel</Text>
                </Flex>
              </ListItem>

              <ListItem>
                <Divider color="silver" my={4} />
              </ListItem>

              <ListItem mb={2}>
                <Text fontSize="sm" fontWeight={600}>
                  Playback
                </Text>
              </ListItem>
              <ListItem onClick={onTogglePlaybackState}>
                <Stack direction="row" spacing={0}>
                  <ListIcon
                    mt={1}
                    as={CheckIcon}
                    opacity={playbackEnabled ? 0.5 : 0}
                  />
                  <Text>Enable sound</Text>
                </Stack>
              </ListItem>

              <ListItem>
                <Divider color="silver" my={4} />
              </ListItem>

              <ListItem mb={2} onClick={reloadDevices}>
                <Flex
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text fontSize="sm" fontWeight={600}>
                    Devices
                  </Text>
                  <Text opacity={0.6}>
                    {isLoadingDevices ? 'Loading...' : 'Reload list'}
                  </Text>
                </Flex>
              </ListItem>

              {devices !== null &&
                devices?.map((device) => (
                  <ListItem
                    key={device.id}
                    onClick={() => onSelectDevice(device.name)}
                  >
                    <Stack direction="row" spacing={0}>
                      <ListIcon
                        mt={1}
                        as={CheckIcon}
                        opacity={device.name === currentDevice?.name ? 0.5 : 0}
                      />
                      <Text>
                        {device.name} ({device.type})
                      </Text>
                    </Stack>
                  </ListItem>
                ))}

              <ListItem>
                <Divider color="silver" my={4} />
              </ListItem>

              <ListItem
                onClick={() => {
                  onCloseMobileDrawer();
                  onClickViewWelcomeMessage();
                }}
              >
                <Text>
                  <ListIcon as={InfoOutlineIcon} opacity={0.5} mr={2} />
                  Display welcome message
                </Text>
              </ListItem>
              <ListItem
                onClick={() => {
                  onCloseMobileDrawer();
                  logout();
                }}
              >
                <Text fontWeight={400}>
                  <LockIcon opacity={0.5} mr={2} mt="-2px" />
                  Log out
                </Text>
              </ListItem>
            </List>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MobileMenu;
