import { atom, selector } from 'recoil';
import { CoreConfig } from './CoreTypes';

export const StatePrefix = 'pp_';

export const configState = atom<CoreConfig | null>({
  key: `${StatePrefix}configState`,
  default: null,
});

export const configSafeState = selector<CoreConfig>({
  key: `${StatePrefix}configSafeState`,
  get: ({ get }) => {
    const config = get(configState);

    if (config === null) {
      throw new Error('Config used before being initialized');
    }

    return config;
  },
});
