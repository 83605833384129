import { Box, Flex, SkeletonCircle, SkeletonText } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

type Props = {};

const FeedItemLoading: FunctionComponent<Props> = (props) => {
  return (
    <Flex grow={1} py={3} pr={3} direction="column">
      <Flex grow={1}>
        <Box mr={3}>
          <SkeletonCircle size="48px" />
        </Box>

        <Flex direction="column" grow={1}>
          <Box>
            <SkeletonText height="16px" width="8em" noOfLines={1} />
          </Box>
          <Box>
            <SkeletonText height="16px" width="7em" noOfLines={1} />
          </Box>
        </Flex>
        <Flex shrink={0} ml="auto">
          <SkeletonText height="16px" width="2em" noOfLines={1} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FeedItemLoading;
