export enum FeedMessageType {
  message,
  queuedTrack,
  currentTrack,
}

export type FeedMessageItem = {
  type: FeedMessageType.message;
  created_at: number;
  created_by: string;
  body: string;
  id: string;
};

export type FeedQueuedTrackItem = {
  type: FeedMessageType.queuedTrack;
  created_at: number;
  created_by: string;
  track_uri: string;
  currentPlayingTrack: boolean;
  id: string;
  pumped?: boolean;
  votes: { pumps: number; poops: number };
};

export type FeedCurrentTrackItem = {
  type: FeedMessageType.currentTrack;
  created_at: number;
  track_uri: string;
  currentPlayingTrack: boolean;
  id: string;
};

export type FeedItem =
  | FeedMessageItem
  | FeedQueuedTrackItem
  | FeedCurrentTrackItem;
