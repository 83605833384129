import { Box, Flex, Image } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

type Props = {
  backgroundColor: string;
  image?: string;
  isLoading?: boolean;
};

const AlbumCoverView: FunctionComponent<Props> = ({
  backgroundColor,
  image,
  isLoading = false,
}) => {
  return (
    <Flex
      grow={1}
      shrink={0}
      direction="column"
      justify="center"
      width="100%"
      align="center"
      backgroundColor={backgroundColor}
      overflowY="hidden"
    >
      <Box>
        {isLoading ? (
          <Box
            bg="silver"
            width={{ base: 200, md: 320, lg: 640 }}
            height={{ base: 200, md: 320, lg: 640 }}
          />
        ) : (
          <Image
            src={image}
            width={{ base: 200, md: 320, lg: 640 }}
            fallback={
              <Box
                borderRadius="full"
                bg="silver"
                width={{ base: 200, md: 320, lg: 640 }}
                height={{ base: 200, md: 320, lg: 640 }}
              />
            }
          />
        )}
      </Box>
    </Flex>
  );
};

export default AlbumCoverView;
