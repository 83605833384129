import { atom, selector, selectorFamily } from 'recoil';
import { userState } from '../Auth/AuthState';
import { StatePrefix } from '../Core/CoreState';
import { trackMetaState } from '../Spotify/SpotifyState';
import { ConnectedUsers } from '../utilities/firebase';
import { localStorageEffect } from '../utilities/state';
import {
  ChannelsState,
  HasSignedIntoChannelState,
  CurrentChannelIdState,
  CurrentChannelState,
  CurrentTrackState,
  CurrentTrackMetaState,
  VotingActiveState,
  VoterState,
  PlayedTracksState,
  PlayedTracksSelectorResults,
  PlayedTracksSelectorParams,
  CanVoteSelectorState,
} from './ChannelTypes';

export const channelsState = atom<ChannelsState>({
  key: `${StatePrefix}channelsState`,
  default: null,
});

export const hasSignedIntoChannelState = atom<HasSignedIntoChannelState>({
  key: `${StatePrefix}hasSignedIntoChannelState`,
  default: false,
});

export const currentChannelIdState = selector<CurrentChannelIdState | null>({
  key: `${StatePrefix}currentChannelIdState`,
  get: ({ get }) => {
    const currentChannel = get(currentChannelState);

    return currentChannel?.id || null;
  },
});

export const currentChannelState = atom<CurrentChannelState | null>({
  key: `${StatePrefix}currentChannelState`,
  default: null,
  effects_UNSTABLE: [
    localStorageEffect<CurrentChannelState | null>(
      `${StatePrefix}currentChannelState`
    ),
  ],
});

export const currentTrackState = atom<CurrentTrackState | null>({
  key: `${StatePrefix}currentTrackState`,
  default: null,
});

// ...

export const currentTrackMetaState = selector<CurrentTrackMetaState | null>({
  key: `${StatePrefix}currentTrackMetaState`,
  get: ({ get }) => {
    const currentChannel = get(currentChannelIdState);
    const currentTrack = get(currentTrackState);

    if (currentChannel !== null && currentTrack !== null) {
      return get(trackMetaState({ trackUri: currentTrack.uri }));
    }

    return null;
  },
});

// ...

export const votingActiveState = atom<VotingActiveState>({
  key: `${StatePrefix}votingActiveState`,
  default: false,
});

export const voterState = atom<VoterState>({
  key: `${StatePrefix}voterState`,
  default: [],
});

export const playedTracksState = atom<PlayedTracksState | null>({
  key: `${StatePrefix}playedTracksState`,
  default: null,
});

export const playedTracksSelector = selectorFamily<
  PlayedTracksSelectorResults,
  PlayedTracksSelectorParams
>({
  key: `${StatePrefix}playedTracksSelector`,
  get: ({ channelId }) => ({ get }) => {
    const playedTracks = get(playedTracksState);

    if (playedTracks && playedTracks[channelId]) {
      return playedTracks[channelId];
    }

    return null;
  },
});

// ...
export const canVoteSelector = selector<CanVoteSelectorState>({
  key: 'canVoteSelector',
  get: ({ get }) => {
    const isVotingActive = get(votingActiveState);
    const user = get(userState);
    const voters = get(voterState);

    if (
      isVotingActive &&
      user?.uid &&
      !voters.find((vote) => vote.uid === user.uid)
    ) {
      return true;
    }

    return false;
  },
});

// ...

export const connectedUsersState = atom<ConnectedUsers | null>({
  key: `${StatePrefix}connectedUsersState`,
  default: null,
});
