import { FunctionComponent } from 'react';
import {
  Badge,
  Box,
  Container,
  Divider,
  Flex,
  Link,
  Text,
} from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { useIsAuthenticated } from '@worldeggplant/partypooper-react';
import { ReactComponent as PPLogo } from '../../assets/icons/pp-logo.svg';
import Icon from '@chakra-ui/icon';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import Changelog from '../../components/Changelog';
import { useResponsiveTextStyle } from '../../utilities/ResponsiveUtilities';

// Increment this when changing to ensure the message is received.
export const WELCOME_VERSION = 6;

type Props = {
  setHasSeenWelcome: (version: number) => void;
};

const WelcomeView: FunctionComponent<Props> = ({ setHasSeenWelcome }) => {
  const isAuthenticated = useIsAuthenticated();
  const headerText = useResponsiveTextStyle('Large+', 'Title+');
  const subheaderText = useResponsiveTextStyle('Regular+', 'Regular+');
  const bodyText = useResponsiveTextStyle('Small', 'Small');

  return (
    <Flex
      direction="column"
      height="100%"
      width="100%"
      justify="center"
      align="center"
      m={0}
      py={3}
      px={4}
      bgGradient="linear(to-r, #5B489730, #F7A5FF30)"
      overflow="auto"
    >
      <Container
        bg="offWhite"
        shadow="2xl"
        borderRadius="xl"
        w="xl"
        maxW="100%"
        p={4}
        overflowY="auto"
      >
        <Flex direction="column" align="center">
          <Icon as={PPLogo} w={{ base: 16, md: 32 }} h={{ base: 12, md: 20 }} />

          <Text textStyle={headerText} mt={2} position="relative">
            Party Pooper
            <Text as="span" position="absolute">
              ™
            </Text>
          </Text>

          <Divider color="silver" my={8} />

          <Box width="100%">
            <Text textStyle={subheaderText}>
              Introducing: Party Pooper™ 3 <Badge>BETA</Badge>
            </Text>
            <Text textStyle={bodyText} lineHeight="tall" mt={3}>
              Listen to what's playing directly in your browser*, use the feed
              to chat &amp; see track history, queue new music with ease – and
              most important of all:{' '}
              <Text as="span" color="fuscia">
                pump
              </Text>{' '}
              and{' '}
              <Text as="span" color="brown">
                poop
              </Text>{' '}
              tracks.
            </Text>
            <Text textStyle={bodyText} lineHeight="tall" mt={3}>
              This, and more, in an all-new design created by Harold Henckell.
            </Text>
            <Text fontSize="xs" opacity={0.8} my={3}>
              * Playback requires a Spotify Premium account
            </Text>

            <Divider color="silver" orientation="horizontal" mt={6} mb={4} />

            <Box>
              <Changelog
                title="Changelog &amp; credits"
                items={[
                  {
                    date: new Date('2021-08-16'),
                    text:
                      'Bug fixes: tab priority is maintained so playback queue is followed even if tab is inactive. The progress bar during voting now updates correctly.',
                  },
                  {
                    date: new Date('2021-06-11'),
                    text: 'Party Pooper 3.0 beta launch',
                  },
                  {
                    date: new Date('2021-01-15'),
                    text: (
                      <>
                        <Link href="https://partypooper.am">
                          partypooper.am
                        </Link>{' '}
                        registered
                      </>
                    ),
                  },
                  {
                    date: new Date('2020-12-20'),
                    text: 'PP3 TypeScript rewrite started by Jonas Skovmand',
                  },
                  {
                    date: new Date('2020-12-10'),
                    text:
                      'PP3 alpha launch after development efforts by Malin Henningsson',
                  },
                  {
                    date: new Date('2020-03-17'),
                    text: 'PP3 development started by Henrik Persson',
                  },
                  {
                    date: new Date('2020-01-01'),
                    text: 'Party Pooper 3.0 design started',
                  },
                  { text: '' },
                  {
                    text:
                      'Harold Henckell (design), Jonas Skovmand (development), Henrik Persson (development), Malin Henningsson (development), et al.',
                  },
                  {
                    text: `{welcome message v${WELCOME_VERSION}}`,
                  },
                ]}
              />
            </Box>
          </Box>

          <Button
            variant="outline"
            onClick={() => setHasSeenWelcome(WELCOME_VERSION)}
            rightIcon={<ArrowForwardIcon />}
            spacing={2}
            mt={4}
          >
            {isAuthenticated ? 'Continue' : 'Log in with Spotify'}
          </Button>
        </Flex>
      </Container>
    </Flex>
  );
};

export default WelcomeView;
