import {
  AvatarGroup,
  Box,
  Circle,
  Flex,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useVotes } from '@worldeggplant/partypooper-react';
import { FunctionComponent, useMemo } from 'react';
import {
  useElementDimensions,
  useResponsiveTextStyle,
} from '../utilities/ResponsiveUtilities';
import UserAvatar from './UserAvatar';

type Props = {};

type Votes = ReturnType<typeof useVotes>['votes'];

type StandingRowProps = {
  voteCount: number;
  totalVoteCount: number;
  votes: Votes;
  label: string;
  loaderColor: string;
  backgroundColor: string;
};

const StandingRow: FunctionComponent<StandingRowProps> = ({
  voteCount,
  votes,
  totalVoteCount,
  loaderColor,
  backgroundColor,
  label,
}) => {
  const text = useResponsiveTextStyle('Small+', 'Medium+');
  const labelText = useResponsiveTextStyle('Mini label', 'Label');
  const maxValue = useBreakpointValue({ base: 2, md: 3 });
  const [rowDimensions, rowRef] = useElementDimensions();
  const percent = useMemo(() => {
    if (totalVoteCount === 0) {
      return 0;
    }

    return Math.floor((voteCount / totalVoteCount) * 100);
  }, [totalVoteCount, voteCount]);
  const voteBadges = votes;

  return (
    <Flex
      direction="row"
      width="100%"
      height="50%"
      shrink={0}
      grow={1}
      alignItems="stretch"
      backgroundColor={backgroundColor}
    >
      <Flex
        grow={1}
        shrink={1}
        height="100%"
        width="100%"
        pb={3}
        alignItems="center"
        direction="row"
        position="relative"
      >
        <Flex alignItems="center" position="absolute">
          <Box transform={`rotate(-90deg)`} zIndex="docked">
            <Text textStyle={labelText} color="body">
              {label}
            </Text>
          </Box>
        </Flex>
        <Flex
          direction="row"
          backgroundColor={loaderColor}
          height="100%"
          width={`calc(${percent}% - ${
            rowDimensions?.height ? rowDimensions.height / 2 : 0
          }px)`}
          sx={{
            transition: 'width 1s ease',
          }}
          justifyContent="flex-end"
          ref={rowRef}
          position="relative"
        >
          {rowDimensions?.height ? (
            <Circle
              position="absolute"
              transform="translate3d(50%, 0, 0)"
              size={`${rowDimensions.height}px`}
              backgroundColor={loaderColor}
            />
          ) : null}
        </Flex>
      </Flex>
      <Flex
        direction="column"
        py={3}
        ml="auto"
        basis="30%"
        justifyContent="space-between"
        grow={1}
        shrink={0}
      >
        <Box height="40px" />
        <Text
          my={4}
          px={4}
          textStyle={text}
          textAlign={{ base: 'right', md: 'left' }}
        >
          {voteCount} {voteCount === 1 ? 'vote' : 'votes'}
        </Text>
        <Box height="40px" align="left" width="100%">
          <AvatarGroup
            size="md"
            spacing={-5}
            max={maxValue}
            transform="scale(0.83333)"
            colorScheme={backgroundColor}
          >
            {voteBadges.map((vote, i) => (
              <UserAvatar
                borderColor={backgroundColor}
                borderWidth={2}
                key={vote.uid}
                uid={vote.uid}
                zIndex={5 - i}
              />
            ))}
          </AvatarGroup>
        </Box>
      </Flex>
    </Flex>
  );
};

const VoteStandings: FunctionComponent<Props> = () => {
  const { votes, pumps, poops } = useVotes();
  const totalVoteCount = useMemo(() => {
    return Object.keys(votes).length;
  }, [votes]);

  return (
    <Flex
      grow={1}
      shrink={1}
      width="100%"
      direction="column"
      pt={3}
      bg="wildSand"
    >
      <StandingRow
        label="Pumps"
        votes={votes.filter((v) => v.pump)}
        voteCount={pumps}
        totalVoteCount={totalVoteCount}
        loaderColor="mauvePink"
        backgroundColor="wildSand"
      />
      <StandingRow
        label="Poops"
        votes={votes.filter((v) => !v.pump)}
        voteCount={poops}
        totalVoteCount={totalVoteCount}
        loaderColor="brightGreen"
        backgroundColor="wildSand"
      />
    </Flex>
  );
};

export default VoteStandings;
