import { useRecoilValue } from 'recoil';
import { nullValueGuard } from '../utilities/hook';
import { configState } from './CoreState';

export function useConfig() {
  const config = useRecoilValue(configState);

  return config;
}

export const useConfigSafe = nullValueGuard(useConfig);
