import { FunctionComponent, useEffect, useState } from 'react';
import { Redirect, RedirectProps, RouteComponentProps } from 'react-router-dom';
import Loading from '../components/Loading';
import { useAuth } from '@worldeggplant/partypooper-react';
import { useRecoilState } from 'recoil';
import { appHasSeenWelcomeState } from '../state/app/appState';
import WelcomeView, { WELCOME_VERSION } from './Splash/WelcomeView';

type RouteProps = {};
type Props = {} & RouteComponentProps<RouteProps>;

const SplashScreen: FunctionComponent<Props> = () => {
  const [redirect, setRedirect] = useState<RedirectProps['to']>();
  const {
    initiateAuth,
    isAuthenticated,
    isAuthenticationInProgress,
  } = useAuth();
  const [showWelcomeMessage, setShowWelcomeMessage] = useState<boolean>(false);
  const [hasSeenWelcome, setHasSeenWelcome] = useRecoilState(
    appHasSeenWelcomeState
  );

  useEffect(() => {
    if (hasSeenWelcome === null || hasSeenWelcome < WELCOME_VERSION) {
      setShowWelcomeMessage(true);
    } else if (!isAuthenticationInProgress && !isAuthenticated) {
      initiateAuth((redirectUri) => {
        window.location.href = redirectUri;
      });
    } else if (isAuthenticated) {
      setRedirect('/home');
    }
  }, [
    hasSeenWelcome,
    initiateAuth,
    isAuthenticated,
    isAuthenticationInProgress,
  ]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (showWelcomeMessage) {
    return <WelcomeView setHasSeenWelcome={setHasSeenWelcome} />;
  }

  return <Loading />;
};

export default SplashScreen;
