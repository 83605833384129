import {
  FeedMessageType,
  useCurrentChannelIdSafe,
  useFeed,
} from '@worldeggplant/partypooper-react';
import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  feedLastSeenItemIdState,
  feedUnreadItemCountState,
} from '../state/app/appState';
import { useDebouncedEffect } from '../utilities/useDebouncedEffect';

export function useMessages() {
  const feed = useFeed();
  const messages = useMemo(() => {
    return feed?.filter((item) => item.type === FeedMessageType.message);
  }, [feed]);

  return messages;
}

export function useMarkAsRead() {
  const messages = useMessages();
  const currentChannelId = useCurrentChannelIdSafe();
  const setFeedLastSeenItemId = useSetRecoilState(
    feedLastSeenItemIdState({ channelId: currentChannelId })
  );

  const markAsRead = useCallback(() => {
    if (messages?.length) {
      setFeedLastSeenItemId(messages[0].id);
    }
  }, [messages, setFeedLastSeenItemId]);

  return markAsRead;
}

export function useUnreadMessagesCount() {
  const currentChannelId = useCurrentChannelIdSafe();
  const feedLastSeenItemId = useRecoilValue(
    feedLastSeenItemIdState({ channelId: currentChannelId })
  );
  const [feedUnreadItemCount, setFeedUnreadItemCount] = useRecoilState(
    feedUnreadItemCountState
  );
  const messages = useMessages();

  useDebouncedEffect(
    () => {
      if (messages && feedLastSeenItemId) {
        const index = messages.findIndex(
          (item) => item.id === feedLastSeenItemId
        );

        if (index !== -1) {
          setFeedUnreadItemCount(index);
        } else {
          setFeedUnreadItemCount(0);
        }
      } else {
        setFeedUnreadItemCount(0);
      }
    },
    100,
    [feedLastSeenItemId, messages, setFeedUnreadItemCount]
  );

  return feedUnreadItemCount;
}
