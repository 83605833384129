import { Tooltip } from '@chakra-ui/tooltip';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  artist: string;
};

const ArtistLink: FunctionComponent<Props> = ({ artist }) => {
  return (
    <Tooltip label={`Discover more tracks from ${artist}`}>
      <Link to={`/search/artist:${encodeURI(artist)}`}>{artist}</Link>
    </Tooltip>
  );
};

export default ArtistLink;
