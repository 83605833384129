import { v4 as uuid } from 'uuid';
import firebase from 'firebase/app';
import 'firebase/auth';
import queryString from 'query-string';
import { request } from './api';
import { AppEventKeys, getEventEmitter } from './events';

// ...

const REFRESH_TOKEN_THRESHOLD_SECONDS = 30;
let IS_REFRESHING_PROMISE: Promise<RefreshAccessTokenResponse> | null = null;

// ...

export const generateStateToken = (): string => uuid();

export const getRedirectUrl = ({
  spotifyAccountsBaseUri,
  spotifyClientID,
  redirectURI,
  scopes,
  state,
}: {
  spotifyAccountsBaseUri: string;
  spotifyClientID: string;
  redirectURI: string;
  scopes: string;
  state: string;
}): string => {
  const query = queryString.stringify({
    client_id: spotifyClientID,
    redirect_uri: redirectURI,
    response_type: 'code',
    scope: scopes,
    state,
  });

  return `${spotifyAccountsBaseUri}/authorize?${query}`;
};

// ...

type GetAccessTokenRequest = {
  code: string;
  serverBaseUri: string;
  redirectUri: string;
};

type GetAccessTokenResponse = {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: 'Bearer';
};

export async function getAccessToken({
  code,
  serverBaseUri,
  redirectUri,
}: GetAccessTokenRequest): Promise<GetAccessTokenResponse> {
  const query = queryString.stringify({
    code,
    redirect_uri: redirectUri,
  });

  const result = await request({
    endpoint: `${serverBaseUri}/swap`,
    body: query,
  });

  return result;
}

// ...

type GetRefreshedAccessTokenRequest = {
  serverBaseUri: string;
  refreshToken: string;
};

type GetRefreshedAccessTokenResponse = {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: 'Bearer';
};

export const getRefreshedAccessToken = async ({
  serverBaseUri,
  refreshToken,
}: GetRefreshedAccessTokenRequest): Promise<GetRefreshedAccessTokenResponse> => {
  const body = queryString.stringify({
    refresh_token: refreshToken,
  });

  return request({
    endpoint: `${serverBaseUri}/refresh`,
    body,
    method: 'POST',
  });
};

// ...

type GetFirebaseTokenRequest = {
  token: string;
  serverBaseUri: string;
};

type GetFirebaseTokenResponse = {
  token: string;
};

export async function getFirebaseToken({
  token,
  serverBaseUri,
}: GetFirebaseTokenRequest): Promise<GetFirebaseTokenResponse> {
  const body = queryString.stringify({
    access_token: token,
  });

  const result = await request({
    endpoint: `${serverBaseUri}/firebase`,
    body,
  });

  return result;
}

// ...

export const isRefreshAccessTokenNeeded = (
  accessTokenExpiry: number | null
) => {
  if (!accessTokenExpiry) {
    return true;
  }

  const now = Math.ceil(new Date().getTime() / 1000);
  const timeLeft = accessTokenExpiry - now;

  if (timeLeft <= REFRESH_TOKEN_THRESHOLD_SECONDS) {
    return true;
  }

  return false;
};

// ...

type RefreshAccessTokenResponse = {
  accessToken: string;
  expiresIn: number;
};

export const refreshAccessToken = async (
  refreshToken: string,
  serverBaseUri: string
): Promise<RefreshAccessTokenResponse> => {
  if (!refreshToken) {
    throw new Error('refreshAccessToken: no refresh token');
  }

  if (IS_REFRESHING_PROMISE === null) {
    IS_REFRESHING_PROMISE = new Promise(async (resolve) => {
      const tokens = await getRefreshedAccessToken({
        refreshToken,
        serverBaseUri: serverBaseUri,
      });

      const firebaseTokenResponse = await getFirebaseToken({
        token: tokens.access_token,
        serverBaseUri: serverBaseUri,
      });

      const { token: firebaseToken } = firebaseTokenResponse;
      await firebase.auth().signInWithCustomToken(firebaseToken);

      getEventEmitter().emit(AppEventKeys.AuthStateUpdate, {
        accessToken: tokens.access_token,
        accessTokenExpiry: tokens.expires_in,
        refreshToken: tokens.refresh_token,
      });

      resolve({
        accessToken: tokens.access_token,
        expiresIn: tokens.expires_in,
      });
      IS_REFRESHING_PROMISE = null;
    });
  }

  return IS_REFRESHING_PROMISE;
};

export const getAccessTokenExpiryTime = (accessTokenExpiry: number) => {
  return Math.ceil(new Date().getTime() / 1000) + accessTokenExpiry;
};
