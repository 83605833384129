import { useCurrentTrackMeta } from '@worldeggplant/partypooper-react';
import { FunctionComponent } from 'react';
import { useRecoilValue } from 'recoil';
import UpNext from '../../components/UpNext';
import { trackBackgroundSelector } from '../../state/app/appSelectors';

type Props = {};

const FeedNowVotingView: FunctionComponent<Props> = () => {
  const trackMeta = useCurrentTrackMeta();
  const backgroundColor = useRecoilValue(
    trackBackgroundSelector(trackMeta?.album.images[0]?.url ?? null)
  );

  return (
    <UpNext
      backgroundColor={backgroundColor}
      image={trackMeta?.album.images[0].url}
    />
  );
};

export default FeedNowVotingView;
