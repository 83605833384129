import { atomFamily } from 'recoil';

type SearchActiveAlbumImageParams = { searchTerm: string | null };
type SearchActiveAlbumImageResult = string | null;

export const searchActiveAlbumImageState = atomFamily<
  SearchActiveAlbumImageResult,
  SearchActiveAlbumImageParams
>({
  key: 'searchActiveAlbumImageState',
  default: null,
});
