import { Box, Divider, Text } from '@chakra-ui/react';
import {
  FeedItem,
  FeedMessageType,
  useCurrentTrack,
  useFeed,
  useIsVotingActive,
} from '@worldeggplant/partypooper-react';
import { FunctionComponent, Suspense, useEffect, useMemo } from 'react';
import FeedMessage from './FeedMessage';
import FeedCurrentTrack from './FeedCurrentTrack';
import FeedTrack from './FeedTrack';
import FeedItemLoading from './FeedItemLoading';
import { useMarkAsRead } from '../hooks/FeedHooks';
import { differenceInCalendarDays, fromUnixTime, subDays } from 'date-fns';
import { getRelativeDay } from '../utilities/DateUtilities';
import SectionList, { Section } from './SectionList';
import { groupBy } from '../utilities/groupBy';
import { useResponsiveTextStyle } from '../utilities/ResponsiveUtilities';

type RenderItemProps = {
  item: FeedItem;
  isVoting: boolean;
};

const RenderItem: FunctionComponent<RenderItemProps> = ({ item, isVoting }) => {
  switch (item.type) {
    case FeedMessageType.message:
      return (
        <>
          <Suspense fallback={<FeedItemLoading />}>
            <FeedMessage
              uid={item.created_by}
              title={item.created_by}
              message={item.body}
              timestamp={item.created_at}
            />
          </Suspense>
          <Divider orientation="horizontal" color="silver" />
        </>
      );

    case FeedMessageType.queuedTrack:
      return (
        <>
          <Suspense fallback={<FeedItemLoading />}>
            {item.pumped ? (
              <FeedMessage
                uid={item.created_by}
                title={item.created_by}
                message="Played a tune"
                timestamp={item.created_at}
              />
            ) : (
              <FeedCurrentTrack
                title="Song got pooped!"
                trackUri={item.track_uri}
                timestamp={item.created_at}
                votes={item.votes}
              />
            )}
          </Suspense>
          {item.pumped && (
            <Suspense fallback={<FeedItemLoading />}>
              <Box ml={12} pl={3}>
                <FeedTrack
                  trackUri={item.track_uri}
                  votes={item.votes}
                  hideVotes={item.currentPlayingTrack && isVoting}
                />
              </Box>
            </Suspense>
          )}
          <Divider mt={3} orientation="horizontal" color="silver" />
        </>
      );

    case FeedMessageType.currentTrack:
      return (
        <>
          <Suspense fallback={<FeedItemLoading />}>
            <FeedCurrentTrack
              title="New song queued"
              trackUri={item.track_uri}
              timestamp={item.created_at}
              hideVotes
            />
          </Suspense>
          <Divider mt={3} orientation="horizontal" color="silver" />
        </>
      );

    default:
      return null;
  }
};

type Props = {
  onActiveSectionChange: (section: Section<any>) => void;
};

const Feed: FunctionComponent<Props> = ({ onActiveSectionChange }) => {
  const feedItems = useFeed();
  const isVoting = useIsVotingActive();
  const markAsRead = useMarkAsRead();
  useCurrentTrack();
  const labelText = useResponsiveTextStyle('Mini label', 'Label');

  useEffect(() => {
    if (feedItems) {
      markAsRead();
    }
  }, [feedItems, markAsRead]);

  const data = useMemo(() => {
    if (feedItems === null) {
      return [];
    }

    const groupedData = groupBy(feedItems, (item) => {
      const date = fromUnixTime(item.created_at / 1000);
      return differenceInCalendarDays(date, Date.now());
    });

    return Object.keys(groupedData).map((group) => {
      return {
        id: group,
        data: groupedData[group],
      };
    });
  }, [feedItems]);

  return (
    <SectionList
      keyExtractor={(item) => item.id}
      data={data}
      renderItem={(item: FeedItem) => {
        return <RenderItem item={item} isVoting={isVoting} />;
      }}
      onActiveSectionChange={onActiveSectionChange}
      renderSectionHeader={(section, index) => {
        if (index === 0) {
          return null;
        }
        const sectionDate = subDays(Date.now(), Math.abs(section.id as number));
        return (
          <>
            <Text textStyle={labelText} mt={4}>
              {getRelativeDay(sectionDate)}
            </Text>
            <Divider mt="12px" orientation="horizontal" color="silver" />
          </>
        );
      }}
    />
  );
};

export default Feed;
