import { Box, Button, Divider, Flex, Icon, Text } from '@chakra-ui/react';
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { ReactComponent as PumpIcon } from '../assets/icons/pump.svg';
import { ReactComponent as PoopIcon } from '../assets/icons/poop.svg';
import {
  usePumpOrPoop,
  useVotes,
  useCanVote,
  useUser,
} from '@worldeggplant/partypooper-react';

type Props = {
  enabled: boolean;
  selected: boolean;
  hidden: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  VoteIcon: ReactNode;
  label: string;
  count: number;
};
const VoteButton: FunctionComponent<Props> = ({
  hidden,
  selected,
  onClick,
  VoteIcon,
  enabled,
  label,
  count,
}) => {
  if (hidden) {
    return <Button variant="vote" px={0} flexGrow={1} flexShrink={1} />;
  }

  return (
    <Button
      variant="vote"
      isFullWidth
      as={Box}
      flexBasis={selected ? '100%' : '50%'}
      _hover={
        !enabled
          ? {
              backgroundColor: 'black',
              cursor: 'not-allowed',
            }
          : undefined
      }
      onClick={onClick}
      grow={1}
      shrink={1}
      opacity={enabled ? 1 : 0.3}
      px={selected ? 4 : 0}
      leftIcon={
        <Icon viewBox="0 0 24 24" fill="white">
          {VoteIcon}
        </Icon>
      }
    >
      {label}
      <Text visibility={count > 0 ? 'visible' : 'hidden'} textStyle="sup">
        {count || 0}
      </Text>
    </Button>
  );
};

const VoteButtons = () => {
  const pumpOrPoop = usePumpOrPoop();
  const canVote = useCanVote();
  const { pumps, poops, votes } = useVotes();
  const { uid = null } = useUser() || {};

  const voted = useMemo((): boolean | null => {
    const voted = votes.find((vote) => vote.uid === uid);

    return voted?.pump ?? null;
  }, [uid, votes]);

  const onClickPumpOrPoop = useCallback(
    (pump: boolean) => async (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      event.preventDefault();
      await pumpOrPoop(pump);
    },
    [pumpOrPoop]
  );

  return (
    <Flex
      grow={1}
      height="100%"
      direction="row"
      justify="stretch"
      align="space-evenly"
    >
      <VoteButton
        enabled={canVote}
        selected={voted === true}
        hidden={voted === false}
        onClick={onClickPumpOrPoop(true)}
        VoteIcon={<PumpIcon />}
        label={voted === true ? 'Pumped' : 'Pump'}
        count={pumps}
      />
      {voted === null && (
        <Divider orientation="vertical" color="black" opacity={0.5} />
      )}
      <VoteButton
        enabled={canVote}
        selected={voted === false}
        hidden={voted === true}
        onClick={onClickPumpOrPoop(false)}
        VoteIcon={<PoopIcon />}
        label={voted === false ? 'Pooped' : 'Poop'}
        count={poops}
      />
    </Flex>
  );
};

export default VoteButtons;
