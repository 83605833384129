import { Avatar, Flex, Icon, IconButton, Input } from '@chakra-ui/react';
import {
  useCurrentProfile,
  useSendMessage,
} from '@worldeggplant/partypooper-react';
import { useCallback, useState } from 'react';
import Debug from '../utilities/debug';

import { ReactComponent as ArrowForwardIcon } from '../assets/icons/arrow-forward.svg';

const debugV = Debug.verbose('chatinput');
const debugE = Debug.error('chatinput');

const ChatInput = () => {
  const [inputValue, setInputValue] = useState<string>('');
  const sendChatMessage = useSendMessage();
  const profile = useCurrentProfile();

  const profileImage = profile?.images?.length
    ? profile.images[0].url
    : undefined;

  const onChangeMessage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
    },
    []
  );

  const validate = useCallback((input: string) => {
    if (input.length === 0) {
      return false;
    }

    return true;
  }, []);

  const submitMessage = useCallback(async () => {
    const validation = validate(inputValue);
    if (!validation) {
      return;
    }

    try {
      await sendChatMessage(inputValue);
      setInputValue('');
    } catch (e) {
      debugE('Could not send message', inputValue);
    }
  }, [inputValue, sendChatMessage, validate]);

  const onSubmitMessage = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      debugV('Submit message', inputValue);
      event.preventDefault();
      submitMessage();
    },
    [inputValue, submitMessage]
  );

  return (
    <form onSubmit={onSubmitMessage}>
      <Flex alignItems="center" py={3}>
        <Avatar
          src={profileImage}
          name={profile?.display_name || profile?.id}
        />
        <Input
          variant="unstyled"
          textStyle="Regular"
          borderRadius={0}
          py={2}
          px={6}
          placeholder="Write a message"
          value={inputValue}
          onChange={onChangeMessage}
          sx={{
            _placeholder: {
              color: 'body',
              opacity: 0.6,
            },
          }}
        />
        <IconButton
          aria-label="Send message"
          disabled={inputValue.length === 0}
          icon={
            <Icon color="black" height={6} width={6} viewBox="0 0 32 32">
              <ArrowForwardIcon />
            </Icon>
          }
          colorScheme="transparent"
          onClick={submitMessage}
        />
      </Flex>
    </form>
  );
};

export default ChatInput;
