import { useCurrentTrackMeta } from '@worldeggplant/partypooper-react';
import { FunctionComponent } from 'react';
import { useRecoilValue } from 'recoil';
import AlbumCoverView from '../../components/AlbumCoverView';
import { trackBackgroundSelector } from '../../state/app/appSelectors';

type Props = {};

const FeedNowPlayingView: FunctionComponent<Props> = () => {
  const trackMeta = useCurrentTrackMeta();
  const backgroundColor = useRecoilValue(
    trackBackgroundSelector(trackMeta?.album.images[0]?.url ?? null)
  );
  return (
    <AlbumCoverView
      image={trackMeta?.album.images[0].url}
      backgroundColor={backgroundColor}
    />
  );
};

export default FeedNowPlayingView;
