/**
 * USAGE:
 *   `const debugV = Debug.verbose('namespace_here');`
 *   will log to `app:v:namespace_here`
 */

import debug from 'debug';

const error = debug('app:e');
error.log = console.error.bind(console);

const warning = debug('app:w');
warning.log = console.warn.bind(console);

const verbose = debug('app:v');
verbose.log = console.log.bind(console);

const Debug = {
  error: (namespace: string) => error.extend(namespace),
  warning: (namespace: string) => warning.extend(namespace),
  verbose: (namespace: string) => verbose.extend(namespace),
};

export default Debug;
