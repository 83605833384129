import { useState, useCallback, FC, useMemo, ChangeEventHandler } from 'react';
import Loading from '../components/Loading';
import { Redirect, RouteProps, useLocation } from 'react-router-dom';
import {
  useAvailableChannels,
  useSignIntoChannel,
  useUserSafe,
} from '@worldeggplant/partypooper-react';
import { Box, Container, Flex, Text } from '@chakra-ui/layout';
import { IconButton } from '@chakra-ui/button';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/input';
import { Image } from '@chakra-ui/image';
import { SmallCloseIcon } from '@chakra-ui/icons';
import ChannelListView from './Channel/ChannelListView';
import Fuse from 'fuse.js';
import { useResponsiveTextStyle } from '../utilities/ResponsiveUtilities';

type RedirectLocationState = {
  from?: RouteProps['location'];
};

const ChannelSelectScreen: FC = () => {
  const location = useLocation<RedirectLocationState>();
  const { from = null } = location.state || {};
  const channels = useAvailableChannels();
  const signIntoChannel = useSignIntoChannel();
  const [redirect, setRedirect] = useState<
    RouteProps['location'] | string | null
  >(null);
  const user = useUserSafe();
  const [filterValue, setFilterValue] = useState<string>('');
  const channelText = useResponsiveTextStyle('Large+', 'Title+');

  const onSelectChannel = useCallback(
    async (channelId: string) => {
      if (user.uid) {
        signIntoChannel(channelId);
        setRedirect(from || '/');
      }
    },
    [from, signIntoChannel, user.uid]
  );

  const fuse = useMemo(() => {
    if (channels === null) {
      return;
    }

    return new Fuse(channels, {
      keys: ['name'],
      threshold: 0.4,
    });
  }, [channels]);

  const sortedChannels = useMemo(() => {
    if (channels) {
      const sorted = [...channels];
      sorted.sort((a, b) => a.created_at - b.created_at);

      return sorted;
    }

    return [];
  }, [channels]);

  const sortedAndFilteredChannels = useMemo(() => {
    if (fuse && filterValue !== '') {
      return (
        fuse?.search(filterValue).map((result) => result.item) ?? sortedChannels
      );
    }
    return sortedChannels;
  }, [filterValue, fuse, sortedChannels]);

  const handleFilterChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFilterValue(e.target.value);
    },
    []
  );

  if (channels === null) {
    return <Loading />;
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Box height="100%" width="100%" m={0}>
      <Container height="100%" w="6xl" maxW="100%" pt={4}>
        <Flex direction="column" grow={1} height="100%">
          <Box>
            <Image alt="Party Pooper logo" w={20} h={20} src="/logo192.png" />
            <Flex
              direction={{ base: 'column', md: 'row' }}
              justifyContent="space-between"
              width="100%"
            >
              <Text textStyle={channelText} mb={{ base: 4, md: 0 }}>
                Channels
              </Text>
              <InputGroup size="md" w={{ base: '100%', md: 'xs' }}>
                <Input
                  value={filterValue}
                  onChange={handleFilterChange}
                  placeholder="Filter"
                  px={3}
                />
                {filterValue !== '' && (
                  <InputRightElement w={12} p={0}>
                    <IconButton
                      variant="unstyled"
                      opacity={0.6}
                      aria-label="Clear"
                      icon={<SmallCloseIcon />}
                      onClick={() => setFilterValue('')}
                    />
                  </InputRightElement>
                )}
              </InputGroup>
            </Flex>
          </Box>
          <Flex
            mt={{ base: 4, md: 3 }}
            grow={1}
            direction="column"
            position="relative"
          >
            {sortedAndFilteredChannels && (
              <ChannelListView
                onSelectChannel={onSelectChannel}
                channels={sortedAndFilteredChannels}
              />
            )}
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default ChannelSelectScreen;
