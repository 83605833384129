import { atom, selectorFamily } from 'recoil';
import { StatePrefix } from '../Core/CoreState';
import {
  ChatMessagesState,
  ChatMessagesSelectorResults,
  ChatMessagesSelectorParams,
} from './ChatTypes';

export const chatMessagesState = atom<ChatMessagesState | null>({
  key: `${StatePrefix}chatMessagesState`,
  default: null,
});

export const chatMessagesSelector = selectorFamily<
  ChatMessagesSelectorResults,
  ChatMessagesSelectorParams
>({
  key: `${StatePrefix}chatMessagesSelector`,
  get: ({ channelId }) => ({ get }) => {
    const chatMessages = get(chatMessagesState);

    if (channelId && chatMessages && chatMessages[channelId]) {
      return chatMessages[channelId];
    }

    return null;
  },
});
