/**
 * Null check helper. Allows us to disregard checking for null values in components
 */

export function nullValueGuard<T extends unknown>(
  valFn: () => T
): () => T extends null | undefined ? never : T {
  return () => {
    const value = valFn();

    if (value === null) {
      throw new Error('Value accessed before it was initated');
    }

    return value as NonNullable<T>;
  };
}
