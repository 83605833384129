import { FunctionComponent, Suspense } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import {
  useCurrentTrack,
  useIsVotingActive,
} from '@worldeggplant/partypooper-react';
import HomeNothingPlayingView from './Home/HomeNothingPlayingView';
import HomeNowPlayingView from './Home/HomeNowPlayingView';
import HomeNowVotingView from './Home/HomeNowVotingView';
import Loading, { Size } from '../components/Loading';

type RouteProps = {};
type Props = {} & RouteComponentProps<RouteProps>;

const HomeScreen: FunctionComponent<Props> = () => {
  const currentTrack = useCurrentTrack();
  const isVotingActive = useIsVotingActive();

  return (
    <Flex direction="column" grow={1}>
      {currentTrack && isVotingActive && (
        <Suspense fallback={<Loading size={Size.lg} />}>
          <HomeNowVotingView />
        </Suspense>
      )}

      {currentTrack && !isVotingActive && (
        <Suspense fallback={<Loading size={Size.lg} />}>
          <HomeNowPlayingView />
        </Suspense>
      )}

      {!currentTrack && <HomeNothingPlayingView />}
    </Flex>
  );
};

export default HomeScreen;
