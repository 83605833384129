import Emittery from 'emittery';

export enum AppEventKeys {
  'AuthStateUpdate' = 'AuthStateUpdate',
  'AuthAccessTokenExpiryReceived' = 'AuthAccessTokenExpiryReceived',
}

export type AppEventMap = {
  [AppEventKeys.AuthStateUpdate]: {
    accessToken: string;
    accessTokenExpiry: number;
    refreshToken: string;
  } | null;
  [AppEventKeys.AuthAccessTokenExpiryReceived]: number | null;
};

let emitter: Emittery<AppEventMap>;

export function getEventEmitter(): Emittery<AppEventMap> {
  if (!emitter) {
    emitter = new Emittery<AppEventMap>();
  }

  return emitter;
}

export default function useEventEmitter(): Emittery<AppEventMap> {
  return getEventEmitter();
}
