import React from 'react';
import { usePlayer } from '../Player/PlayerHooks';
import { useChannel } from './ChannelHooks';

type ChannelRootProps = {};

export const ChannelRoot: React.FunctionComponent<ChannelRootProps> = () => {
  useChannel();
  usePlayer();

  return null;
};
