import { Flex } from '@chakra-ui/react';
import { usePlaylist } from '@worldeggplant/partypooper-react';
import { FunctionComponent } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import TrackList from '../../components/TrackList';
import { useSetNavigationState } from '../../hooks/AppHooks';

type RouteProps = {
  playlistId: string;
};

type Props = {} & RouteComponentProps<RouteProps>;

const PlayPlaylistView: FunctionComponent<Props> = () => {
  const { playlistId } = useParams<RouteProps>();
  useSetNavigationState('currentPlaylistId', playlistId);
  const [playlist, loadNextPage, nextPage, isLoadingNextPage] = usePlaylist(
    playlistId
  );

  if (!playlistId) {
    return <div>No playlist selected</div>;
  }

  return (
    <Flex grow={1} overflowY="auto" sx={{ minHeight: 'min-content' }}>
      {playlist?.tracks && (
        <TrackList
          key={playlistId}
          tracks={playlist.tracks.items
            .filter((track) => !!track)
            .map(({ track }) => track as SpotifyApi.TrackObjectFull)}
          loadNextPage={loadNextPage}
          hasNextPage={!!nextPage}
          isNextPageLoading={isLoadingNextPage}
        />
      )}
    </Flex>
  );
};

export default PlayPlaylistView;
