import { List, ListItem } from '@chakra-ui/react';
import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

export type Section<T> = {
  id: string | number;
  data: T[];
};

interface ElementWithDataset extends Element {
  dataset: { [key: string]: string };
}

type Props<T> = {
  data: Section<T>[];
  renderItem: (item: T) => ReactNode;
  renderSectionHeader: (section: Section<T>, index: number) => ReactNode;
  keyExtractor: (item: T) => string;
  onActiveSectionChange?: (section: Section<T>) => void;
};

function SectionList<T extends unknown>({
  data,
  renderItem,
  renderSectionHeader,
  keyExtractor,
  onActiveSectionChange,
}: Props<T>): JSX.Element {
  const listRef = useRef<HTMLUListElement>(null);
  const [activeSectionId, setActiveSectionId] = useState<
    string | number | null
  >(null);

  const renderRow = useCallback((row, key) => {
    return <ListItem key={key}>{row}</ListItem>;
  }, []);

  const renderStickyRow = useCallback(
    (row, section: Section<T>, key: string | number) => {
      return (
        <ListItem key={key} data-section-id={section.id}>
          {row}
        </ListItem>
      );
    },
    []
  );

  const renderItemRow = useCallback(
    (item: T) => {
      return renderRow(renderItem(item), keyExtractor(item));
    },
    [keyExtractor, renderItem, renderRow]
  );

  const onScroll = useCallback(() => {
    const list = listRef.current;
    if (list) {
      const children = Array.from(list.children).filter(
        (child: any) =>
          child.dataset.sectionId && child.offsetTop < list.scrollTop
      );

      if (!children.length) {
        return;
      }

      const lastChild = children[children.length - 1] as ElementWithDataset;
      const newSectionId = lastChild.dataset.sectionId;
      setActiveSectionId(newSectionId);
    }
  }, []);

  useEffect(() => {
    if (activeSectionId) {
      const activeSection = data.find(
        (section) => section.id === activeSectionId
      );
      if (activeSection && onActiveSectionChange) {
        onActiveSectionChange(activeSection);
      }
    }
  }, [activeSectionId, data, onActiveSectionChange]);

  useEffect(() => {
    if (activeSectionId === null && data.length > 0) {
      const newSectionId = data[0].id;

      setActiveSectionId(newSectionId);
    }
  }, [activeSectionId, data]);

  return (
    <List
      position="relative"
      ref={listRef}
      overflowY="auto"
      onScroll={onScroll}
      height="100%"
    >
      {data?.map((section, index) => {
        return (
          <Fragment key={section.id}>
            {renderStickyRow(
              renderSectionHeader(section, index),
              section,
              section.id
            )}
            {section.data.map(renderItemRow)}
          </Fragment>
        );
      })}
    </List>
  );
}

export default SectionList;
