import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValue,
  useResetRecoilState,
} from 'recoil';
import { useUser } from '../Auth/AuthHooks';
import {
  channelsState,
  votingActiveState,
  voterState,
  playedTracksState,
  currentTrackMetaState,
  currentChannelIdState,
  currentChannelState,
  currentTrackState,
  connectedUsersState,
  canVoteSelector,
  hasSignedIntoChannelState,
} from './ChannelState';
import {
  CurrentTrack,
  VoteProps,
  ObservePlayedTracksResponse,
  selectChannel,
  observeCurrentTrack,
  observeVoting,
  observeVoters,
  observePlayedTracks,
  stopObserveCurrentTrack,
  stopObservingVoting,
  stopObservingPlayedTracks,
  vote,
  queueTrack,
  getChannels,
  observeChat,
  stopObserveChat,
  stopObserveConnectedUsers,
  observeConnectedUsers,
  ConnectedUsers,
  deselectChannel,
} from '../utilities/firebase';
import { nullValueGuard } from '../utilities/hook';
import { chatMessagesState } from '../Chat/ChatState';
import { ChannelsState, VoterState } from './ChannelTypes';

// ...

type UseAvailableChannelsResult = ChannelsState;

export function useAvailableChannels(): UseAvailableChannelsResult {
  const [channels, setChannels] = useRecoilState(channelsState);

  const loadChannels = useCallback(async () => {
    try {
      const result = await getChannels();
      const allChannels = Object.keys(result).map((id) => ({
        id,
        name: result[id].name,
        created_at: result[id].created_at,
        created_by: result[id].created_by,
        updated_at: result[id].updated_at,
      }));

      setChannels(allChannels.sort((a, b) => b.updated_at - a.updated_at));
    } catch (error) {
      console.error('Error when fetching channels', error);
    }
  }, [setChannels]);

  useEffect(() => {
    if (channels === null) {
      loadChannels();
    }
  }, [channels, loadChannels]);

  return channels;
}

export const useCurrentChannel = () => {
  const currentChannel = useRecoilValue(currentChannelState);

  return currentChannel;
};

export const useCurrentChannelSafe = nullValueGuard(useCurrentChannel);

export function useSignIntoChannel() {
  const setCurrentChannel = useSetRecoilState(currentChannelState);
  const availableChannels = useRecoilValue(channelsState);

  const signIntoChannel = useCallback(
    (channelId: string) => {
      if (availableChannels !== null) {
        const channel = availableChannels.find(({ id }) => id === channelId);

        if (channel) {
          setCurrentChannel(channel);
        }
      }
    },
    [availableChannels, setCurrentChannel]
  );

  return signIntoChannel;
}

export function useSignOutOfChannel() {
  const resetCurrentChannel = useResetRecoilState(currentChannelState);

  const signOutOfChannel = useCallback(() => {
    resetCurrentChannel();
  }, [resetCurrentChannel]);

  return signOutOfChannel;
}

function useObserveTrack() {
  const [currentTrack, setCurrentTrack] = useRecoilState(currentTrackState);
  const currentChannelId = useCurrentChannelId();
  const setVoters = useSetRecoilState(voterState);

  const onCurrentTrackChange = useCallback(
    async (track: CurrentTrack | null) => {
      if (
        track &&
        track.started_at !== currentTrack?.started_at &&
        track.uri !== currentTrack?.uri
      ) {
        try {
          setCurrentTrack(track);
        } catch (error) {
          console.error('could not fetch track', error);
        }
      } else if (!track) {
        setVoters([]);
        setCurrentTrack(null);
      }
    },
    [currentTrack, setCurrentTrack, setVoters]
  );

  useEffect(() => {
    const unsubscribeObserveCurrentTrack =
      currentChannelId &&
      observeCurrentTrack(currentChannelId, onCurrentTrackChange);

    return () => {
      unsubscribeObserveCurrentTrack &&
        currentChannelId &&
        stopObserveCurrentTrack(
          currentChannelId,
          unsubscribeObserveCurrentTrack
        );
    };
  }, [currentChannelId, onCurrentTrackChange]);
}

function useObserveVoting() {
  const currentChannelId = useCurrentChannelId();
  const setVotingActiveState = useSetRecoilState(votingActiveState);
  const currentTrack = useCurrentTrack();

  const onVotingActiveChange = useCallback(
    (active: boolean) => {
      setVotingActiveState(active ? !!currentTrack : false);
    },
    [currentTrack, setVotingActiveState]
  );

  useEffect(() => {
    const unsubscribeObserveVotingActive =
      currentChannelId && observeVoting(currentChannelId, onVotingActiveChange);

    return () => {
      unsubscribeObserveVotingActive &&
        currentChannelId &&
        stopObservingVoting(currentChannelId, unsubscribeObserveVotingActive);
    };
  }, [currentChannelId, onVotingActiveChange]);
}

function useObserveVoters() {
  const currentChannelId = useCurrentChannelId();
  const setVoters = useSetRecoilState(voterState);

  const onVotersChange = useCallback(
    (vote: VoteProps) => {
      setVoters((currentVoters) => {
        if (!currentVoters.find((voter) => voter.uid === vote.uid)) {
          return [...currentVoters, vote];
        }
        return currentVoters;
      });
    },
    [setVoters]
  );

  useEffect(() => {
    const unsubscribeObserveVoters =
      currentChannelId && observeVoters(currentChannelId, onVotersChange);

    return () => {
      unsubscribeObserveVoters &&
        currentChannelId &&
        stopObservingVoting(currentChannelId, unsubscribeObserveVoters);
    };
  }, [currentChannelId, onVotersChange]);
}

function useObserveConnectedUsers() {
  const currentChannelId = useCurrentChannelId();
  const setConnectedUsers = useSetRecoilState(connectedUsersState);

  const onConnectedUsersChange = useCallback(
    (connectedUsers: ConnectedUsers) => {
      setConnectedUsers(connectedUsers);
    },
    [setConnectedUsers]
  );

  useEffect(() => {
    const unsubscribeObserveConnectedUsers =
      currentChannelId &&
      observeConnectedUsers(currentChannelId, onConnectedUsersChange);

    return () => {
      unsubscribeObserveConnectedUsers &&
        currentChannelId &&
        stopObserveConnectedUsers(
          currentChannelId,
          unsubscribeObserveConnectedUsers
        );
    };
  }, [currentChannelId, onConnectedUsersChange]);
}

function useObservePlayedTracks() {
  const currentChannelId = useCurrentChannelId();
  const setPlayedTracks = useSetRecoilState(playedTracksState);

  const onPlayedTracksChange = useCallback(
    (playedTracks: Record<string, ObservePlayedTracksResponse>) => {
      if (currentChannelId) {
        setPlayedTracks((currPlayedTracks) => ({
          ...currPlayedTracks,
          [currentChannelId]: {
            ...(currPlayedTracks && currPlayedTracks[currentChannelId]),
            ...(playedTracks || {}),
          },
        }));
      }
    },
    [currentChannelId, setPlayedTracks]
  );

  useEffect(() => {
    const unsubscribePlayedTracks =
      currentChannelId &&
      observePlayedTracks(currentChannelId, onPlayedTracksChange);

    return () => {
      unsubscribePlayedTracks &&
        currentChannelId &&
        stopObservingPlayedTracks(currentChannelId, unsubscribePlayedTracks);
    };
  }, [currentChannelId, onPlayedTracksChange]);
}

function useObserveChat() {
  const setMessages = useSetRecoilState(chatMessagesState);
  const currentChannelId = useCurrentChannelId();

  const onReceiveChatMessages = useCallback(
    (data) => {
      currentChannelId &&
        setMessages((existingMessages) => ({
          ...existingMessages,
          [currentChannelId]: {
            ...(existingMessages && existingMessages[currentChannelId]),
            ...data,
          },
        }));
    },
    [currentChannelId, setMessages]
  );

  useEffect(() => {
    if (currentChannelId) {
      observeChat(currentChannelId, onReceiveChatMessages);
    }

    return () => {
      currentChannelId &&
        stopObserveChat(currentChannelId, onReceiveChatMessages);
    };
  }, [currentChannelId, onReceiveChatMessages]);
}

const useUpdateCurrentChannel = () => {
  const [prevChannelId, setPrevChannelId] = useState<string | null>(null);
  const [
    hasSignedIntoChannel,
    setHasSignedIntoChannel,
  ] = useHasSignedIntoChannel();
  const currentChannelId = useCurrentChannelId();
  const user = useUser();

  useEffect(() => {
    if (user && currentChannelId && !hasSignedIntoChannel) {
      console.log('signing IN to channel', currentChannelId, user.uid);
      setPrevChannelId(currentChannelId);
      setHasSignedIntoChannel(true);
      selectChannel(currentChannelId, user.uid);
    }

    if (user && hasSignedIntoChannel && !currentChannelId && prevChannelId) {
      setHasSignedIntoChannel(false);
      setPrevChannelId(null);
      deselectChannel(prevChannelId, user.uid);
    }
  }, [
    currentChannelId,
    user?.uid,
    hasSignedIntoChannel,
    setHasSignedIntoChannel,
    user,
    prevChannelId,
  ]);
};

export const useHasSignedIntoChannel = () => {
  const state = useRecoilState(hasSignedIntoChannelState);

  return state;
};

export const useCurrentChannelId = () => {
  const currentChannelId = useRecoilValue(currentChannelIdState);

  return currentChannelId;
};

export const useCurrentChannelIdSafe = nullValueGuard(useCurrentChannelId);

export const useCurrentTrack = () => {
  const currentTrack = useRecoilValue(currentTrackState);

  return currentTrack;
};

export const useCurrentTrackSafe = nullValueGuard(useCurrentTrack);

export const useCurrentTrackMeta = () => {
  const currentTrackMeta = useRecoilValue(currentTrackMetaState);

  return currentTrackMeta;
};

export const useCurrentTrackMetaSafe = nullValueGuard(useCurrentTrackMeta);

export const useQueueTrack = () => {
  const user = useUser();
  const currentChannelId = useCurrentChannelId();

  const queue = useCallback(
    async (track: SpotifyApi.TrackObjectFull) => {
      if (track.uri.startsWith('spotify:local:')) {
        throw new Error('Queuing local track not allowed');
      }

      const queueTrackWithFallback = async (): Promise<void> => {
        if (!user?.uid || !currentChannelId) {
          console.warn('Trying to queue track while not logged in');
          return;
        }

        await queueTrack({
          uid: user.uid,
          duration: track.duration_ms,
          trackUri: track.uri,
          channelId: currentChannelId,
        });
      };

      try {
        await queueTrackWithFallback();
      } catch (error) {
        console.warn('Error when queueing track', error);
        if (error?.code === 'PERMISSION_DENIED') {
          // If we get a `PERMISSION_DENIED` error, try to re-select channel
          console.warn('Attempting to re-join channel and queue track again');

          if (currentChannelId && user?.uid) {
            await selectChannel(currentChannelId, user.uid);
            try {
              await queueTrackWithFallback();
            } catch (err) {}
          }
        }
      }
    },
    [currentChannelId, user]
  );

  return queue;
};

export const useIsVotingActive = () => {
  const isVotingActive = useRecoilValue(votingActiveState);

  return isVotingActive;
};

type UseVotes = {
  votes: VoterState;
  pumps: number;
  poops: number;
};

export const useVotes = (): UseVotes => {
  const votes = useRecoilValue(voterState);

  const pumps = votes.filter(({ pump }) => pump).length;
  const poops = votes.filter(({ pump }) => !pump).length;

  const value = useMemo(
    () => ({
      votes,
      pumps,
      poops,
    }),
    [poops, pumps, votes]
  );

  return value;
};

export const useCanVote = () => {
  const canVote = useRecoilValue(canVoteSelector);

  return canVote;
};

export const usePumpOrPoop = () => {
  const currentChannelId = useCurrentChannelId();
  const user = useUser();
  const isVotingActive = useIsVotingActive();

  const pumpOrPoop = useCallback(
    async (pump) => {
      if (!user?.uid || !currentChannelId || !isVotingActive) {
        return;
      }

      await vote({
        channelId: currentChannelId,
        uid: user.uid,
        pump,
      });
    },
    [currentChannelId, isVotingActive, user]
  );

  return pumpOrPoop;
};

export const useConnectedUsers = () => {
  const connectedUsers = useRecoilValue(connectedUsersState);

  return connectedUsers;
};

// ...

export function useChannel() {
  useObserveTrack();
  useObserveVoting();
  useObserveVoters();
  useObservePlayedTracks();
  useObserveChat();
  useObserveConnectedUsers();
  useUpdateCurrentChannel();
}
