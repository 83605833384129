import { useRecoilValue } from 'recoil';
import { useCurrentChannelId } from '../Channel/ChannelHooks';
import { feedItemsSelector } from './FeedState';

export function useFeed() {
  const channelId = useCurrentChannelId();
  const feedItems = useRecoilValue(feedItemsSelector({ channelId }));

  return feedItems;
}
