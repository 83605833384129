import { Box, Button, Flex, List, ListItem, Text } from '@chakra-ui/react';
import { FunctionComponent, Suspense, useCallback, useState } from 'react';
import { Route, RouteComponentProps, useParams } from 'react-router-dom';
import SearchForm from '../components/SearchForm';
import TrackListLoading from '../components/TrackListLoading';
import { useSetNavigationState } from '../hooks/AppHooks';
import Debug from '../utilities/debug';
import {
  useHeaderHeight,
  useIsMobile,
  useResponsiveTextStyle,
} from '../utilities/ResponsiveUtilities';
import SearchAlbumImage from './Search/SearchAlbumImage';
import SearchResults from './Search/SearchResults';

const debugV = Debug.verbose('SearchScreen');

const SEARCH_SUGGESTIONS = [
  'Loljud',
  'Billie Eilish',
  'Edoardo Vianello',
  'FKA twigs',
];

export type RouteParams = {
  searchTerm?: string;
};

type Props = RouteComponentProps<RouteParams>;

const SearchScreen: FunctionComponent<Props> = ({ history }) => {
  const { searchTerm: defaultSearchTerm } = useParams<RouteParams>();
  const [searchTerm, setSearchTerm] = useState<string>(defaultSearchTerm || '');
  const headerHeight = useHeaderHeight();
  const labelText = useResponsiveTextStyle('Mini label', 'Label');
  const buttonText = useResponsiveTextStyle('Small+', 'Regular+');
  const isMobile = useIsMobile();

  useSetNavigationState('currentSearchTerm', searchTerm);

  const onSearch = useCallback(
    (searchTerm: string) => {
      setSearchTerm(searchTerm);
      history.push(`/search/${searchTerm}`);
    },
    [history]
  );

  const onSubmitSearchForm = useCallback(
    async (value: string) => {
      debugV('Submitted form', value);
      if (value.length > 2) {
        onSearch(value);
      }
    },
    [onSearch]
  );

  return (
    <>
      <Flex
        px={{ base: 6, md: 28 }}
        mt={{ base: 5, md: 28 }}
        grow={1}
        pt={headerHeight}
        direction="column"
      >
        <SearchForm
          initialValue={defaultSearchTerm}
          onSubmit={onSubmitSearchForm}
        />

        <Flex
          grow={1}
          overflowY="auto"
          direction={{ base: 'column', md: 'row' }}
          mt={6}
        >
          {(!isMobile || searchTerm === '') && (
            <Box direction="column" pr={4} mr={6}>
              <Text textStyle={labelText} color="grey">
                Suggested
              </Text>
              <List spacing={1} mt={2}>
                {SEARCH_SUGGESTIONS.map((searchSuggestion) => (
                  <ListItem key={searchSuggestion}>
                    <Button
                      variant="unstyled"
                      textStyle={buttonText}
                      onClick={() => {
                        onSubmitSearchForm(searchSuggestion);
                      }}
                    >
                      {searchSuggestion}
                    </Button>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}

          <Flex
            grow={1}
            direction="column"
            maxWidth={{ base: 'auto', md: 'md' }}
            ml={{ base: 0, md: 'auto' }}
            mr={{ base: 0, md: 'auto' }}
            px={{ base: 0, md: 3 }}
            pb={{ base: 0, md: 3 }}
          >
            <Suspense fallback={<TrackListLoading compactMode />}>
              <Route path="/search/:searchTerm" component={SearchResults} />
            </Suspense>
          </Flex>

          {!isMobile && (
            <Flex justify="center" basis="200px" ml="auto" grow={0}>
              <SearchAlbumImage searchTerm={searchTerm} />
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default SearchScreen;
