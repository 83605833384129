import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import {
  accessTokenState,
  accessTokenExpiryState,
  refreshTokenState,
} from '../Auth/AuthState';
import { StatePrefix } from '../Core/CoreState';
import { configSafeState } from '../Core/CoreState';
import { localStorageEffect } from '../utilities/state';
import {
  SpotifyApiCredentialSelectorResult,
  GenericLoadStateParams,
  SpotifyGenericLoadStateParams,
  PagingFunctions,
  DevicesState,
  DevicesLoadingState,
  CurrentDeviceState,
  TrackMetaStateResult,
  TrackMetaStateParams,
  ProfileSelectorResult,
  ProfileSelectorParams,
} from './SpotifyTypes';
import { getTrackIdFromUri, spotifyApiClient } from './SpotifyUtilities';

export const spotifyApiCredentialSelector = selector<SpotifyApiCredentialSelectorResult>(
  {
    key: `${StatePrefix}spotifyApiCredentialSelector`,
    get: ({ get }) => {
      const accessToken = get(accessTokenState);
      const accessTokenExpiry = get(accessTokenExpiryState);
      const refreshToken = get(refreshTokenState);
      const config = get(configSafeState);

      if (!accessToken || !refreshToken) {
        throw new Error('No access token or refreshToken or accessTokenExpiry');
      }

      return {
        accessToken,
        refreshToken,
        accessTokenExpiry,
        serverBaseUri: config.serverBaseUri,
      };
    },
  }
);

// ...

export const genericLoadState = selectorFamily<
  unknown | null,
  GenericLoadStateParams
>({
  key: 'genericLoadState',
  get: ({ path }) => async ({ get }) => {
    const spotifyApi = await spotifyApiClient(
      get(spotifyApiCredentialSelector)
    );

    const results = spotifyApi.getGeneric(path);

    return results;
  },
  set: ({ path }) => ({ set }, value) => {
    set(genericLoadState({ path }), value);
  },
});

export const spotifyGenericState = atomFamily({
  key: 'spotifyGenericState',
  default: (_params) => null,
});

export const spotifyGenericLoadState = selectorFamily({
  key: 'spotifyGenericLoadState',
  get: (params) => async ({ get }) => {
    const { method, options } = params as SpotifyGenericLoadStateParams<
      keyof PagingFunctions,
      Parameters<PagingFunctions[keyof PagingFunctions]>
    >;
    const spotifyApi = await spotifyApiClient(
      get(spotifyApiCredentialSelector)
    );

    try {
      const func = spotifyApi[method];
      const results = await func.bind(spotifyApi)(...(options ? options : []));

      return results;
    } catch (error) {
      console.error('spotifyGenericLoadState', error);
      return null;
    }
  },
  set: (params) => ({ set }, value) => {
    set(spotifyGenericState(params), value);
  },
});

// ...

export const devicesState = atom<DevicesState>({
  key: `${StatePrefix}devicesState`,
  default: null,
});

export const devicesLoadingState = atom<DevicesLoadingState>({
  key: `${StatePrefix}devicesLoadingState`,
  default: false,
});

export const currentDeviceState = atom<CurrentDeviceState>({
  key: `${StatePrefix}currentDeviceState`,
  default: null,
  effects_UNSTABLE: [
    localStorageEffect<CurrentDeviceState>(`${StatePrefix}currentDeviceState`),
  ],
});

// ...

export const trackMetaState = selectorFamily<
  TrackMetaStateResult | null,
  TrackMetaStateParams
>({
  key: `${StatePrefix}trackMetaState`,
  get: ({ trackUri }) => async ({ get }) => {
    const spotifyApi = await spotifyApiClient(
      get(spotifyApiCredentialSelector)
    );

    const trackId = getTrackIdFromUri(trackUri);

    try {
      const results = await spotifyApi.getTrack(trackId);
      return results;
    } catch (error) {
      console.error('getTrackMetaData error', error);
      return null;
    }
  },
});

// ...

export const profileSelector = selectorFamily<
  ProfileSelectorResult | null,
  ProfileSelectorParams
>({
  key: 'profileSelector',
  get: ({ uid }) => async ({ get }) => {
    if (!uid) {
      return null;
    }

    const spotifyApi = await spotifyApiClient(
      get(spotifyApiCredentialSelector)
    );

    try {
      const results = await spotifyApi.getUser(uid);
      return results;
    } catch (error) {
      console.error('getProfileData error', error);
      return null;
    }
  },
});
