import { Flex, Text } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { config, useSpring, animated } from 'react-spring';
import { ReactComponent as PartyPooperLogo } from '../assets/icons/partypooper-logo.svg';
import { useResponsiveTextStyle } from '../utilities/ResponsiveUtilities';

export enum Size {
  'sm' = 20,
  'md' = 40,
  'lg' = 80,
}

type Props = {
  noAnimation?: boolean;
  hideText?: boolean;
  size?: Size;
};

const Loading: FunctionComponent<Props> = ({
  hideText,
  size = Size.md,
  noAnimation = false,
}) => {
  const labelText = useResponsiveTextStyle('Mini label', 'Label');
  const { y } = useSpring({
    to: { y: 100 },
    from: { y: 0 },
    loop: true,
    config: {
      ...config.molasses,
      duration: 500,
    },
    delay: 300,
  });

  return (
    <Flex grow={1} justify="center" align="center" direction="column" mt="40px">
      <animated.div
        style={{
          transform: noAnimation
            ? undefined
            : y
                .to({
                  range: [0, 25, 50, 75, 100],
                  output: [0, -20, 0, -10, 0],
                })
                .to((yValue) => `translate3d(0, ${yValue}px, 0)`),
        }}
      >
        <PartyPooperLogo height={size} width={size} />
      </animated.div>

      {!hideText && (
        <Text mt={2} textStyle={labelText}>
          NOW LOADING
        </Text>
      )}
    </Flex>
  );
};

export default Loading;
