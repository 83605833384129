import { atom } from 'recoil';
import { StatePrefix } from '../Core/CoreState';
import { localStorageEffect } from '../utilities/state';
import { PlaybackEnabledState } from './PlayerTypes';

export const playbackEnabledState = atom<PlaybackEnabledState>({
  key: `${StatePrefix}playbackEnabledState`,
  default: false,
  effects_UNSTABLE: [
    localStorageEffect<PlaybackEnabledState>(
      `${StatePrefix}playbackEnabledState`
    ),
  ],
});
