import { atom, atomFamily } from 'recoil';
import { localStorageEffect } from '../../utilities/StateUtilities';

// ...

type QueuedTracksState = string[];

export const queuedTracksState = atom<QueuedTracksState>({
  key: 'queuedTracksState',
  default: [],
});

// ...

export type NavigationState = {
  currentPlaylistId: string | null;
  currentSearchTerm: string | null;
};

export const navigationState = atom<NavigationState>({
  key: 'navigationState',
  default: {
    currentPlaylistId: null,
    currentSearchTerm: null,
  },
});

// ...

export enum PlayScreenViewMode {
  list,
  grid,
}

export const playScreenViewMode = atom<PlayScreenViewMode>({
  key: 'playScreenViewMode',
  default: PlayScreenViewMode.grid,
});

// ...

export type FeedLastSeenItemIdState = string | null;
export type FeedLastSeenItemIdParams = { channelId: string };

export const feedLastSeenItemIdState = atomFamily<
  FeedLastSeenItemIdState,
  FeedLastSeenItemIdParams
>({
  key: 'feedLastSeenItemIdState',
  default: null,
  effects_UNSTABLE: [localStorageEffect('feedLastSeenItemIdState')],
});

export type FeedUnreadItemState = number;

export const feedUnreadItemCountState = atom<FeedUnreadItemState>({
  key: 'feedUnreadItemCountState',
  default: 0,
});

// ...

export type AppHasSeenWelcome = number | null;

export const appHasSeenWelcomeState = atom<AppHasSeenWelcome>({
  key: 'appHasSeenWelcomeState',
  default: null,
  effects_UNSTABLE: [localStorageEffect('appHasSeenWelcomeState')],
});

// ...

export type AppMobileMenuOpen = boolean;

export const appMobileMenuOpenState = atom<AppMobileMenuOpen>({
  key: 'appMobileMenuOpenState',
  default: false,
});

// ...

export type AppIsMobile = boolean;

export const appIsMobileState = atom<AppIsMobile>({
  key: 'appIsMobileState',
  default: false,
});
