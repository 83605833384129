import { differenceInCalendarDays, format } from 'date-fns';

const pad = (part: number) => `0${part}`.slice(-2);

export const millisecondsToHumanReadable = (ms: number): string => {
  const { days, hours, minutes, seconds } = millisecondsToTime(ms);

  if (days > 0) {
    return `${days}:${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  }
  if (hours > 0) {
    return `${hours}:${pad(minutes)}:${pad(seconds)}`;
  }

  return `${minutes}:${pad(seconds)}`;
};

export const millisecondsToTime = (
  ms: number
): {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
} => {
  var days, hours, minutes, seconds;
  seconds = Math.floor(ms / 1000);
  minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;
  hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  days = Math.floor(hours / 24);
  hours = hours % 24;

  return { days, hours, minutes, seconds };
};

export function getRelativeDay(date: Date) {
  const daysAgo = Math.abs(differenceInCalendarDays(date, Date.now()));

  if (daysAgo > 6) {
    return `${daysAgo} days ago`;
  }

  switch (daysAgo) {
    case 0:
      return 'today';
    case 1:
      return 'yesterday';
    default:
      return format(date, 'EEEE');
  }
}
