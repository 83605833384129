import { Box, Text } from '@chakra-ui/layout';
import { Channel } from '@worldeggplant/partypooper-react/dist/utilities/firebase';
import { FunctionComponent, useCallback, useRef } from 'react';
import {
  Index,
  IndexRange,
  SectionRenderedParams,
  GridCellRenderer,
  InfiniteLoader,
  AutoSizer,
  Grid,
} from 'react-virtualized';
import {
  useIsMobile,
  useResponsiveTextStyle,
} from '../../utilities/ResponsiveUtilities';

type Props = {
  channels: Channel[];
  onSelectChannel: (id: string) => void;
};

const ChannelListView: FunctionComponent<Props> = ({
  channels,
  onSelectChannel,
}) => {
  const isRowLoaded = (_params: Index): boolean => true;
  const loadMoreRows = async () => {};
  const onRowsRenderedRef = useRef<(params: IndexRange) => void>();
  const widthRef = useRef<number>();
  const headerText = useResponsiveTextStyle('Regular+', 'Large+');
  const descriptionText = useResponsiveTextStyle('Small', 'Regular');
  const isMobile = useIsMobile();

  const columnCount = isMobile ? 2 : 4;
  const itemLength = channels.length;
  const hasNextPage = false;
  const rowCount = Math.ceil(itemLength / columnCount) + (hasNextPage ? 1 : 0);
  const onSectionRendered = useCallback(
    ({
      columnStartIndex,
      columnStopIndex,
      rowStartIndex,
      rowStopIndex,
    }: SectionRenderedParams) => {
      const startIndex = rowStartIndex * columnCount + columnStartIndex;
      const stopIndex = rowStopIndex * columnCount + columnStopIndex;

      onRowsRenderedRef.current &&
        onRowsRenderedRef.current({ startIndex, stopIndex });
    },
    [columnCount]
  );

  const renderRow: GridCellRenderer = useCallback(
    ({ columnIndex, rowIndex, key, style }) => {
      const index = rowIndex * columnCount + columnIndex;
      const cellStyle = { ...style };
      const channel = channels[index];

      return (
        <Box
          key={key}
          style={cellStyle}
          h="100%"
          pl={index % columnCount > 0 ? 3 : 0}
          pb={3}
        >
          {channel && (
            <Box
              bg="white"
              cursor="pointer"
              borderColor="black10"
              borderWidth={1}
              _hover={{
                shadow: 'lg',
              }}
              onClick={() => onSelectChannel(channel.id)}
              transition="all 0.3s ease"
              key={channel.id}
              borderRadius="md"
              shadow="md"
              display="flex"
              flexDirection="column"
              p={4}
              w="100%"
              h="100%"
            >
              <Text
                width="100%"
                textStyle={headerText}
                noOfLines={3}
                isTruncated
              >
                {channel.name}
              </Text>
              <Text mt="auto" textStyle={descriptionText}>
                Created by {channel.created_by}
              </Text>
            </Box>
          )}
        </Box>
      );
    },
    [columnCount, channels, headerText, descriptionText, onSelectChannel]
  );

  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={loadMoreRows}
      rowCount={channels.length}
    >
      {({ onRowsRendered, registerChild }) => {
        onRowsRenderedRef.current = onRowsRendered;
        return (
          <AutoSizer>
            {({ height, width }) => {
              widthRef.current = width;
              return (
                <Grid
                  columnCount={columnCount}
                  ref={registerChild}
                  onSectionRendered={onSectionRendered}
                  cellRenderer={renderRow}
                  height={height}
                  columnWidth={width / columnCount}
                  rowHeight={140}
                  rowCount={rowCount}
                  width={width}
                />
              );
            }}
          </AutoSizer>
        );
      }}
    </InfiniteLoader>
  );
};

export default ChannelListView;
