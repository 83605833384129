import { FunctionComponent, useEffect } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { redirectState } from '../state/auth/authState';
import queryString from 'query-string';
import Loading from '../components/Loading';
import { errorState } from '../state/error/errorState';
import { useAuth, useUser } from '@worldeggplant/partypooper-react';

type RouteProps = {};
type Props = {} & RouteComponentProps<RouteProps>;

const AuthScreen: FunctionComponent<Props> = () => {
  const redirect = useRecoilValue(redirectState);
  const [appError, setError] = useRecoilState(errorState);

  const { validateAuth, isAuthenticationInProgress } = useAuth();
  const user = useUser();

  const { code, state } = queryString.parse(window.location.search);

  useEffect(() => {
    if (isAuthenticationInProgress && code && state) {
      validateAuth(state as string, code as string).catch((error) =>
        setError(error.toString())
      );
    }
  }, [code, isAuthenticationInProgress, setError, state, validateAuth]);

  if (isAuthenticationInProgress || !user) {
    return <Loading />;
  }

  if (appError) {
    return <>ERROR: {appError}</>;
  }

  return <Redirect to={redirect || '/'} />;
};

export default AuthScreen;
