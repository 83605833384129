import { Avatar, AvatarProps } from '@chakra-ui/react';
import { useProfile } from '@worldeggplant/partypooper-react';
import { FunctionComponent } from 'react';

type Props = {
  uid: string | undefined;
} & AvatarProps;

const UserAvatar: FunctionComponent<Props> = ({ uid, ...avatarProps }) => {
  const profile = useProfile(uid);
  const picture = profile?.images?.length ? profile.images[0].url : null;

  return (
    <Avatar
      {...avatarProps}
      bg="pressedWhite"
      src={picture ?? undefined}
      name={profile?.display_name ?? uid}
      getInitials={(name) => name.substring(0, 2)}
    />
  );
};

export default UserAvatar;
