import { FunctionComponent, Suspense, useCallback, useMemo } from 'react';
import { NavLink, Switch } from 'react-router-dom';
import Loading from '../../components/Loading';
import { Route } from 'react-router-dom';
import PlayPlaylistView from '../Play/PlayPlaylistView';
import {
  AspectRatio,
  Box,
  Flex,
  Icon,
  IconButton,
  Image,
  Link,
  Text,
  useToken,
} from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import InfiniteLoadingList from '../../components/InfiniteLoadingList';
import InfiniteLoadingGrid from '../../components/InfiniteLoadingGrid';
import { Index } from 'react-virtualized';
import { ReactComponent as ListIcon } from '../../assets/icons/list.svg';
import { ReactComponent as GridIcon } from '../../assets/icons/grid.svg';
import {
  playScreenViewMode,
  PlayScreenViewMode,
} from '../../state/app/appState';
import PlayLikedTracksView from '../Play/PlayLikedTracksView';
import {
  usePlaylists,
  useLikedTracks,
  useCurrentProfile,
} from '@worldeggplant/partypooper-react';
import TrackListLoading from '../../components/TrackListLoading';
import { useHeaderHeight } from '../../utilities/ResponsiveUtilities';
import { SkeletonGrid, SkeletonList } from './PlayScreenSkeleton';
import { ChevronRightIcon } from '@chakra-ui/icons';

export type UserPlaylist = {
  id: string;
  name: string;
  total: number;
  image: string | null;
  link: string;
};

type Props = {
  playlistId: 'liked' | string;
};

const PlayScreenMobileView: FunctionComponent<Props> = ({ playlistId }) => {
  const headerHeight = useHeaderHeight();
  const [likedTracks] = useLikedTracks();
  const profile = useCurrentProfile();
  const [
    playlistItems,
    loadNextPage,
    nextPage,
    isLoadingNextPage,
  ] = usePlaylists();

  const listItems = useMemo(() => {
    const items: UserPlaylist[] = [];

    if (likedTracks) {
      items.push({
        id: 'liked',
        name: 'Liked tracks',
        total: likedTracks.total,
        image: profile?.images?.length ? profile.images[0].url : null,
        link: '/play/liked',
      });
    }

    if (playlistItems?.items.length) {
      items.push(
        ...playlistItems.items.map((playlist) => ({
          id: playlist.id,
          name: playlist.name,
          total: playlist.tracks.total,
          image: playlist.images.length ? playlist.images[0].url : null,
          link: `/play/${playlist.id}`,
        }))
      );
    }

    return items;
  }, [likedTracks, playlistItems?.items, profile?.images]);

  const activeColor = useToken('colors', 'black');
  const [viewMode, setViewMode] = useRecoilState(playScreenViewMode);

  const onToggleViewMode = useCallback(() => {
    setViewMode(
      viewMode === PlayScreenViewMode.list
        ? PlayScreenViewMode.grid
        : PlayScreenViewMode.list
    );
  }, [setViewMode, viewMode]);

  const renderItem = useCallback(
    ({ index }: Index) => {
      const item = listItems && listItems[index];

      if (!item) {
        return null;
      }

      switch (viewMode) {
        case PlayScreenViewMode.grid:
          return (
            <Link
              as={NavLink}
              activeStyle={{
                color: activeColor,
              }}
              display="block"
              _hover={{ fontStyle: 'normal' }}
              to={item.link}
              title={item.name}
            >
              {item.image !== null && (
                <AspectRatio ratio={1}>
                  <Image
                    sx={
                      playlistId === item.id
                        ? {
                            border: '2px solid black',
                            padding: '2px',
                          }
                        : undefined
                    }
                    src={item.image}
                    fallback={<Box height="100%" width="100%" bg="wildSand" />}
                    width="100%"
                    height="100%"
                    fit="cover"
                  />
                </AspectRatio>
              )}

              <Text
                as="span"
                display="inline-block"
                textStyle="Small+"
                mt={2}
                width="100%"
                float="left"
                isTruncated
              >
                {item.name}
              </Text>
              <Text
                as="span"
                display="inline-block"
                float="left"
                textStyle="Mini"
                width="100%"
              >
                {item.total} tracks
              </Text>
            </Link>
          );

        case PlayScreenViewMode.list:
        default:
          return (
            <Link
              as={NavLink}
              _hover={{ fontStyle: 'normal' }}
              to={item.link}
              title={item.name}
            >
              <Text
                as="span"
                display="flex"
                flexDirection="row"
                flexGrow={1}
                justifyContent="space-between"
                textStyle="Medium+"
                opacity={playlistId === item.id ? 1 : 0.7}
                isTruncated
                maxWidth="100%"
              >
                {item.name}
                <ChevronRightIcon width="24px" height="24px" />
              </Text>
            </Link>
          );
      }
    },
    [activeColor, playlistId, listItems, viewMode]
  );

  if (!playlistId) {
    return (
      <Flex direction="column" px={3} grow={1} shrink={0} mt={headerHeight}>
        <Flex direction="row" justifyContent="space-between" py={4}>
          <Text textStyle="Regular+">Playlists ({playlistItems?.total})</Text>
          <IconButton
            variant="unstyled"
            aria-label="Switch mode"
            onClick={onToggleViewMode}
            icon={
              <Icon width="24px" height="24px">
                {viewMode === PlayScreenViewMode.list ? (
                  <GridIcon />
                ) : (
                  <ListIcon />
                )}
              </Icon>
            }
          />
        </Flex>
        <Flex grow={1} direction="column" overflowY="auto">
          {viewMode === PlayScreenViewMode.list ? (
            <InfiniteLoadingList
              items={listItems}
              hasNextPage={nextPage !== null}
              isNextPageLoading={isLoadingNextPage}
              rowHeight={54}
              loadNextPage={loadNextPage}
              renderItem={renderItem}
              renderItemLoading={<SkeletonList />}
            />
          ) : (
            <InfiniteLoadingGrid
              columnCount={2}
              items={listItems.filter((x) => x.id)}
              hasNextPage={nextPage !== null}
              isNextPageLoading={isLoadingNextPage}
              columnSpacing={20}
              columnHeight={270}
              loadNextPage={loadNextPage}
              renderItem={renderItem}
              renderItemLoading={<SkeletonGrid />}
            />
          )}
        </Flex>
      </Flex>
    );
  }

  return (
    <>
      {listItems === null ? (
        <Loading />
      ) : (
        <Flex grow={1} px={3} direction="column" pt={headerHeight}>
          <Text textStyle="Regular+" py={4}>
            {playlistId === 'liked' || playlistId === ''
              ? 'Liked tracks'
              : listItems.find((x) => x.id === playlistId)?.name ?? ''}
          </Text>

          <Suspense fallback={<TrackListLoading />}>
            <Switch>
              <Route path="/play/liked" component={PlayLikedTracksView} />
              <Route path="/play/:playlistId" component={PlayPlaylistView} />
            </Switch>
          </Suspense>
        </Flex>
      )}
    </>
  );
};

export default PlayScreenMobileView;
