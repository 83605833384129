import { useToken } from '@chakra-ui/react';
import { prominent } from 'color.js';

const FALLBACK_COLOR = '#FFFFFF';

export async function getColorFromImage(imageUrl: string): Promise<string> {
  try {
    const colors = await prominent(imageUrl, { amount: 3, format: 'hex' });

    if (!Array.isArray(colors)) {
      return colors;
    }

    return (
      (colors as string[]).reduce<string | null>((currentColor, color) => {
        if (!currentColor && !['#000000', '#FFFFFF'].includes(color)) {
          currentColor = color;
        }

        return currentColor;
      }, null) || FALLBACK_COLOR
    );
  } catch (error) {
    return FALLBACK_COLOR;
  }
}

function percentToHex(percent: number): string {
  return Math.round((percent / 100) * 255)
    .toString(16)
    .padStart(2, '0')
    .toUpperCase();
}

export function getColorWithOpacity(color: string, opacity: number): string {
  return `${color}${percentToHex(opacity)}`;
}

export function useColorWithOpacity(color: string, opacity: number): string {
  const tokenColor = useToken('colors', color);

  return getColorWithOpacity(tokenColor, opacity);
}
