import { FunctionComponent } from 'react';
import { getArtistFromTrack } from '../utilities/SpotifyUtilities';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { useTrackMeta } from '@worldeggplant/partypooper-react';
import FeedVotes from './FeedVotes';
import { useResponsiveTextStyle } from '../utilities/ResponsiveUtilities';
import ArtistLink from './ArtistLink';

type Props = {
  trackUri: string;
  hideVotes?: boolean;
  votes?: { pumps: number; poops: number };
};

const FeedTrack: FunctionComponent<Props> = ({
  trackUri,
  votes = {},
  hideVotes = false,
}) => {
  const trackMeta = useTrackMeta(trackUri);
  const trackTitleText = useResponsiveTextStyle('Small+', 'Regular+');
  const artistText = useResponsiveTextStyle('Small', 'Regular');
  const { pumps, poops } = votes;

  if (trackMeta === null) {
    return null;
  }

  return (
    <Flex grow={1} p={3} backgroundColor="fadedWhite">
      <Image
        flexShrink={0}
        fallback={
          <Box
            height={20}
            width={20}
            bgColor="wildSand"
            mr={3}
            borderRadius="md"
          />
        }
        width={20}
        height={20}
        borderRadius="md"
        src={trackMeta.album.images[0].url}
        mr={3}
      />
      <Flex direction="column" grow={1}>
        <Box>
          <Text textStyle={trackTitleText}>{trackMeta.name}</Text>
        </Box>
        <Box>
          <Text textStyle={artistText}>
            <ArtistLink artist={getArtistFromTrack(trackMeta)} />
          </Text>
        </Box>

        {!hideVotes && <FeedVotes pumps={pumps} poops={poops} />}
      </Flex>
    </Flex>
  );
};

export default FeedTrack;
