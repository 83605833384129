import { ReactNode, useCallback, useRef } from 'react';
import {
  AutoSizer,
  Index,
  InfiniteLoader,
  InfiniteLoaderProps,
  Grid,
  GridCellRenderer,
  SectionRenderedParams,
  IndexRange,
} from 'react-virtualized';

type Props<T> = {
  items: T[];
  loadNextPage: () => void;
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  renderItem: ({ index }: Index) => ReactNode;
  renderItemLoading: ReactNode;
  columnCount: number;
  columnHeight: number;
  columnSpacing: number;
};

const InfiniteLoadingGrid = <T extends unknown>({
  hasNextPage,
  items,
  loadNextPage,
  isNextPageLoading,
  renderItem,
  renderItemLoading,
  columnCount,
  columnHeight,
  columnSpacing,
}: Props<T>) => {
  const onRowsRenderedRef = useRef<(params: IndexRange) => void>();
  const widthRef = useRef<number>();
  const rowCount =
    Math.ceil(items.length / columnCount) + (hasNextPage ? 1 : 0);
  const itemCount = items.length + (hasNextPage ? 1 : 0);

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreRows: InfiniteLoaderProps['loadMoreRows'] = useCallback(async () => {
    if (!isNextPageLoading) {
      loadNextPage();
    }
  }, [isNextPageLoading, loadNextPage]);

  // Every row is loaded except for our loading indicator row.
  const isRowLoaded = useCallback(
    ({ index }: Index): boolean => {
      return !hasNextPage || index < items.length;
    },
    [hasNextPage, items.length]
  );

  const renderRow: GridCellRenderer = useCallback(
    ({ columnIndex, rowIndex, key, style }) => {
      let content: ReactNode = null;
      const index = rowIndex * columnCount + columnIndex;
      const cellStyle = { ...style };

      if (
        columnSpacing &&
        typeof cellStyle.left === 'number' &&
        widthRef.current
      ) {
        const itemBaseWidth =
          (widthRef.current - (columnCount - 1) * columnSpacing) / columnCount;
        const itemWidth =
          columnIndex > 0 ? itemBaseWidth + columnSpacing : itemBaseWidth;
        const left =
          columnIndex > 0
            ? itemBaseWidth * columnIndex + (columnIndex - 1) * columnSpacing
            : 0;

        cellStyle.width = itemWidth;
        cellStyle.left = left;

        if (columnIndex > 0) {
          cellStyle.paddingLeft = columnSpacing;
        }
      }

      if (!isRowLoaded({ index })) {
        if (index < itemCount) {
          content = renderItemLoading;
        }
      } else {
        content = renderItem({
          index,
        });
      }

      return (
        <div key={key} style={cellStyle}>
          {content}
        </div>
      );
    },
    [
      columnCount,
      columnSpacing,
      isRowLoaded,
      itemCount,
      renderItem,
      renderItemLoading,
    ]
  );

  const onSectionRendered = useCallback(
    ({
      columnStartIndex,
      columnStopIndex,
      rowStartIndex,
      rowStopIndex,
    }: SectionRenderedParams) => {
      const startIndex = rowStartIndex * columnCount + columnStartIndex;
      const stopIndex = rowStopIndex * columnCount + columnStopIndex;

      onRowsRenderedRef.current &&
        onRowsRenderedRef.current({ startIndex, stopIndex });
    },
    [columnCount]
  );

  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={loadMoreRows}
      rowCount={itemCount}
    >
      {({ onRowsRendered, registerChild }) => {
        onRowsRenderedRef.current = onRowsRendered;
        return (
          <AutoSizer>
            {({ height, width }) => {
              widthRef.current = width;

              return (
                <Grid
                  columnCount={columnCount}
                  ref={registerChild}
                  onSectionRendered={onSectionRendered}
                  cellRenderer={renderRow}
                  height={height}
                  columnHeight={columnHeight}
                  columnWidth={width / columnCount}
                  rowHeight={columnHeight}
                  rowCount={rowCount}
                  width={width}
                />
              );
            }}
          </AutoSizer>
        );
      }}
    </InfiniteLoader>
  );
};

export default InfiniteLoadingGrid;
