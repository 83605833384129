import { FunctionComponent, useCallback } from 'react';
import { AspectRatio, Box, Flex, Skeleton, Text } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import InfiniteLoadingList from '../../components/InfiniteLoadingList';
import InfiniteLoadingGrid from '../../components/InfiniteLoadingGrid';
import {
  playScreenViewMode,
  PlayScreenViewMode,
} from '../../state/app/appState';
import TrackListLoading from '../../components/TrackListLoading';
import {
  useHeaderHeight,
  useIsMobile,
  useResponsiveTextStyle,
} from '../../utilities/ResponsiveUtilities';

export const SkeletonGrid = () => (
  <Box width="100%" height="100%">
    <AspectRatio ratio={1}>
      <Skeleton width="100%" height="100%" />
    </AspectRatio>
    <Skeleton mt={2} width="50%" height={4} />
    <Skeleton mt={1} width="30%" height={4} />
  </Box>
);

export const SkeletonList = () => (
  <Skeleton width="100%" height={4} maxWidth="100%" />
);

const PlayScreenSkeleton: FunctionComponent<unknown> = () => {
  const headerText = useResponsiveTextStyle('Regular+', 'Large+');
  const viewMode = useRecoilValue(playScreenViewMode);
  const headerHeight = useHeaderHeight();
  const isMobile = useIsMobile();

  const renderItem = useCallback(() => {
    switch (viewMode) {
      case PlayScreenViewMode.grid:
        return <SkeletonGrid />;

      case PlayScreenViewMode.list:
      default:
        return <SkeletonList />;
    }
  }, [viewMode]);

  return (
    <>
      <Flex
        grow={1}
        width="100%"
        direction="row"
        mt={headerHeight}
        borderTop={{ base: 'none', md: '1px' }}
        borderTopColor={{ base: 'none', md: 'silver' }}
      >
        <Flex
          width="100%"
          basis={isMobile ? '100%' : 'lg'}
          direction="column"
          px={{ base: 3, md: 6 }}
          align="stretch"
          borderRight={{ base: 'none', md: '1px' }}
          borderRightColor={{ base: 'none', md: 'silver' }}
        >
          <Text textStyle={headerText} py={{ base: 4, md: 4 }}>
            Library
          </Text>

          <Flex width="100%" grow={1} overflowY="auto">
            {viewMode === PlayScreenViewMode.list ? (
              <InfiniteLoadingList
                items={Array(8).fill(null)}
                hasNextPage={false}
                isNextPageLoading={false}
                rowHeight={54}
                loadNextPage={() => {}}
                renderItem={renderItem}
                renderItemLoading={null}
              />
            ) : (
              <InfiniteLoadingGrid
                columnCount={2}
                items={Array(8).fill(null)}
                hasNextPage={false}
                isNextPageLoading={false}
                columnSpacing={20}
                columnHeight={isMobile ? 270 : 298}
                loadNextPage={() => {}}
                renderItem={renderItem}
                renderItemLoading={null}
              />
            )}
          </Flex>
        </Flex>

        {!isMobile && (
          <Flex grow={1} direction="column" px={16}>
            <Skeleton noOfLines={1} my={4} h="30px" w={32} />

            <TrackListLoading />
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default PlayScreenSkeleton;
