import { FunctionComponent } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import AlbumCoversImage from '../../assets/images/NothingPlayingBackground.png';
import AlbumCoversImageMobile from '../../assets/images/mobile-covers.png';
import {
  useHeaderHeight,
  useIsMobile,
  useResponsiveTextStyle,
} from '../../utilities/ResponsiveUtilities';

type Props = {};

const HomeNothingPlayingView: FunctionComponent<Props> = () => {
  const headerHeight = useHeaderHeight();
  const isMobile = useIsMobile();
  const h1 = useResponsiveTextStyle('Title+', 'Big Title+');

  return (
    <Flex
      flex={1}
      justify="center"
      direction="column"
      background={{
        base: '',
        md: `url(${AlbumCoversImage}) no-repeat right bottom / auto 85vh`,
      }}
    >
      <Box
        my={{ base: 8, md: 28 }}
        flexGrow={1}
        mx={{ base: 6, md: 28 }}
        maxWidth={{ base: '100%', md: '6xl' }}
        pt={headerHeight}
      >
        <Text textStyle={h1}>
          There’s Nothing Playing.
          {isMobile ? ' ' : <br />}
          Turn Up!
        </Text>

        {!isMobile && (
          <Button variant="inverted" mt={5} as={Link} to="/play">
            + Queue
          </Button>
        )}
      </Box>
      {isMobile && (
        <>
          <Box
            mt="auto"
            background={`url(${AlbumCoversImageMobile}) no-repeat center / auto`}
            height={300}
            width="100%"
          />
          <Flex grow={0} shrink={0} w="100%" px={6} py={10} bg="white">
            <Button as={Link} to="/play" variant="inverted" h={14} w="100%">
              + Queue
            </Button>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default HomeNothingPlayingView;
