import { Flex } from '@chakra-ui/layout';
import { FunctionComponent } from 'react';
import InfiniteLoadingList from './InfiniteLoadingList';
import TrackListLoadingItem from './TrackListLoadingItem';
import TrackListHeader from './TrackListHeader';

type Props = {
  compactMode?: boolean;
  noOfItems?: number;
};

const TrackListLoading: FunctionComponent<Props> = ({
  compactMode = false,
  noOfItems = 10,
}) => {
  return (
    <>
      <Flex direction="column" grow={1} width="100%">
        {!compactMode && <TrackListHeader />}

        <Flex grow={1} direction="column" position="relative">
          <InfiniteLoadingList
            items={Array(noOfItems).fill(1)}
            hasNextPage={false}
            isNextPageLoading={false}
            rowHeight={73}
            loadNextPage={() => null}
            renderItem={() => (
              <TrackListLoadingItem compactMode={compactMode} />
            )}
            renderItemLoading={() => (
              <TrackListLoadingItem compactMode={compactMode} />
            )}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default TrackListLoading;
