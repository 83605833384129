import { HamburgerIcon, InfoOutlineIcon, LockIcon } from '@chakra-ui/icons';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  MenuDivider,
  Text,
  Button,
} from '@chakra-ui/react';
import {
  useDevices,
  useLogout,
  useUser,
  useCurrentDevice,
  useSetCurrentDevice,
  usePlaybackEnabled,
  useSetPlaybackEnabled,
  useSignOutOfChannel,
  useCurrentChannel,
} from '@worldeggplant/partypooper-react';
import { FunctionComponent, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import {
  appHasSeenWelcomeState,
  appMobileMenuOpenState,
} from '../state/app/appState';
import Debug from '../utilities/debug';
import { useIsMobile } from '../utilities/ResponsiveUtilities';
import MobileMenu from './MobileMenu';

const debugV = Debug.verbose('ProfileMenu');

type Props = {};

const ProfileMenu: FunctionComponent<Props> = () => {
  const setMobileMenuOpen = useSetRecoilState(appMobileMenuOpenState);
  const user = useUser();
  const logout = useLogout();
  const [devices, isLoadingDevices, reloadDevices] = useDevices();
  const channel = useCurrentChannel();
  const currentDevice = useCurrentDevice();
  const setCurrentDevice = useSetCurrentDevice();
  const playbackEnabled = usePlaybackEnabled();
  const setPlaybackEnabled = useSetPlaybackEnabled();
  const signOutOfCurrentChannel = useSignOutOfChannel();
  const setHasSeenWelcome = useSetRecoilState(appHasSeenWelcomeState);
  const isMobile = useIsMobile();

  const onClickChangeChannel = useCallback(() => {
    signOutOfCurrentChannel();
  }, [signOutOfCurrentChannel]);

  const onClickViewWelcomeMessage = useCallback(() => {
    setHasSeenWelcome(null);
  }, [setHasSeenWelcome]);

  const onSelectDevice = useCallback(
    (name: string | string[]) => {
      let deviceName: string | string[] = name;
      debugV('onSelectDevice', deviceName);

      if (typeof deviceName !== 'string') {
        deviceName = deviceName.shift() as string;
      }

      const device = devices?.find((d) => d.name === name);

      if (device) {
        setCurrentDevice(device);
      }
    },
    [devices, setCurrentDevice]
  );

  const onTogglePlaybackState = useCallback(() => {
    setPlaybackEnabled((currentPlaybackEnabled) => !currentPlaybackEnabled);
  }, [setPlaybackEnabled]);

  if (isMobile) {
    return (
      <>
        <Button
          onClick={() => setMobileMenuOpen((open) => !open)}
          variant="unstyled"
          w={5}
          h={5}
        >
          <HamburgerIcon w={5} h={5} />
        </Button>
        <MobileMenu />
      </>
    );
  }

  return (
    <Menu variant="profile" closeOnSelect={false}>
      <MenuButton opacity={0.7} _hover={{ opacity: 1 }}>
        {user?.uid}
      </MenuButton>
      <MenuList>
        <MenuItem onClick={onClickChangeChannel} command="Change channel">
          <Text
            as="span"
            textAlign="center"
            display="inline-block"
            color="black"
            opacity={0.5}
            w={4}
            h={4}
            mr={2}
          >
            #
          </Text>{' '}
          {channel?.name}
        </MenuItem>

        <MenuDivider />

        <MenuOptionGroup
          title="Playback"
          value={playbackEnabled ? 'on' : 'off'}
          onChange={onTogglePlaybackState}
        >
          <MenuItemOption value="on">Enable sound</MenuItemOption>
        </MenuOptionGroup>

        <MenuDivider />

        <MenuOptionGroup value={currentDevice?.name} onChange={onSelectDevice}>
          <MenuItem
            onClick={!isLoadingDevices ? reloadDevices : undefined}
            opacity={isLoadingDevices ? 0.5 : 1}
            cursor={isLoadingDevices ? 'default' : 'pointer'}
            transition="opacity 0.2s ease"
            fontSize="sm"
            command={isLoadingDevices ? 'Loading...' : 'Reload list'}
          >
            <Text fontWeight="600">Devices</Text>
          </MenuItem>
          {devices !== null &&
            devices?.map((device) => (
              <MenuItemOption value={device.name} key={device.id}>
                {device.name} ({device.type})
              </MenuItemOption>
            ))}
        </MenuOptionGroup>

        <MenuDivider />

        <MenuItem onClick={onClickViewWelcomeMessage}>
          <InfoOutlineIcon opacity={0.5} mr={2} />
          Display welcome message
        </MenuItem>

        <MenuItem onClick={logout}>
          <LockIcon opacity={0.5} mr={2} />
          Log out
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ProfileMenu;
