import { Circle, Text } from '@chakra-ui/react';
import {
  useCurrentChannelIdSafe,
  useFeed,
} from '@worldeggplant/partypooper-react';
import { FunctionComponent, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useMarkAsRead, useUnreadMessagesCount } from '../hooks/FeedHooks';
import { feedLastSeenItemIdState } from '../state/app/appState';

type Props = {};

const FeedUnreadCount: FunctionComponent<Props> = () => {
  const feed = useFeed();
  const currentChannelId = useCurrentChannelIdSafe();
  const feedLastSeenItemId = useRecoilValue(
    feedLastSeenItemIdState({ channelId: currentChannelId })
  );
  const markAsRead = useMarkAsRead();
  const unreadMessageCount = useUnreadMessagesCount();

  useEffect(() => {
    if (!feedLastSeenItemId && feed?.length) {
      markAsRead();
    }
  }, [feed?.length, feedLastSeenItemId, markAsRead]);

  if (feed === null || unreadMessageCount === 0) {
    return null;
  }

  return (
    <Circle
      position="relative"
      display="inline-flex"
      bg="black"
      centerContent
      size="16px"
      ml="6px"
    >
      <Text
        align="center"
        as="span"
        p="2px"
        color="white"
        fontSize={12}
        lineHeight="short"
        fontWeight={600}
      >
        {unreadMessageCount}
      </Text>
    </Circle>
  );
};

export default FeedUnreadCount;
