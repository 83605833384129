import { Box, Image } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { useRecoilValue } from 'recoil';
import { searchActiveAlbumImageState } from '../../state/search/searchState';

type Props = {
  searchTerm: string | null;
};

const SearchAlbumImage: FunctionComponent<Props> = ({ searchTerm }) => {
  const activeAlbumImage = useRecoilValue(
    searchActiveAlbumImageState({ searchTerm })
  );

  return (
    <Box width="100%">
      {activeAlbumImage !== null && (
        <Image
          src={activeAlbumImage}
          height="200px"
          width="200px"
          fit="cover"
          fallback={<Box height="200px" width="200px" bg="wildSand" />}
        />
      )}
    </Box>
  );
};

export default SearchAlbumImage;
