import { FunctionComponent } from 'react';
import { Link, LinkProps, useStyleConfig, useToken } from '@chakra-ui/react';
import { NavLink, NavLinkProps } from 'react-router-dom';

type Props = {} & LinkProps & NavLinkProps;

const NavigationLink: FunctionComponent<Props> = ({
  to,
  children,
  ...rest
}) => {
  const styles = useStyleConfig('NavigationLink');
  const activeColor = useToken('colors', 'black');

  return (
    <Link
      as={NavLink}
      to={to}
      sx={styles}
      activeStyle={{
        color: activeColor,
        opacity: 1,
      }}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default NavigationLink;
