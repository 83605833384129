import { atom } from 'recoil';
import { localStorageEffect } from '../../utilities/StateUtilities';

type RedirectState = string | null;

export const redirectState = atom<RedirectState>({
  key: 'redirectState',
  default: null,
  effects_UNSTABLE: [localStorageEffect<RedirectState>('redirectState')],
});
