import { FunctionComponent, ReactNode } from 'react';
import { format, fromUnixTime } from 'date-fns';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import FeedVotes from './FeedVotes';
import UserAvatar from './UserAvatar';
import { useResponsiveTextStyle } from '../utilities/ResponsiveUtilities';

type Props = {
  uid?: string;
  title: string;
  message: ReactNode;
  timestamp: number;
  image?: string;
  votes?: { pumps: number; poops: number };
  hideVotes?: boolean;
};

const FeedItem: FunctionComponent<Props> = ({
  uid,
  image,
  title,
  message,
  timestamp,
  votes,
  hideVotes = false,
}) => {
  const titleText = useResponsiveTextStyle('Regular+', 'Medium+');
  const text = useResponsiveTextStyle('Regular', 'Medium');
  const timestampText = useResponsiveTextStyle('Mini', 'Small');

  return (
    <Flex grow={1} py={4} pr={4} direction="column">
      <Flex grow={1}>
        <Box mr={4} flexShrink={0}>
          {image === undefined && uid && (
            <UserAvatar height={12} width={12} uid={uid} />
          )}
          {image !== undefined && (
            <Image
              height={12}
              width={12}
              src={image}
              fallback={
                <UserAvatar height={12} width={12} uid={uid || undefined} />
              }
            />
          )}
        </Box>

        <Flex direction="column" grow={1}>
          <Text wordBreak="break-word" textStyle={titleText}>
            {title}
          </Text>

          <Text wordBreak="break-word" textStyle={text}>
            {message}
          </Text>
        </Flex>

        <Flex shrink={0} ml="auto">
          <Text fontSize={timestampText}>
            {format(fromUnixTime(Math.ceil(timestamp / 1000)), 'HH:mm')}
          </Text>
        </Flex>
      </Flex>
      {!hideVotes && (
        <Box ml="48px" mt={1} pl={3}>
          <FeedVotes pumps={votes?.pumps} poops={votes?.poops} />
        </Box>
      )}
    </Flex>
  );
};

export default FeedItem;
